import { createSelector } from 'reselect';
import { SECTIONS } from '../utils/constants';

const selectCluster = (state) => state.init.sections;
const selectPresets = (state) => state.presets;
const selectLocationCluster = (state, props) => props.match.params[0] || null;


export const getPresetsByCluster = createSelector(
	selectPresets,
	selectLocationCluster,
	(selectPresets, locationAlias) => {

		const cluster = SECTIONS.find( (element) => element.aliasName === locationAlias ) || null;

		return {
			cluster: cluster,
			presets: (cluster) ? selectPresets[cluster.systemName] : []
		}
	}
)

export const getChainsByCluster = createSelector(
	selectCluster,
	selectLocationCluster,
	(stateSections, locationAlias) => {

		const cluster = SECTIONS.find( (element) => element.aliasName === locationAlias ) || null;

		if (cluster === null)
			return {
				cluster: null,
				chains: [],
				tableGroups: []
			}

		const chains = stateSections[cluster.systemName].chains;

		return {
			cluster: cluster,
			chains: chains,
			tableGroups: stateSections[cluster.systemName].tableGroups,
		}
	}
)
