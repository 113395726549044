import React, {Component} from "react";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import Preloader from '../../../components/Preloader';
import ClipboardButtons from '../../../components/ClipboardButtons';
import Dropdown from '../../../components/Dropdown';

import {getDataTable} from '../../../route/Chain/Chain.selectors.js';

import {
	ORDER_FREQUENCY_LIST,
} from '../../../utils/constants';

import {smoothScrollTo} from '../../../utils/helpers';

import Demos from './MenuCategories.Demos';
import Chains from './MenuCategories.Chains';
import Category from './MenuCategories.Category';

import ModalDemo from './MenuCategories.ModalDemo';
 

const TYPE_VIEW = {
	CATEGORY: 'CATEGORY',
	CHAINS: 'CHAINS',
	DEMOS: 'DEMOS',
};

const getValuesByFilter = ({data, filterConsumerTypeId}) => {
	const consumerList = data.find(item => item.consumerTypeId === filterConsumerTypeId) || [];
    const baseList = data.find(item => item.consumerTypeId === null) || [];

	if (consumerList.length === 0)
		return {
			consumerList: [],
			baseList: []
		}

	return {
		consumerList: consumerList.data,
		baseList: baseList.data[0].data,
	}
}


class MenuCategories extends Component {

	constructor(props){
		super(props);

		this.handlerSwichState = this.handlerSwichState.bind(this);
		this.handlerSwichFilter = this.handlerSwichFilter.bind(this);

		this.state = {
			typeView: TYPE_VIEW.CATEGORY,
			itemDetail: null,
			filterConsumerTypeId: ORDER_FREQUENCY_LIST[0].id,
		};
	}

	handlerSwichFilter(filter){
		this.setState(prevState => {
			return {
				...prevState,
				...filter
			}
		});	
	}

	handlerSwichState({typeView, itemDetail}) {
		this.setState( prevState => {
			return {
				typeView: (typeView === prevState.typeView) ?  prevState.typeView : typeView,
				itemDetail: (itemDetail === prevState.itemDetail) ?  prevState.itemDetail : itemDetail,
			};	
		});
	}

	handleSwichViewClick(typeView) {

		setTimeout(() => {
			if (this.nodeSection)
				smoothScrollTo((this.nodeSection.offsetTop - 50), 1000); 
		}, 100)

		this.setState( prevState => {
			return {
				typeView: typeView
			};	
		});
	}
  
	render() {
		const {
			table,
			data,
			chain,
			respondentTypeId,
			history,
			match,
		} = this.props;

		const {
			filterConsumerTypeId,
			typeView,
			itemDetail
		} = this.state;

		const answerValues = data
			? getValuesByFilter({
				...data,
				filterConsumerTypeId
			})
		: null;

		const filterConsumer = ORDER_FREQUENCY_LIST.find(item => item.id === filterConsumerTypeId)
		const formattedDate = new Date().toLocaleString('default', { 
			month: 'short',
			day: 'numeric', 
			year: 'numeric'
		});

		const baseSize = (answerValues) ? answerValues.baseList.find(base => base.respondentTypeId === respondentTypeId && base.ffChainId === chain.id) || null : null;

		return (
			<section className="section" id={table.aliasName} snapshot={table.methodName} ref={node => this.nodeSection = node}>
				<div className="section-header">
					<div className="section-header-meta">
						<div className="section-header-meta__caption">
							{table.name}
						</div>
						<div className="section-header-meta__description">
							% of customers who 
							{data && <Dropdown 
								className="dropdown-frequency section-header-meta__dropdown"
								placeholder="frequently"
								options={ORDER_FREQUENCY_LIST.slice()}
								optionSelectedId={filterConsumerTypeId}
								onChange={item => this.handlerSwichFilter({
									filterConsumerTypeId: item.id
								})}
							/>} order

						</div>
					</div>
					<div className="section-header-controls snapshot-disable">
						{(data && window.generalConfig.access.trial === false) && (
							<ClipboardButtons 
								fileName={table.name}
								nodeNameAttribute={table.methodName}
							/>
						)}

						{(data && typeView === TYPE_VIEW.CATEGORY && baseSize) && (
							<div className="base-size">base size: n={baseSize.value}</div>
						)}

					</div>
					<div className="section-header-copyright snapshot-only">Datassential</div>	
				</div>
				<div className="section-content" clipboard={table.methodName}>

					<span className="hidden-layer">
						<div><b>Datassential Brand Fingerprints</b></div>
						<div>{chain.name}: 
						  {{
							'CATEGORY': <> MENU CATEGORIES</>,
							'CHAINS': <> COMPETITIVE MENU CATEGORIES</>,
							'DEMOS': <> DEMOGRAPHY</>,
						  }[typeView]}
						</div>
						<div>% of consumers who {filterConsumer.name} order</div>
						<div>Extracted {formattedDate}</div>
						<div>Questions? Call us at 888-556-3687 or email help@datassential.com</div>
						<br />
						<br />
					</span>


					{(data === null) ? (
							<Preloader type="table" />
						) : (
							<Content 
								typeView={typeView}
								table={table}
								chain={chain}
								onClickItem={itemDetail => this.handlerSwichState({typeView, itemDetail})}
								history={history}
								match={match}
								data={{
									categories: data.categories,
									...answerValues,
								}} />
						)}
				</div>
				<div className="section-footer">
					<Footer 
						typeView={typeView}
						onClick={typeView => this.handleSwichViewClick(typeView)} />							 
				</div>
				{itemDetail && <ModalDemo 
					table={table}
					itemDetail={itemDetail}
					consumerList={answerValues.consumerList}
					chain={chain}
					filterConsumerTypeId={filterConsumerTypeId}
					onClose={() => this.handlerSwichState({
						typeView, 
						itemDetail: null
					})} />}
			</section>
		)
	}

}


const Content = props => {
	switch(props.typeView) {
		case TYPE_VIEW.CHAINS:
			return <Chains { ...props } />;
		case TYPE_VIEW.DEMOS:
			return <Demos { ...props } />;
		case TYPE_VIEW.CATEGORY:
			return <Category { ...props } />
		default:
			return <div>Not loaded</div>
	}
}

const Footer = ({ typeView, onClick }) => {
	switch(typeView) {
		case TYPE_VIEW.CHAINS:
			return (
				<div className="section-footer-swich section-footer-swich--list">
					<button className="button section-footer-swich-button" onClick={() => onClick(TYPE_VIEW.CATEGORY)}>
						<svg className="icon icon-back" dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-back" />' }} />
						Back to chain
					</button>
					<button className="button section-footer-swich-button" onClick={() => onClick(TYPE_VIEW.DEMOS)}>
						Compare demos
						<svg className="icon icon-forward" dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-forward" />' }} />
					</button>
				</div>
			);
		case TYPE_VIEW.DEMOS:
			return (
				<div className="section-footer-swich section-footer-swich--list">
					<button className="button section-footer-swich-button" onClick={() => onClick(TYPE_VIEW.CHAINS)}>
						<svg className="icon icon-back" dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-back" />' }} />
						Back to chains comparison
					</button>
					<button className="button section-footer-swich-button" onClick={() => onClick(TYPE_VIEW.CATEGORY)}>
						Back to chain
						<svg className="icon icon-forward" dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-forward" />' }} />
					</button>
				</div>
			);

		default:
			return (
				<div className="section-footer-swich">
					<button className="button section-footer-swich-button" onClick={() => onClick(TYPE_VIEW.CHAINS)}>
						Compare chains and demos
					</button>
				</div>
			);
	}
}

MenuCategories.propTypes = {
	data: PropTypes.object,
	chain: PropTypes.object,
	respondentTypeId: PropTypes.number,
};

const mapStateToProps = (state, props) => ({
	data: getDataTable(state, props),
	respondentTypeId: state.chain.filterRespondentTypeId,
})

 

export default connect(mapStateToProps)(MenuCategories);