import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
 
import SectionWrapper from '../../../containers/SectionWrapper';
import { getDataTable } from '../../../route/Chain/Chain.selectors.js';
import { deleteUnmetNeeds } from '../../../actions/chain';

const COUNT_MIN_ITEMS = 6;

class ToggleList extends Component {

	constructor(props){
		super(props);
		this.state = {
			countMaxItems: COUNT_MIN_ITEMS
		}

		this.onToggleLengthList = this.onToggleLengthList.bind(this);
	}

	onToggleLengthList() {
		const {data} = this.props;
		
		this.setState((prevState) => ({
			countMaxItems: (data.length === prevState.countMaxItems) ? COUNT_MIN_ITEMS : data.length,
		}))
	}

	render() {
 
		const {table, data} = this.props;
		const {countMaxItems} = this.state;



  
		return (
			<SectionWrapper config={{
				...table, 
				isShowData: (data === null) ? false : true,
 				copyData: (data === null) ? null : data.reduce( (list, item) => { 
					list.push([item.name]);
					return list
				}, [[table.name.toUpperCase()]]),
			}}>
				<div className="section-content--container" clipboard={table.methodName}>
 					
 					{data && <div className="toggle-list-holder">
	 					<ul className="toggle-list">
							{data.slice(0, countMaxItems).map( (item, index) => (
								<li className="toggle-list__item" key={item.id}>
									{item.name}              
									{(window.generalConfig.access.admin === true) && (
										<svg className="icon icon-delete" 
											dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-delete" />' }} 
											onClick={() => this.props.deleteUnmetNeeds({methodName: table.methodName, id: item.id})} 
										/>
									)}
								</li>
							))}
						</ul>

						{data.length > COUNT_MIN_ITEMS && (
							<div className="toggle-list-bottom">
								<button className="button toggle-list__button-more" onClick={ (event) => {
									event.preventDefault();
									this.onToggleLengthList();
								}}>
									<svg className={`icon ${(data.length === countMaxItems) ? `icon--up-arrow` : `icon--down-arrow`}`} dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-down-arrow" />' }} />
								</button>
							</div>
						)}

					</div>}
				</div>
			</SectionWrapper>
		);
	}
}
 
ToggleList.propTypes = {
	data: PropTypes.array,
	dataInit: PropTypes.object,
};


const mapStateToProps = (state, props) => ({
	data: getDataTable(state, props),
})

const mapDispatchToProps = dispatch => ({
  deleteUnmetNeeds: (data) => dispatch(deleteUnmetNeeds(data)),
});
 
export default connect(mapStateToProps, mapDispatchToProps)(ToggleList);