import React  from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useRouteMatch } from "react-router-dom";
 
import {
	lowerCaseFirstLetter
} from '../../utils/helpers';

const ChainList = props => {
	const {
		url,
		params:{
			0: cluster,
		}
	} = useRouteMatch();

	const {
		chainList,
		groupList,
		avaliableChainViews = [],
	} = props,
	listLetters = 'abcdefghijklmnopqrstuvwxyz'.split(''),
	listNumbersString = ['zerro', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];

	const groupLetters = listLetters.map((letter) => {
		return {
			letter: letter.toUpperCase(),
			list: ((letter) => {

				return chainList.filter( (element) => {

					const firstChar = element.name.charAt(0).toLowerCase();

					if (isNaN(firstChar) === false &&  listNumbersString[firstChar].charAt(0) === letter )
						return true;

					if (firstChar === letter)
						return true;

					return false;

				})

			})(letter)
		}

	}).filter( (group) => {
		if (group.list.length > 0)
			return group
		return null;
	});

	const [firstGroup] = groupList;

	return (
		<div className={`chain-list chain-list--${cluster}`}>
			{groupLetters.map((group, index) => (
				<div key={index} className="chain-list-block">
					<div className="chain-list__leter">{group.letter}</div>
						{group.list.map((chain, indexChain) => {

							const {
								property = '',
							} = avaliableChainViews.find(chainView => chainView.ffChainId === chain.id) || {};

							const chainProp = lowerCaseFirstLetter(property);

							const pathLink = `${url}/chain/${chain.id}${(chainProp && chain[chainProp]) ? `/${chain[chainProp]}`:``}/${firstGroup.aliasName}`;
							
							return (
								<Link key={indexChain} to={{ 
									pathname: pathLink,
								}} className={`chain-list__name ${(chain.isDisabled === true) ? `is-disabled` : `is-enabled`}`}>{chain.name}</Link>
							)

						})}
				</div>
			))}
		</div>
	);
}


ChainList.propTypes = {
	dataList: PropTypes.array
};

ChainList.defaultProps = {
	dataList: []
}

export default ChainList;