import {isJsonString} from './helpers';

export default class httpClient {
	
	constructor(url, config) {
		const configDefault = {
			url: url,
			method: "GET",
			data: "",
			isAsync: true
		}

		this.config  = Object.assign(configDefault, config);
		this.request = this.go();
	}

	go(){
		const {url, method, data, isAsync}  = this.config;

		return new Promise( (resolve, reject) => {
			const xhr = new XMLHttpRequest();

				xhr.open(method, url, isAsync);
				xhr.setRequestHeader('Accept', 'application/json');
				xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
				xhr.withCredentials = true;

			xhr.onload = function() {

				if (this.status === 401)
					setTimeout(() => {
				 		window.location.href =`${process.env.REACT_APP_API}UserAuth`;
					}, 1000)

			
				if (this.status >= 200 && this.status < 300) {
					
					const response = (isJsonString(xhr.response)) ?  JSON.parse(xhr.response) : xhr.response;

					if (typeof response === 'object'){
						resolve(response);
					} else {

						if (xhr.response === 'NO_PERMISSION'){
							reject({
								status: this.status,
								statusText: xhr.statusText,
								responseText: xhr.response,
							});	
						}
					}

				} else {

					reject({
						status: this.status,
						statusText: xhr.statusText,
						responseText: xhr.response,
					});	
				}
			}

			xhr.onerror = function() {
				reject({
					status: this.status,
					statusText: xhr.statusText,
					responseText: xhr.response,
				});	
			}

			xhr.send(data);
		})
	}

}