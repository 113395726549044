
import { ACTIONS_PRESET } from '../utils/constants';

const initialState = {
	isLoaded: false,
	isLoading: false,
	restaurants: [],
	cstores: [],
	supermarkets: []
};

const reducer =  (state = initialState, action) => {

	switch (action.type) {

		case ACTIONS_PRESET.STORE_DATA: {
			return {
				...state,
				...action.payload,
				isLoaded: true,
				isLoading: false,
			};
		}

		case ACTIONS_PRESET.LOADING: {
			return {
				...state,
				isLoading: true,
			};
		}

		case ACTIONS_PRESET.CREATE_PRESET: {
 
			const { section, preset } = action.payload;

			return {
				...state,
				isLoading: false,
				[section]: [
					...state[section],
					...[preset]
				]
			};
		}

		case ACTIONS_PRESET.UPDATE_PRESET: {
			
			const { section, preset } = action.payload;
			const indexPreset = state[section].findIndex(item => (item.id === preset.id)) || null;

			if (indexPreset === null)
				return state;

			return {
				...state,
				isLoading: false,
				[section]: [
					...state[section].slice(0, indexPreset),
					...[preset],
					...state[section].slice(indexPreset + 1),
				]
			};
		}

		case ACTIONS_PRESET.REMOVE: {
			
			const { section, preset } = action.payload;
			const indexPreset = state[section].findIndex(item => (item.id === preset.id)) || null;

		 	return {
				...state,
				isLoading: false,
				[section]: [
					...state[section].slice(0, indexPreset),
					...state[section].slice(indexPreset + 1),
				]
			};
		}

		default: {
			return state;
		}
	}
}

export default reducer;