
import { 
	CHAIN_COMPARE_TYPES, 
	RANKING_TABLE, 
	TABLE_TYPES_COLUMN,
	TABLE_COLUMNS,
	TOOLTIP_TYPES,
} from './constants';

export const getApiControllerByAlias = (alias) => {
	switch(alias) {
		case 'restaurants':
			return 'Restaurant';
		case 'c-stores':
			return 'CStore';
		case 'grocery':
			return 'Supermarket';
		default:
			return null;
	}
}

 
export const borowserDetect = () => {

	const userAgent = (navigator && (navigator.userAgent || '')).toLowerCase(),
	vendor = (navigator && (navigator.vendor || '')).toLowerCase();

	return {
		isIE: () => {
			return userAgent.match(/(?:msie |trident.+?; rv:)(\d+)/) !== null;
		},
		isEdge: () => {
			return userAgent.match(/edge\/(\d+)/) !== null;
		},
		isFirefox: () => {
			return userAgent.match(/(?:firefox|fxios)\/(\d+)/) !== null;
		},
		isChrome: () => {
				const match = /google inc/.test(vendor) ? userAgent.match(/(?:chrome|crios)\/(\d+)/) : null;
				return match !== null
		},
		isSafari: () => {
				return userAgent.match(/version\/(\d+).+?safari/);
		},
	}
}

 
export function toLowerCaseFirstLetterJsonKeys (obj) {
	if (typeof obj !== 'object' || obj === null) {
		return obj;
	}
	if (Array.isArray(obj)) {
		return obj.map(o => toLowerCaseFirstLetterJsonKeys(o));
	}
	return Object.keys(obj).reduce((prev, curr) => {
		prev[ curr.charAt(0).toLowerCase() + curr.substr(1) ] = toLowerCaseFirstLetterJsonKeys(obj[curr]);
		return prev;
	}, {});
};

  
export const capitalizeString = string => ( string.replace(/\b\w/g, l => l.toUpperCase())  );

export const capitalizeFirstLetter = string => ( string.charAt(0).toLowerCase() + string.slice(1) );

export const lowerCaseFirstLetter = capitalizeFirstLetter;


export function mapInitialDataChains (chains = []) {
	if (Array.isArray(chains) === false)
		return [];

	return chains.map( chain => {
		return {
			id: chain.fireflyChainId,
			name: chain.displayName,
			segmentGroupId: chain.segmentGroup,
			logo: chain.logo,
			unitsCount: chain.unitsCount,
			isDisabled: chain.isDisabled,
			isDisabledInPreset: chain.isDisabledInPreset,
			stateId: chain.stateId,
			dmaRegionId: chain.dmaRegionId,
			chainType: chain.type,
		};
	})
};


export function mapInitialDataPresets ( presets = [], chains = []) {
	if (Array.isArray(presets) === false)
		return []

	return presets.map( preset => {

		const chainList = preset.chains.map((id) => {
			return chains.find(chain => chain.id === id) || null;
		});

		return {
			...preset,
			chains: chainList,
		};
	})
};



export function mapInitialDataTableGroups (tableGroups) {

	return tableGroups.map( group => {

		group.aliasName = group.name.toLowerCase().replace(new RegExp(' ', 'g'), '-');
		group.tables.map( table => {
			table.aliasName = table.methodName.toLowerCase().replace(new RegExp(' ', 'g'), '-');
			return table
		})
		
		return group; 
	})

};

export function mapInitialDataRespondentTypes (respondentTypes) {
	return respondentTypes.map( (element) => {
		return {
			name: element.name,
			items: element.types,
		}
	});
};

 
export const defaultSortMethod = (a, b) => {
	// force null and undefined to the bottom
	a = (a === null || a === undefined) ? -Infinity : a
	b = (b === null || b === undefined) ? -Infinity : b
	// force any string values to lowercase
	a = typeof(a) === 'string' ? a.toLowerCase() : a
	b = typeof(b) === 'string' ? b.toLowerCase() : b
	// Return either 1 or -1 to indicate a sort priority
	if (a > b) {
	  return 1
	}
	if (a < b) {
	  return -1
	}
	return 0
}

export const isJsonString = (string) => {
	try {
		JSON.parse(string);
	} catch (e) {
		return false;
	}
	return true;
}


export const getUrlParamFilterCompareTo  = filter => {

	switch(filter.type) {
		case CHAIN_COMPARE_TYPES.GROUP:
			return {
				presetId: filter.payload.id
			} 
		case CHAIN_COMPARE_TYPES.BRAND:
			return {
				chainToCompareId: filter.payload.id,
				stateToCompareId: filter.payload.stateId,
				dmaRegionToCompareId: filter.payload.dmaRegionId,
			} 
		case CHAIN_COMPARE_TYPES.HISTORY:
			return {
				needHistoryData: true
			} 
		default:
			return {
				needPresetData: true
			}
	}
}
 

export const smoothScrollTo = (to, duration) => {
	const
	element = document.scrollingElement || document.documentElement,
	start = element.scrollTop,
	change = to - start,
	startDate = +new Date(),
	// t = current time
	// b = start value
	// c = change in value
	// d = duration
	easeInOutQuad = (t, b, c, d) => {
		t /= d/2;
		if (t < 1) return c/2*t*t + b;
		t--;
		return -c/2 * (t*(t-2) - 1) + b;
	},
	animateScroll = () => {
		const currentDate = +new Date();
		const currentTime = currentDate - startDate;
		element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
		if(currentTime < duration) {
			requestAnimationFrame(animateScroll);
		}
		else {
			element.scrollTop = to;
		}
	};

	setTimeout(() => {
		animateScroll();
	},1)
	 
}

// https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
export const formatPhoneNumber = phoneNumberString => {
	const cleaned = ('' + phoneNumberString).replace(/\D/g, ''),
		match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

	if (match)
		return '(' + match[1] + ') ' + match[2] + '-' + match[3];

	return phoneNumberString;
}

export const transformRankingTableData = ( response ) => {
	if (response === null)
		return null;

	let columnId = 0,
	columnName;
	let columnsGroups = response.columnsGroups.map( columnsGroup => ({
			label: (columnsGroup.name) ? columnsGroup.name : "",
			columns: columnsGroup.columns.map( column => { 
				++columnId;
 
				switch(column.toUpperCase()) {
					case 'BASE':
						columnName = TABLE_COLUMNS.BASE;
						break;
					case 'EXCELLENT + VERY GOOD':
						columnName = TABLE_COLUMNS.EXCELLENT_VERY_GOOD;
						break;
					case 'NPS':
						columnName = TABLE_COLUMNS.NPS;
						break;
					default:
						columnName = TABLE_COLUMNS.DATA_PERCENTAGE;
				}

				return {
					name: columnName,
					label: column,
					id: columnId,
					isVisible: (columnId < RANKING_TABLE.MAX_COUNT_COULUMNS_VISIBLE_TOTAL) ? true : false,
					isAllowToggleVisible: (columnName === TABLE_COLUMNS.BASE) ? false : true,
					orderBy: null,
				}
			})
	}));

	return {
		columnsGroups: [ 
			{
				label: "", 
				columns:[{
					name: TABLE_COLUMNS.CHAIN_LINK,
					label: "",
					id: ++columnId,
					isVisible: true,
					isAllowToggleVisible: false,
					orderBy: 'asc',
				}]
			},
			...columnsGroups
		],
		rows: response.rows
	}
}

export const transformRankingHistoryTableData = ( response ) => {
	if (response === null)
		return null;

	let columnId = 0;
	let columnsGroups = response.columnsGroups.map( (group, indexGroup) => ({
			label: (group.name) ? group.name : "",
			columns: group.columns.map( column => { 
				return {
					name: TABLE_COLUMNS.HISTORY_DIFFERENCE,
					label: column,
					id: columnId++,
					isVisible: false,
					isAllowToggleVisible: true,
					orderBy: null,
				}
			})
			.filter( column =>  (column.label.toLowerCase() !== 'base') )
	}))
	.filter( column =>  (column.columns.length > 0) )
	.map( (group, indexGroup) => {
		group.columns.map( (column, indexColumn) => {

			if (indexGroup === 0 && indexColumn === 0)
				column.isVisible = true;

			return column;
		});
		return group;
	})

	return {
		columnsGroups: [
			{
				label: "", 
				columns:[{
					name: TABLE_COLUMNS.CHAIN_LINK,
					label: "",
					id: columnId++,
					isVisible: true,
					isAllowToggleVisible: false,
					orderBy: 'asc',
				}]
			},
			{
				label: "",
				columns:[{
					name: TABLE_COLUMNS.HISTORY_BASE,
					label: "",
					id: columnId++,
					isVisible: true,
					isAllowToggleVisible: false,
					orderBy: null,
				}]
			},
			{
				label: "", 
				columns:[{
					name: TABLE_COLUMNS.HISTORY_TO,
					label: "",
					id: columnId++,
					isVisible: true,
					isAllowToggleVisible: false,
					orderBy: null,
				}]
			},
			{
				label: "", 
				columns:[{
					name: TABLE_COLUMNS.HISTORY_FROM,
					label: "",
					id: columnId++,
					isVisible: true,
					isAllowToggleVisible: false,
					orderBy: null,
				}]
			},

			...columnsGroups
		],

		rows: response.rows
	}
}
 
export const transformChainTableData = ( sectionData ) => {

	let groups = {
		group: null,
		rows:[]
	},
 	rowsGroups = [],
	footerRows = [],
	rowIndex = 0;


	sectionData.columnsGroups.forEach( (columnGroup) => {

		if (columnGroup.name)
			groups = {
				group: null,
				rows:[]
			}

	    columnGroup.columns.forEach( (column) => {

			const listCells = [{
				value:column,

				...(  (sectionData.rows[rowIndex].additionalValues) ? {
					tooltip:{
						type: TOOLTIP_TYPES.RESPONSE_DISTRIBUTION,
						value: sectionData.rows[rowIndex].additionalValues
					}
				} : {}),


				...(  (column.toUpperCase() === 'NPS') ? {
					className: 'cell--nps',
					type: TABLE_TYPES_COLUMN.NPS
				} : {}),


			}].concat( sectionData.rows[rowIndex].values.map( (value, indexCell) => {
	
				const baseValue = Array.isArray(sectionData.rows[rowIndex].bases) ? sectionData.rows[rowIndex].bases[indexCell] : null;

				return {
					value: value,
					...( (baseValue !== null) ? {
						tooltip:{
							type: TOOLTIP_TYPES.BASE_SIZE,
							value: baseValue
						}
					} : {}),

					// @ToDo: remake => need param from back-end
					// hardcode field type for "Payment Method"
					...(  (column.toUpperCase() === 'AVERAGE PRICE PER PERSON') ? {
						type: TABLE_TYPES_COLUMN.CURENCY_NUMBER
					} : {}),

					...(  (column.toUpperCase() === 'NPS') ? {
						className: 'cell--nps',
						type: TABLE_TYPES_COLUMN.NPS_VALUE
					} : {}),

				}

			}));

			if (footerRows.length === 0 && column === `Base`){
				footerRows.push(listCells);	
			} else {
				groups.rows.push(listCells);
			}
	 
		   rowIndex++
	   });

		if (columnGroup.name){
			groups.group = { 
				label: columnGroup.name.toLowerCase().replace(/\b\w/g, l => l.toUpperCase()).replace("Lsr", "LSR").replace("Fsr", "FSR"),
				colSpan: 3
			}
			rowsGroups.push(groups);	
		}

	});

	if (rowsGroups.length === 0)
		rowsGroups.push(groups);

	return {
		rowsGroups: rowsGroups,
		footerRows: footerRows
	}
}


const extendViewChainId = JSON.parse(sessionStorage.getItem('EXTEND_VIEW_CHAIN_ID'));


export const filterExtendViewChains = ({
	chains = [],
	avaliableChainViews = [],
}) => {

	// if (IS_DATASSENTIAL === false)
	// 	return chains;

	 
	return chains.filter(chain => {

		const {
			property = '',
		} = avaliableChainViews.find(chainView => chainView.ffChainId === chain.id) || {};

		const chainProp = lowerCaseFirstLetter(property);

		if (extendViewChainId === chain.id && chain[chainProp])
			return true;
		
		if (extendViewChainId !== chain.id && chain[chainProp])
			return false;
		
		if (extendViewChainId === null && chain[chainProp])
			return false;

		return true;
	})
}
