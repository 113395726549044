import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
 
import DropdownToggle from '../../../components/DropdownToggle';
import Table from '../../../components/Table';
 
import {
	TABLE_COLUMNS, 
} from '../../../utils/constants';

import {getChainsByCluster} from '../../../selectors/section.selectors';
import {dbTableColumnInfo} from '../../../utils/presets';

import Notification from '../../../utils/notification';

// import { TOOLTIP_TYPES } from '../../../utils/constants';

const VISIBLE_COLUMN_COUNT = 9;
const FIXED_HEADER_TOP_POSITION  = 46;


const getInitState = props => {

	const {
		clusterChains:{
			chains,
		},
		chain: currentChain,
		foodTabChains
	} = props;

	return [{
		label: '',
		columns:[{
			label: '',
			isAllowToggleVisible: false,
			id: TABLE_COLUMNS.MENU_CATEGORY,
			name: TABLE_COLUMNS.MENU_CATEGORY,
			isVisible: true,
			isColumn: true,
			orderBy: 'asc',
		},
		...foodTabChains
			.sort((a, b) => {
				return a === currentChain.id ? -1 : b === currentChain.id ? 1 : 0;
			})
			.map((chainId, index) => {
				
				const chain = chains.find(item => item.id === chainId) || null;
			 
				return {
					label: chain.name,
					isAllowToggleVisible: (currentChain.id === chainId) ? false : true,
					id: chainId,
					name: TABLE_COLUMNS.DATA_PERCENTAGE,
					isVisible: (index < VISIBLE_COLUMN_COUNT) ? true : false,
					isColumn: true,
					orderBy: null,
				}
			}),],
	}];
}


class Chains extends Component {

	constructor(props){
		super(props);

		this.handleToggleColumn = this.handleToggleColumn.bind(this);
		this.onSortColumn = this.onSortColumn.bind(this);
		this.buildTable = this.buildTable.bind(this);
 
		const columnsGroups = getInitState(this.props);
		this.state = {
			columnsGroups
		} 

		this.notification = new Notification();
	}


	handleToggleColumn(сurrentСolumn = null){

		this.setState(prevState => {

			const columnsGroups = prevState.columnsGroups.map(group => {
				return {
					...group,
					columns: group.columns.map(column => {

						const isVisible = (сurrentСolumn && column.id === сurrentСolumn.id) ? сurrentСolumn.isSelected : column.isVisible;

						return {
							...column,
							isVisible: (сurrentСolumn === null && column.isAllowToggleVisible === true) ? false : isVisible,
						}
					})
				}
			});

			return {
				...prevState,
				columnsGroups: [
					...columnsGroups
				]
			}
 
		});
	}

	onSortColumn(column){
		// console.log('test', column);
	}

	
	buildTable({columnsGroups, isFixedHeader}) {
		const {
			// table,
			data:{
				categories,
				consumerList,
				// baseList,
			},
			chain,
			respondentTypeId,
		} = this.props;

		const visibleChainColumnList = columnsGroups.reduce( (group,item) => {
			const columns = item.columns.filter(column => column.isVisible === true && column.name === TABLE_COLUMNS.DATA_PERCENTAGE);
			return [
				...group,
				...columns
			]
		}, []); 

		return (
			<Table
				className="table-menu-chains"
				isScrollBar={false}
				fixHeaderPositionTop={(isFixedHeader === true) ? FIXED_HEADER_TOP_POSITION : null}
				columnsGroups={ columnsGroups.map( (group, indexGroup) => {

					const columns = group.columns
						.filter( column => column.isVisible === true )
						.map((column) => {

							const columnInfo = dbTableColumnInfo.get(column.name);
					
							const classList = [
								...[columnInfo.className],
								...[(column.id === chain.id) ? `cell--name-current-chain` : ``],
							].filter( element => element ).join(' ');

							return {
								id: column.id,
								label:  (columnInfo.label) ? columnInfo.label : column.label,
								type: columnInfo.dataType,
								className: classList,
								isSorting: true,
								orderBy: column.orderBy,
								isColumn: column.isColumn,
							}
						});

					return {
						className: '',
						label: group.label,
						columns: columns,
						isColumn: false
					}
				})}
				rowsGroups={categories
					.filter(category => category.groupId === 1)
					.map(category => {
					return {
						group: {
							label: category.name.toUpperCase(),
							colSpan: visibleChainColumnList.length,
						},
						rows: category.items.map((item) => {

							const answer = consumerList.find(answer => answer.answerId === item.answerId) || null;


							const columns = visibleChainColumnList.map(column => {

								const chainAnswer =  (answer) ? 
									(answer.data.find(answer => answer.respondentTypeId === respondentTypeId && answer.ffChainId === column.id) || null) : null;


								// const baseSize = baseList.find(base => base.respondentTypeId === respondentTypeId && base.ffChainId === column.id) || null;

								
								return{
									value: (chainAnswer === null) ? null : chainAnswer.value,

									/*
									...((baseSize && chainAnswer !== null) && {
										tooltip: {
											value: baseSize.value,
											type: TOOLTIP_TYPES.BASE_SIZE
										},
									})
									*/
								}
							});

							return [
								{
									value: item.chainText
								},
								...columns
							]
						}),
					}
				})}
				onSortColumn={this.onSortColumn}
			/>
		)
	}

	render() {

		const {
			columnsGroups
		}  = this.state;

		const visibleChainColumnList = columnsGroups.reduce( (group,item) => {
			const columns = item.columns.filter(column => column.isVisible === true && column.name === TABLE_COLUMNS.DATA_PERCENTAGE);
			return [
				...group,
				...columns
			]
		}, []);


		return (
			<Fragment>
				<DropdownToggle
					className="dropdown-toggle--side dropdown-toggle--single"
					placeholder=""
					isMultiselect={true}
					options={columnsGroups.map(item => {

						const items = item.columns
							.filter( column => column.isAllowToggleVisible === true )
							.map( column => {
								return{
									id: column.id,
									name: column.label,
									isSelected: column.isVisible
								}
							})

						return {
							name: item.label,
							items: items
						}

					}).filter( group => group.items.length > 0 )}
					onClickItem={(column) => {

						if (column.isSelected === true && visibleChainColumnList.length >= VISIBLE_COLUMN_COUNT){
							this.notification.add(`Table displays ${VISIBLE_COLUMN_COUNT} columns max.`);
						} else {
							this.handleToggleColumn(column);
						}
					}}
					onClear={() => {
						this.handleToggleColumn();
					}}

					clipboard-disable="true"
				/>


				<div clipboard-disable="true">
					{this.buildTable({
						columnsGroups,
						isFixedHeader: true,
					})}
				</div>

				<span className="hidden-layer">
					{this.buildTable({
						columnsGroups: columnsGroups.map( group => {
							const columns = group.columns.map(column => ({
								...column,
								isVisible:true,
							}));

							return {
								...group,
								columns
							}

						}), 
						isFixedHeader: false,
					})}
				</span>



			</Fragment>
		);
	}
}

Chains.propTypes = {
	table: PropTypes.object,
	data: PropTypes.object,
	chain: PropTypes.object,
	clusterChains: PropTypes.object,
	foodTabChains: PropTypes.array,
	respondentTypeId: PropTypes.number,
};

const mapStateToProps = (state, props) => ({
	clusterChains: getChainsByCluster(state, props),
	foodTabChains: state.init.foodTabChains,
	respondentTypeId: state.chain.filterRespondentTypeId,
});
 
export default connect(mapStateToProps)(Chains);