import httpClient from '../utils/httpClient';
import {ACTIONS_RANKINGS} from '../utils/constants';

import { 
	transformRankingTableData,
	transformRankingHistoryTableData,
	getApiControllerByAlias
} from '../utils/helpers';

 
export const getDataByBroup = ( queryParam ) => (dispatch, getState)  => {

	const {methodName, respondentType, aliasName} = queryParam; 

	dispatch({ 
		type: ACTIONS_RANKINGS.FETCH_DATA_BY_GROUP_START, 
		payload: {
			methodName
		} 
	});

	const { 
		rankings:{
			dataGroups
		}
	} = getState();

	const group =  dataGroups.find( group => group.methodName === methodName);
	const historyTables = group.tables.filter( table => table.isHistory === true).map( table => {
		return table.methodName
	});


	const apiController = getApiControllerByAlias(aliasName);

	const http = new httpClient( `${process.env.REACT_APP_API}${apiController}/${methodName}`, {
		method: 'POST',
		data: JSON.stringify({
			dataType: respondentType,
			historyTables: (historyTables.length > 0) ? historyTables : null
		})
	});

	http.request.then( (response) => {


		const tables = group.tables.map( (table, index) => {
			const indexDataTable = response[index];
			return {
				...table,
				dataTable: (table.isHistory === true) ? transformRankingHistoryTableData(indexDataTable) : transformRankingTableData(indexDataTable)
			}
		});


		if (Array.isArray(dataGroups))
			dispatch({ type: ACTIONS_RANKINGS.FETCH_DATA_BY_GROUP_FINISH, payload: {tables, methodName} });

	}).catch( ( response ) => {
		console.error( "Network Error:", response );
	})
}



export const getDataByTable = ( queryParam ) => (dispatch, getState)  => {

	const {
		groupMethodName, 
		tableMethodName, 
		isHistory, 
		respondentTypeId, 
		chainType
	} = queryParam;

	dispatch({ 
		type: ACTIONS_RANKINGS.UPDATE_GROUP_TABLE, 
		payload: {
			groupMethodName,
			tableMethodName,
			options:{
				dataTable: null,
				isHistory: queryParam.isHistory,
			}
		}
	});

	const http = new httpClient( `${process.env.REACT_APP_API}Table/GetTableData`, {
		method: 'POST',
		data: JSON.stringify({
			methodName: tableMethodName,
			dataType: respondentTypeId,
			chainType: chainType,
			needHistoryData: isHistory
		})
	});

	http.request.then( (response) => {

		const dataTable = (isHistory === true) ? transformRankingHistoryTableData(response) : transformRankingTableData(response);

		dispatch({ 
			type: ACTIONS_RANKINGS.UPDATE_GROUP_TABLE, 
			payload: {
				groupMethodName,
				tableMethodName,
				options:{
					dataTable: dataTable,
				}
			}
		});

	}).catch( ( response ) => {
		console.error( "Network Error:", response );
	})
}

export const createGroupsDataStore = ( payload ) => (dispatch, getState)  => {
	dispatch({ type: ACTIONS_RANKINGS.CREATE_GROUPS_STORE, payload: payload });
}

export function clearGroupsDataStore() {
	return {
		type: ACTIONS_RANKINGS.CLEAN_STORE,
	};
}

export function updateHeaderTable(payload) {
	return {
		type: ACTIONS_RANKINGS.UPDATE_HEADER_TABLE,
		payload: payload
	};
}
 
export function setRespondentType(respondent) {
	return {
		type: ACTIONS_RANKINGS.CHANGE_RESPONDENT_TYPE,
		payload: respondent
	};
}

export function setSegmentChain(segment) {
	return {
		type: ACTIONS_RANKINGS.CHANGE_SEGMENT_CHAIN,
		payload: segment
	};
}
