import React, {Component} from 'react';
import PropTypes from 'prop-types';
 

class ChainLogo extends Component {
 
	// constructor(props) {
	// 	super(props);
	// }

	componentDidMount(){
		if (this.props.image)
			this.lazyLoadImage(this.props.image)
	}

	componentWillReceiveProps(nextProps){
		if (nextProps.image !== this.props.image)
			this.lazyLoadImage(nextProps.image)
	}

	lazyLoadImage(imageUrl){
		const image = new Image();
			image.src = imageUrl;
			image.onload = (image) => {
				if (this.nodeImage){
					this.nodeImage.src = image.target.src;
					this.nodeImage.classList.add('fade-in');
				}
			}
	}
 
	render() {
		return(
			<figure className={`chain-logo ${this.props.className}`}>
				<img className="chain-logo-image" src="" alt="" ref={node => this.nodeImage = node} />
				<span className="chain-logo-placeholder animation-logo-placeholder"></span>
			</figure>
		)
	}
}
 

ChainLogo.propTypes = {
	className: PropTypes.string,
	image: PropTypes.string
};

ChainLogo.defaultProps = {
	className: "",
	image: ""
}

export default ChainLogo;