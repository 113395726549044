import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ReactComponent as IconClose } from '../../icons/close.svg'

import {
	getChainsByCluster
} from '../../selectors/section.selectors';

import { 
	CHAIN_COMPARE_TYPES,
} from '../../utils/constants';

import PerfectScrollbar from '../../components/PerfectScrollbar';
import { isMixLSRFSR } from './ChainPopup.Group';

const SELECTED_CHAINS_LIMIT = 25;

class ChainPopupChain extends Component {

	constructor(props){
		super(props);
 
		this.state = {
			presetTypeId: null,
			searchValue: '',
		};

		this.onSearch = this.onSearch.bind(this);
		this.onSelect = this.onSelect.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.setTypeSegment = this.setTypeSegment.bind(this);

	}

	componentWillMount(){
		
		const { 
			clusterChains: {
				cluster:{
					chainComparePresetTypeList,
					chainReportPresetTypeList,
				},
			}, 
			popup: {
				callingTable
			},

		} = this.props;


	 	const presetTypeList = (callingTable === null)  ? chainReportPresetTypeList : chainComparePresetTypeList;
		const [, two] = presetTypeList;
		this.setTypeSegment(two);
	}

	componentDidMount() {
		this.perfectScrollbar = new PerfectScrollbar(this.nodeScrollBar, {
			wheelPropagation: false
		});
	}

	componentWillUnmount() {
		this.perfectScrollbar.destroy();
		this.perfectScrollbar = null;
	}


	// componentDidUpdate(prevProps, prevState) {
	componentDidUpdate() {
		// this.nodeScrollBar.scrollTop = 0;
		this.perfectScrollbar.update();
	}


	setTypeSegment(item){
		this.setState(() => ({
			presetTypeId: item.id,
			searchValue: '',
		}));
	}

	onSearch(event) {
		const valueText = event.target.value.trim().toLowerCase();
		this.setState({searchValue: valueText});
	}

	onSelect(chain){
		this.props.onSelect({
			type: CHAIN_COMPARE_TYPES.BRAND,
			payload: chain
		});
	}

	onCancel(){
		this.props.onCancel();
	}

	onSubmit(){
		if (this.props.onSubmit) {
			this.props.onSubmit();
		}
	}

	render() {

		const { 
			clusterChains: {
				cluster:{
					chainComparePresetTypeList = [],
					chainReportPresetTypeList = [],
					chainXlsReportPresetTypeList = [],
					hasChainReportTabs,
				},
			}, 
			chain, 
			filterCompareTo:{
				payload:filterCompareData, 
				type:filterCompareType,
			},
			sections,
			popup: {
				callingTable
			},
			highlightedChainsIds = [],
			isXls = false,
		} = this.props;

	
		const { 
			presetTypeId, 
			searchValue,
		} = this.state;


		const reportPresetTypeList = isXls && chainXlsReportPresetTypeList.length > 0 ? chainXlsReportPresetTypeList : chainReportPresetTypeList;
		const presetTypeList = ((callingTable === null)  ? reportPresetTypeList : chainComparePresetTypeList);
		const presetType = presetTypeList.find(({id}) => id === presetTypeId);
		const filteredPresetTypeList = presetTypeList.filter(presetType => (presetType.isCustom === false));
		const hasReportTabs = (callingTable === null && hasChainReportTabs && filteredPresetTypeList.length > 1);

		const  {
			chains = []
		} = sections[presetType.section] || {};

		const isSegmentGroups = chains.some( chain => chain.segmentGroupId !== null);

		const chainList = (isSegmentGroups === true && isMixLSRFSR(presetTypeList) === false) ? chains.filter(chain => chain.segmentGroupId === presetType.segmentGroup) : chains;
 
		const chainListInactive = [{
				chainId: chain.id,
				stateId: chain.stateId,
				dmaRegionId: chain.dmaRegionId,
			},
			...[(filterCompareType === CHAIN_COMPARE_TYPES.BRAND) ? {
				chainId: filterCompareData.id,
				dmaRegionId: filterCompareData.dmaRegionId,
				stateId: filterCompareData.stateId,
			} : null],
		].filter( element => element );

		const regEx = new RegExp('^'+ searchValue, 'ig');

		const extendViewChainId = JSON.parse(sessionStorage.getItem('EXTEND_VIEW_CHAIN_ID'));

		function isChainSelected (item) {
			return chainListInactive.some( inactiveChain => ( inactiveChain.chainId === item.id && inactiveChain.stateId === item.stateId && inactiveChain.dmaRegionId === item.dmaRegionId ));
		}

		const chainRenderList = chainList
			.filter(chain => {
				if (window.generalConfig.isDatassential === false)
					return true;

				if (extendViewChainId === chain.id)
					return true;

				if (chain.stateId === null && chain.dmaRegionId === null)
					return true;

				return false;
			})
			.filter(chain => !isChainSelected(chain))
			.filter( (chain) => ( chain.name.toLowerCase().search(regEx) === 0 ) )
			.map( (item, index) => {

				const classList = [
					...['preset-chains-caption preset-chains-caption--selectable'],
					...[isChainSelected(item) ? 'is-selected' : ''],
					...[highlightedChainsIds.includes(item.id) ? 'is-highlighted' : ''],
					...[(item.isDisabled === true) ? 'is-disabled' : '']
				].filter( element => element ).join(' ');

				return (
					<div className="preset-chains__item" key={`${item.id}${index}`}>
						<div
							className={classList}
							onClick={ (event) => {
								event.preventDefault();
								if (highlightedChainsIds.length < SELECTED_CHAINS_LIMIT || highlightedChainsIds.includes(item.id)) {
									this.onSelect(item);
								}
							}}
						>{item.name}</div>
					</div>
				)
			});


        const classListScroller = [
            ...['modal-body-scrollbar'],
            ...['preset-chains-holder'],
            ...[(hasReportTabs === true) ? 'modal-body-scrollbar--sm' : ''],
        ].filter( element => element ).join(' ');

		return (
			<div className="chains-list-container">
				{hasReportTabs === true && (
					<ul className="swich-group">
						{filteredPresetTypeList
							.map( (item, index) => {

							const classList = [
								...['swich-group-item'],
								...[(presetTypeId === item.id) ? 'is-active' : ''],
							].filter( element => element ).join(' ');

							return (
								<li 
									className={classList} 
									onClick={ (event) => {
										this.setTypeSegment(item);
									}} 
									key={index}>
										{item.name}
								</li>
							)

						})}
					</ul>
				)}
				<div className="chain-filter chain-filter--center">
					{this.props.isMultiple && (
						<div className="chain-filter__left">
							{highlightedChainsIds.length} competitor{highlightedChainsIds.length === 1 ? '' : 's'} selected {highlightedChainsIds.length >= SELECTED_CHAINS_LIMIT ? '(max)' : ''}
							<div className="tooltip-preset">
								<div className="tooltip-preset-chains" style={{ overflow: 'auto' }}>
									{chainList.filter(i => highlightedChainsIds.includes(i.id)).map(i => (
										<div
											key={i.id}
											className="tooltip-preset-chains__item"
										>
											<div style={{ paddingRight: 10 }}>{i.name}</div>
											{!isChainSelected(i) && (
												<span
													style={{ cursor: 'pointer' }}
													onClick={() => this.onSelect(i)}
												>
													<IconClose style={{ height: 10, width: 10 }} viewBox="0 0 30 30"/>
												</span>
											)}
										</div>
									))}
								</div>
							</div>
						</div>
					)}

					<div className="chain-filter__search">
						<div className="input-text-search-holder">
							<input type="text" className="input-text-search input-text-search--modal-chain" onChange={this.onSearch} value={searchValue} placeholder="Search chain here" />
							<svg className="icon icon-search" dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-search" />' }} />
							<div className="input-text-search-line"></div>
						</div>
					</div>
				</div>
				<div className={classListScroller} ref={node => { this.nodeScrollBar = node }}>
					{(chainRenderList.length > 0) ? (
							 <div className="preset-chains">{chainRenderList}</div>
						) : (
						<div className="text-center preset-chains__not-found">Nothing found...</div>
					)}
				</div>
				<div className="modal-bottom modal-bottom--center">
					<div className="modal-bottom-buttons modal-bottom-buttons--separe">
						{this.props.isMultiple ? (
							<button className="button button-primary" onClick={(event) => {
								event.preventDefault();
								this.onSubmit();
							}}>Finish & Download Report</button>
						) : (
							<button className="button button-primary" onClick={(event) => {
								event.preventDefault();
								this.onCancel();
							}}>Cancel</button>
						)}
					</div>
				</div>
			</div>
		);
	}
}

 
ChainPopupChain.propTypes = {
	clusterChains: PropTypes.object,
	chain: PropTypes.object,
	callback: PropTypes.func,
	filterCompareTo: PropTypes.object,
};

ChainPopupChain.defaultProps = {
	chains: [],
	clusterChains: {
		cluster: null,
		chains: [],
	},
};

const mapStateToProps = (state, props) => ({
	clusterChains: getChainsByCluster(state, props),
	filterCompareTo: state.chain.filterCompareTo,

	popup: state.chain.competetivePopup,
	sections: state.init.sections,
});

export default connect(mapStateToProps)(ChainPopupChain);