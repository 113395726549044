import {createSelector} from 'reselect';
import { SECTIONS } from '../../utils/constants';
import { lowerCaseFirstLetter } from '../../utils/helpers';

export const getHasHistoryChain = createSelector(
	(state) => state.chain.header.data,
	(headerData) => {
		if (headerData)
			return headerData.hasHistory;
		return false;
	}
)


const selectSections = (state) => state.init.sections;
const sectionLocationAlias = (state, props) => props.match.params[0] || null;
const idLocationChain = (state, props) => props.match.params.id || null;
const idLocationParam = (state, props) => props.match.params.paramId || null;
const avaliableChainViews = (state) => state.init.avaliableChainViews;

export const getChainData = createSelector(
	selectSections,
	sectionLocationAlias,
	idLocationChain,
	idLocationParam,
	avaliableChainViews,
	(stateSections, locationAlias, chainId, paramId, avaliableChainViews) => {

		const cluster = SECTIONS.find( (element) => element.aliasName === locationAlias ) || null;
 
 		const chainList =  (cluster === null) ? [] : stateSections[cluster.systemName].chains;

		const chain = chainList.find(chain => {

			if (paramId){

				const {
					property = '',
				} = avaliableChainViews.find(chainView => chainView.ffChainId === chain.id) || {};

				const chainProp = lowerCaseFirstLetter(property);

				return chain.id === +chainId && chain[chainProp] === +paramId;

			}

			return chain.id === +chainId;
						 
		}) || null;



		return {
			ready: (chainList.length > 0) ? true : false,
			success: (chain === null && chainList.length > 0) ? false : true,
			tableGroups: stateSections[cluster.systemName].tableGroups,
			chain: chain
		}
	}
)


const selectDataGroups = (state) => state.chain.dataGroups;
const selectPropsTable = (state, props) => props.table;


// @TODO: need remake
export const getDataTable = createSelector(
	selectDataGroups,
	selectPropsTable,
	(dataGroups, config) => {

		const group = (dataGroups || []).find( (element) => (element.methodName === config.groupMethodName) ) || null;

		if (group === null)
			return group

		const table = group.tables.find( (element) => (element.methodName === config.methodName) ) || null;
  
		return table.data;
	}
)

// @TODO: need remake
export const getIsHistoryTable = createSelector(
	selectDataGroups,
	selectPropsTable,
	(dataGroups, config) => {

		const group = (dataGroups || []).find( (element) => (element.methodName === config.groupMethodName) ) || null;

		if (group === null)
			return group

		const table = group.tables.find( (element) => (element.methodName === config.methodName) ) || null;
  
		return table.isHistory;
	}
)

// @TODO: need remake
export const hasShowSection = createSelector(
	selectDataGroups,
	selectPropsTable,
	(dataGroups, config) => {

		const group = (dataGroups || []).find( (element) => (element.methodName === config.groupMethodName) ) || null;

		if (group === null)
			return true

		const table = group.tables.find( (element) => (element.methodName === config.methodName) ) || null;

		return (table.data === null && group.isLoaded === true)
	}
)
