import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Scrollbar from 'smooth-scrollbar';

import {defaultSortMethod} from '../../utils/helpers';

import Tooltip from '../../components/Tooltip';
import TableCellContent from './Table.Cell.Content';
import {borowserDetect} from '../../utils/helpers';

const getColumnConfig = (columns) => {

	const config = [];
		columns.forEach( (columnGroup) => {
			if (columnGroup.columns.length){
				columnGroup.columns.forEach( (column) => {
					config.push({className: column.className, type: column.type, id: column.id });
				})
			} else {
				config.push({className: columnGroup.className, type: columnGroup.type, id: columnGroup.id})
			}
		})

	return config;
}

const getDefaultSortColumn = (columnsGroups) => {
	return columnsGroups.reduce( (defaultSortColumns, group) => {
		if (group.isColumn === true && group.orderBy !== null)
		   defaultSortColumns.push(group);
		   group.columns.forEach( column => {
				if (column.isColumn === true && column.orderBy !== null)
				   defaultSortColumns.push(column);
		   })
		   
		return defaultSortColumns;
	} , [] );
}


class Table extends Component{

	constructor (props) {
		super(props);

		this.onSort = this.onSort.bind(this);
 
		this.handleSort = this.handleSort.bind(this);
		this.buildFooter = this.buildFooter.bind(this);
		this.buildBodyRow = this.buildBodyRow.bind(this);


		this.adjustFixedTable = this.adjustFixedTable.bind(this);

		const [defaultSortColumn] = getDefaultSortColumn(this.props.columnsGroups);

		this.state = {
			columnId: (defaultSortColumn) ? defaultSortColumn.id : null,
			sortOrder: (defaultSortColumn) ? defaultSortColumn.orderBy : null,
		}

		this.nodeTableMain = React.createRef();
		this.nodeTableFixed = React.createRef();

		this.instanceScrollbar = null;
	}

	componentDidMount() {
		if (this.props.isScrollBar){
			this.instanceScrollbar = Scrollbar.init(this.nodeScrollBar,{
				continuousScrolling: false
			});

			if (borowserDetect().isIE() === false && borowserDetect().isEdge() === false){
				const event = new Event('smoothScrollEvent');

				this.instanceScrollbar.addListener( (status) => {
					this.nodeScrollBar.dispatchEvent(event);  
				});
			}

			this.nodeScrollBar.smoothScroll = this.instanceScrollbar;
		}

		if (this.props.fixHeaderPositionTop !== null){
			window.addEventListener('scroll', this.adjustFixedTable);
			window.addEventListener('resize', this.adjustFixedTable);
		}

		this.adjustFixedTable();
	}

	componentDidUpdate() {
		if (this.props.isScrollBar && this.instanceScrollbar)
			this.instanceScrollbar.scrollTo(0,0);
	}

	componentWillUnmount() {
		if (this.props.isScrollBar){
			this.instanceScrollbar.destroy();
			this.instanceScrollbar = null;
		}

		if (this.props.fixHeaderPositionTop !== null){
			window.removeEventListener('scroll', this.adjustFixedTable);
			window.removeEventListener('resize', this.adjustFixedTable);
		}
	}

	componentWillReceiveProps(nexProps){

		if (this.props.onSortColumn.length){
	 		const [defaultSortColumn] = getDefaultSortColumn(nexProps.columnsGroups);

			this.setState((prevState) => ({
				columnId: (defaultSortColumn) ? defaultSortColumn.id : null,
				sortOrder: (defaultSortColumn) ? defaultSortColumn.orderBy : null,
			}));
		}
	}

	adjustFixedTable(){

		const {fixHeaderPositionTop} = this.props;

		if (fixHeaderPositionTop !== null && this.nodeTableMain.current){

			const clientRectTableMain = this.nodeTableMain.current.getBoundingClientRect();
			 
			this.nodeTableFixed.current.style.top = `${fixHeaderPositionTop}px`;
			this.nodeTableFixed.current.style.width = `${clientRectTableMain.width}px`;
			this.nodeTableFixed.current.style.left = `${clientRectTableMain.left}px`;


			if (clientRectTableMain.top < this.props.fixHeaderPositionTop) {
				this.nodeTableFixed.current.classList.add('is-sticky');
			} else {
				this.nodeTableFixed.current.classList.remove('is-sticky');
			}
		}
	}
 
	onSort(columnId) {
		const reverseOrder = (order) => ((order === "asc") ? "desc" : "asc");

		this.setState((prevState) => ({
			columnId: columnId,
			sortOrder: (columnId === prevState.columnId) ? reverseOrder(prevState.sortOrder) :  "desc"
		}), () => {
			const {columnId, sortOrder} = this.state;
			this.props.onSortColumn({columnId, sortOrder});
		})
	}

	handleSort() {
		const {rowsGroups} = this.props;
		const {columnId, sortOrder} = this.state;

		const columns = this.configColumns
			.map((element, index) => ({
				index: index,
				...element
			}))
			.filter( (element) => element.id === columnId );
  
		if (columns.length < 1)
			return rowsGroups;

			const [column] = columns,
			listNumberString = ['zerro', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];

	
			return rowsGroups.map(group => {

				const rows = group.rows.slice().sort((a, b) => {

					a = a[column.index].value;
					b = b[column.index].value;

					// @TODO: remake
					if (column.type === 'chainLink'){
						const firstChar = a.charAt(0).toLowerCase();

						if (isNaN(firstChar) === false)
							a =  listNumberString[+firstChar] + a.substr(1);


						const firstCharB = b.charAt(0).toLowerCase();

						if (isNaN(firstCharB) === false)
							b =  listNumberString[+firstCharB] + b.substr(1);
					}

					return defaultSortMethod(a,b);
				});

				return{
					...group,
					rows: (sortOrder === "desc") ? rows.reverse() : rows
				}

			});
	}

	rowCellsHeaderGroup(columns) {
		const {columnId, sortOrder} = this.state;

		const cellsGroup = columns.map((column, index) => {

			const classList = [
				...['cell', column.className],
				...[(column.columns.length === 0) ? "cell--sorting" : ""],
				...[(column.columns.length === 0 && column.id === columnId ) ? `cell--sorting-${sortOrder}` : ""]
			].filter( element => element ).join(' ');

			return (
				<th key={index} className={classList} {...((columns) => { 

					return (columns.length) ? { colSpan: columns.length } : { rowSpan: 2 }

				})(column.columns) } onClick={ (event) => {
					event.preventDefault();
					if (column.isSorting === true)
						this.onSort(column.id);
				}}><span className="cell-text-string" title={column.label}>{column.label}</span></th>
			)
		})

		return <tr>{cellsGroup}</tr>
	}

	rowCellsHeader(columns) {
		const {columnId, sortOrder} = this.state;

		const cells = columns.map((columnGroup, indexGroup) => {
			return columnGroup.columns.map((column, index) => {

				const classList = [
					...['cell cell--base', column.className],
					...[(column.isSorting === true) ? `cell--sorting` : ``],  
					...[(column.id === columnId) ? `cell--sorting-${sortOrder}` : ``]
				].filter( element => element ).join(' ');

				return (
					<th key={index} className={classList} onClick={ (event) => {
						event.preventDefault();
						if (column.isSorting === true)
							this.onSort(column.id);

					}}>{this.props.onRenderHeaderCell(column)}</th>
				)
			})
		})

		return <tr>{cells}</tr>
	}

	buildBody() {
		return (
			<tbody className="table-body">
				{this.handleSort().map((rowsGroup, index) => {
					
					return rowsGroup.rows.reduce( (rows, row, ind) => {

				 		return [ ...rows, this.buildBodyRow(row, `${index}${ind}`) ]
 
					}, [

						...[(rowsGroup.group) ? [
							<tr key={index}>
								<td className="cell cell--group">{rowsGroup.group.label}</td>
								<td className="cell cell--group" colSpan={rowsGroup.group.colSpan}></td>
							</tr>
						] : []]

					]);

				})}
			</tbody>
		)
	}

 
	buildBodyRow(row, index){
		return (
			<tr key={index}>
				{row.map((cell, indexCell) => {
					const {
						className, 
						type, 
						// id
					} = this.configColumns[indexCell];

					const content = {
						...cell, 
						...( (cell.hasOwnProperty('type') !== true) ? {type} : {}),
					}

					const classList = [
						...['cell', className],
						...[(cell.hasOwnProperty('className') === true) ? cell.className : ``],  
					].filter( element => element ).join(' ');

					return (
						<td className={classList} key={`${index}${indexCell}`} >
							{ cell.hasOwnProperty('tooltip') === true ? 
								<Tooltip content={<TableCellContent content={{
									...cell.tooltip,
									caption: cell.value
								}} />}><TableCellContent content={content} /></Tooltip> : 
								<TableCellContent content={content} />
							}
						</td>
					)
				})}
			</tr>
		)
	}

	buildFooter(rows) {
		return (
			<tfoot className="table-foot">
				{rows.map((row, indexRow) => {
					return (
						<tr key={indexRow}>
							{row.map((cell, indexCell) => {
								const {
									className = '', 
									// type = ``
								} = this.configColumns[indexCell];

								return <td key={`${indexRow}${indexCell}`} className={`cell ${className}`}>{(cell.value === null) ? `-` : cell.value.toLocaleString()}</td>
							})}
						</tr>
					)
				})}
			</tfoot>
		)
	}	
 
 
	render() {
		const {
			columnsGroups, 
			className, 
			isScrollBar,
			fixHeaderPositionTop
		} = this.props;
		
		this.configColumns = getColumnConfig(columnsGroups);

		return(
			<div className="table-holder">

				{(isScrollBar === true) ? (

						<Fragment>
							<table className={`table ${this.props.className}`}>
								<thead className="table-head">
									{columnsGroups.some( group => group.label.length > 0 ) === true && this.rowCellsHeaderGroup(columnsGroups)}
									{this.rowCellsHeader(columnsGroups)}
								</thead>
							</table>
							<div className="table-scrollbar js-smooth-scroll" ref={ node => this.nodeScrollBar = node}>
								<table className={`table ${className}`}>
									{this.props.rowsGroups.length > 0 && this.buildBody()}
								</table>
							</div>
							<table className={`table ${this.props.className}`}>
								{this.buildFooter(this.props.rowsFooter)}
							</table>
						</Fragment>
					
					) : (
						<Fragment>
							<table className={`table ${this.props.className}`} ref={this.nodeTableMain}>
								<thead className="table-head">
									{columnsGroups.some( group => group.label.length > 0 ) === true && this.rowCellsHeaderGroup(columnsGroups)}
									{this.rowCellsHeader(columnsGroups)}
								</thead>

								{this.props.rowsGroups.length > 0 && this.buildBody()}
								{this.buildFooter(this.props.rowsFooter)}
							</table>

							{fixHeaderPositionTop !== null  && 
								<table className={`table table-fixed ${this.props.className}`} ref={this.nodeTableFixed}>
									<thead className="table-head">
										{columnsGroups.some( group => group.label.length > 0 ) === true && this.rowCellsHeaderGroup(columnsGroups)}
										{this.rowCellsHeader(columnsGroups)}
									</thead>
								</table>}	
						</Fragment>
					)} 
			 
			</div>
		)
	} 
}




Table.propTypes = {
	className: PropTypes.string,
	data: PropTypes.object,
	onRenderHeaderCell: PropTypes.func,
	onSortColumn: PropTypes.func,
	isScrollBar: PropTypes.bool,
	fixHeaderPositionTop: PropTypes.number,

};

Table.defaultProps = {
	className: "",

	columnsGroups: [],
	rowsGroups: [],
	rowsFooter: [],

	onRenderHeaderCell: column => (column.label),
	onSortColumn: () => {},

	isScrollBar: true,
	fixHeaderPositionTop: null,
};

export default Table;
