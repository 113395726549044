import React, { Component, Fragment } from "react";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {Link, NavLink} from 'react-router-dom';

import {saveAs} from 'file-saver';

import Dropdown from '../../components/Dropdown';
import ChainLogo from '../../components/ChainLogo';
import GaugeCircle from '../../components/GaugeCircle';
import NavFoodTabTooltip from './NavFoodTabTooltip';

import {setRespondentType, getChainDataHeader, toggleCompetetivePopup, changeReportCompareTo} from '../../actions/chain';
import {getChainData} from '../../route/Chain/Chain.selectors';
import {getPresetsByCluster} from '../../selectors/section.selectors';

import {
	smoothScrollTo,
	lowerCaseFirstLetter,
} from '../../utils/helpers';

import Notification from '../../utils/notification';

import {
	ROOT_URL, 
	// CHAIN_COMPARE_TYPES, 
	SECTIONS, 

	BUTTONS_DOWNLOAD,
	CHAIN_FOOD_TAB_ALIAS,
	// EXTEND_VIEW_CHAIN_LIST,
	// IS_DATASSENTIAL,
} from '../../utils/constants';

import {
	getPostDataCompare,
} from './helper.js';
import TrendExport from "./TrendExport/TrendExport";
import { PlatformContext } from "@datassential/platform-ui-lib";



const extendChainInfo = ({
	avaliableChainViews,
	chainStateDmaRegionViewSettings,
	chainId,
	paramId,
}) => {

	// const chainView = avaliableChainViews.find(chain => chain.ffChainId === chainId) || null;

	const {
		property = '',
	} = avaliableChainViews.find(chain => chain.ffChainId === chainId) || {};

	if (property){
		const chainProp = lowerCaseFirstLetter(property);
		return chainStateDmaRegionViewSettings.find(option => (option[chainProp] === paramId)) || null;
	}

	return null;
}

class ChainHeader extends Component {

	constructor(props){
		super(props);

		this.state = {
			isLoadingReport: false
		}

		this.onWindowScroll = this.onWindowScroll.bind(this);
		this.onBuildReport = this.onBuildReport.bind(this);
		this.getReportButton = this.getReportButton.bind(this);
		this.onClickMenu = this.onClickMenu.bind(this);
		this.scrollToSection = this.scrollToSection.bind(this);
		this.buttonTypeId = null;
		this.notification = new Notification();
	}

	componentWillMount() {
		this.loadHeaderData(this.props);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		window.addEventListener('scroll', this.onWindowScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.onWindowScroll);
	}
 
	componentWillReceiveProps(nextProps){

		if (nextProps.reportCompareTo.payload !== null){
			this.onBuildReport({
				compareData: nextProps.reportCompareTo.payload,
				compareType: nextProps.reportCompareTo.type
			});

			this.props.changeReportCompareTo({
				data: null,
				type: null
			});
		}

		this.loadHeaderData(nextProps);
	}

	componentDidUpdate(){
		const {
			match:{
				params:{
					groupName
				}
			},
			dataGroups
		} = this.props;

		const groupData = dataGroups && (dataGroups.find((element) => (element.aliasName === groupName)) || null);
		this.scrollToSection(groupData);
	}

	getScrollOffset () {
		const { userInfo: { featureFlags } } = this.context;
		const isPlatformEnabled = (featureFlags || []).includes('enable platform');

		return isPlatformEnabled  ? 180 : 50 ;
	}

	scrollToSection = (groupData = null) => {
		const hashUrl = window.location.hash.substr(1);

		if (hashUrl)
			setTimeout(() => {

				const nodeSection = document.getElementById(hashUrl);
				if(nodeSection) {
					const scroll = nodeSection.offsetTop - this.getScrollOffset();
					smoothScrollTo(scroll, 1000);
				}
				if (groupData && groupData.isLoaded === true)
					window.history.pushState('', document.title, window.location.pathname);

			}, 600);
	}

	loadHeaderData(props){
		const {
			mainData,
			header,
			respondentTypeId,
			clusterPreset: {
				presets
			},
			match:{
				params:{
					0: clusterName
				}
			}
		} = props;

		if (mainData.ready === true && mainData.success === true
			&& header.isLoaded === false 
			&& header.isLoadProcess === false)

			this.props.getChainDataHeader({
				presets: presets,
				chainId: mainData.chain.id,
				stateId: mainData.chain.stateId,
				dmaRegionId: mainData.chain.dmaRegionId,
				dataType: respondentTypeId,
				cluster: clusterName
			})
	}
 
	onWindowScroll(){
		if (window.pageYOffset > this.nodeNavigation.offsetTop) {
			this.nodeNavigation.firstChild.classList.add('is-sticky');
		} else {
			this.nodeNavigation.firstChild.classList.remove('is-sticky');
		}
	}

	onTrendExportBuild = (data = {}) => {
		const {
			selectedChains = [],
			activePresetId = null,
		} = data;
		const {
			mainData: {
				chain,
			},
			respondentTypeId,
		} = this.props;

		const payload = {
			chain: {
				id: chain.id,
				stateId: chain.stateId,
				dmaRegionId: chain.dmaRegionId,
			},
			presetId: activePresetId,
			chainsToCompare: selectedChains,
			respondentTypeId,
		};

		this.setState({
			isLoadingReport: true
		});

		const xhr = new XMLHttpRequest();

		xhr.open('POST', `${process.env.REACT_APP_API}Export/GetTrendReport`);
		xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
		xhr.withCredentials = true;
		xhr.responseType = 'blob';
		xhr.onload = event => {

			this.setState({
				isLoadingReport: false
			});

			if (xhr.response.size === 0 && xhr.response.type === "text/xml"){
				window.location.href = ROOT_URL;
			} else {


				const disposition = xhr.getResponseHeader("Content-Disposition");
				if (disposition && disposition.indexOf('attachment') !== -1) {
					const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
						matches = filenameRegex.exec(disposition);

					if (matches != null && matches[1]){
						const filename = matches[1].replace(/['"]/g, '');
						saveAs(xhr.response, filename);

					}
				} else {
					this.notification.add(`Download failed...`);
				}


			}

		};
		xhr.send(
			JSON.stringify(payload)
		);

	}

	onBuildReport(compare){

		const {
			mainData:{
				chain: {
					id: chainId,
					stateId,
					dmaRegionId,
				}
			},
			respondentTypeId
		} = this.props;

		this.setState({
			isLoadingReport: true
		});


		const postData = getPostDataCompare({
			buttonTypeId: this.buttonTypeId, 
			chainId, 
			stateId,
			dmaRegionId,
			compare,
			respondentTypeId
		});

		const xhr = new XMLHttpRequest();

			xhr.open('POST', `${process.env.REACT_APP_API}Export/${postData.handlerMethod}`);
			xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
			xhr.withCredentials = true;
			xhr.responseType = 'blob';
			xhr.onload = event => {

				this.setState({
					isLoadingReport: false
				});

				if (xhr.response.size === 0 && xhr.response.type === "text/xml"){
					window.location.href = ROOT_URL;
				} else {


					const disposition = xhr.getResponseHeader("Content-Disposition");
					if (disposition && disposition.indexOf('attachment') !== -1) {
						const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/, 
							matches = filenameRegex.exec(disposition);

						if (matches != null && matches[1]){
							const filename = matches[1].replace(/['"]/g, '');
							saveAs(xhr.response, filename);

						}
					} else {
						this.notification.add(`Download failed...`);
					}


				}
 
			};
			xhr.send(
				JSON.stringify(postData.post)
			);

	}

	onClickNav = () => {
		if (window.pageYOffset > this.nodeNavigation.offsetTop)
			smoothScrollTo(this.nodeNavigation.offsetTop, 1000);  
	}

	onClickMenu = (tableAliasName) => {
		const nodeSection = document.getElementById(tableAliasName);
		if (nodeSection)
			smoothScrollTo((nodeSection.offsetTop - this.getScrollOffset()), 1000);
	}

	getReportButton(){

		const {
			isLoadingReport
		} = this.state;

 		const { 
			mainData,
			match:{
				params:{
					// 0: clusterName,
					groupName: aliasName,
				}
			},

			filterCompareTo:{
				payload: compareData,
				type: compareType
			},

		} = this.props;

		const classList = [
			...['button-report'],
			...[(isLoadingReport === true) ? 'button-report--busy' : ''],
			...[(aliasName === CHAIN_FOOD_TAB_ALIAS) ? 'button-report--hidden' : ''],
		].filter( element => element ).join(' ');

		// const [firstSection] = SECTIONS;

		return (
			<div className={classList}>
				<div className="button-report__busy">
					<span className="button-report-label">Downloading...</span>
				</div>
				<div className="button-report__label">
					<svg className="icon icon-download" dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-download" />'}} />
					{(window.generalConfig.access.trial === true) ? `Sample report` : `Create report`}
				</div>
				<div className="button-report__buttons">
					<button className="button button-report__btn" onClick={(event) => {
						event.preventDefault();
						this.buttonTypeId = BUTTONS_DOWNLOAD.REPORT_PPT_BIG;

						if (mainData.ready === true){
							if (window.generalConfig.access.trial === true){
								this.onBuildReport({
									compareData,
									compareType
								});
							} else {
								this.props.toggleCompetetivePopup(null);
							}
						}
					}}>PPT 16:9</button>
					{window.generalConfig.access.trendExport === true ? (
						<>
							{window.generalConfig.access.trial === true ? (
								<button
									className="button button-report__btn"
									onClick={() => this.onTrendExportBuild()}
								>
									XLSX
								</button>
							) : (
								<TrendExport
									chain={mainData.chain}
									onSubmit={this.onTrendExportBuild}
								/>
							)}
						</>
					) : (
						<button
							className="button button-report__btn"
							onClick={(event) => {
								event.preventDefault();
								this.buttonTypeId = BUTTONS_DOWNLOAD.REPORT_XLS;

								if (mainData.ready === true){
									if (window.generalConfig.access.trial === true){
										this.onBuildReport({
											compareData,
											compareType
										});
									} else {
										this.props.toggleCompetetivePopup(null);
									}
								}
							}}
						>
							XLSX
						</button>
					)}
				</div>
			</div>
		)
	}

	render() {
		
		const {
			respondentTypesList,
			respondentTypeId,
			mainData,
			header:{
				data: headerData
			},
			match:{
				params:{
					0: clusterName,
					// id: idUrl,
					groupName: aliasName,
					paramId,
				}
			},

			foodTabChains,

		} = this.props;



		const {excludeListRespondentTypeNames = []} = SECTIONS.find(cluster => cluster.aliasName === clusterName) || null;

		const [firstSection] = SECTIONS;
		const currentPreiodName = (firstSection.aliasName === clusterName) ? this.props.currentPeriodName : this.props.supermarketCStoreCurrentPeriodName;
		
		// const extendViewChainId = JSON.parse(sessionStorage.getItem('EXTEND_VIEW_CHAIN_ID'));
		const extendChain = (mainData.chain) ? extendChainInfo({
			avaliableChainViews: this.props.avaliableChainViews,
			chainStateDmaRegionViewSettings: this.props.chainStateDmaRegionViewSettings,
			chainId: mainData.chain.id,
			paramId: +paramId,
		}) : null;

 		return (
			<section className="section">
				<div className="section-content main-score">
					<div className="main-score__logo">
						<ChainLogo { 
							...( mainData.chain !== null && { 
								image: mainData.chain.logo 
							})}
						/>
						{extendChain && (
							<Fragment>
								<div className="main-score__caption">{extendChain.name}</div>
								<div className="main-score__desc">*In this chain view, {mainData.chain.name} and competitors are both reflective of {extendChain.shortName} visitors only</div>
							</Fragment>
						)}
					</div>

					<div className="main-score__gauges">
						{(headerData ? headerData.circles : new Array(3).fill(null) ).map( (circle, key) => {
							return <GaugeCircle
								className="main-score__gauges-item"
								key={key}
								{ ...( circle && { name: circle.title } ) } 
								{ ...( circle && { data: {
									value: circle.chainValue,
									tooltipMax: circle.max,
									tooltipMin: circle.min,
									tooltipAvg: circle.average,
									segmentName: headerData.segmentName,
								}}) }
							/>
						})}
					</div>
 
					<div className="main-score__panel">
						<div className="panel-score">
							<div className="panel-score-header">NET PROMOTER SCORE</div>
							<div className="panel-score-content">
								<div className="panel-score-content__item">
									{headerData && headerData.promoter}% promoter
								</div>
								<div className="panel-score-content__item">
									{headerData && headerData.passive}% passive
								</div>
								<div className="panel-score-content__item">
									{headerData && headerData.detractor}% detractor
								</div>
							</div>
							<div className="panel-score-rounded">
								{headerData && <span className="panel-score-rounded__value">{(+headerData.nps > 0) ? `+${headerData.nps}` : headerData.nps}</span>}
							</div>
						</div>

						<div
							className="main-score__report button-report-holder"
							style={{ pointerEvents: headerData ? '' : 'none' }}
						>
			 				{this.getReportButton()}
						</div>
					</div>
				</div>

				<div className="section-navigation" ref={node => this.nodeNavigation = node}>
					<div className="navigation-panel navigation-panel--wide animation-show-navigation-panel">
						<div className="navigation-panel-container">

							<div className="navigation-panel-home">
								<Link to={{ pathname: `${ROOT_URL}` }} className="navigation-panel-home__link">
									<svg className="icon icon-home" dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-home" />' }} />
								</Link>
								{mainData.chain !== null && (
									<div className="navigation-panel-home__caption" title={mainData.chain.name}>{mainData.chain.name}</div>
								)}
							</div>

							{(mainData.ready === true && mainData.success === true) && (
								<ul className="tab-navigation">
									{mainData.tableGroups
										.filter((group) => {
	 
											if (group.aliasName === CHAIN_FOOD_TAB_ALIAS){

												if (foodTabChains.length && mainData && foodTabChains.includes(mainData.chain.id))
													return group;
												return null;
											}

											return group;
										})
										.map((group, indexGroup) => {

											return (
												<li 
													key={`${indexGroup}${group.aliasName}`} 
													className="tab-navigation__item">

													{(group.aliasName === CHAIN_FOOD_TAB_ALIAS && window.generalConfig.access.foodTab === false) ? (

														<Fragment>
															<span className="tab-navigation__caption">{group.name}</span>
															<span className="tab-navigation-menu">
																<NavFoodTabTooltip />
															</span>
														</Fragment>

														) : (

														<Fragment>
															<NavLink 
																activeClassName="is-active" 
																className="tab-navigation__link" 
																to={{ 
																	pathname: `${ROOT_URL}${clusterName}/chain/${mainData.chain.id}${(paramId) ? `/${paramId}`:``}/${group.aliasName}` 
																}} 
																onClick={event => {
																	 
																	this.onClickNav();

																	if (aliasName === group.aliasName)
																		event.preventDefault();

																}}>{group.name}</NavLink>
														
																{headerData && (
																	<span className="tab-navigation-menu">
																		<span className="tab-navigation-menu-list">
																			{group.tables
																				.filter( table => {
																					return (headerData.hiddenTables ? headerData.hiddenTables : []).includes(table.methodName) !== true							
																				})
																				.map((table, indexTable) => ( 
																				<Link className="tab-navigation-menu__link" 
																					key={`${indexGroup}${indexTable}`} 
																					to={{ 
																						pathname: `${ROOT_URL}${clusterName}/chain/${mainData.chain.id}${(paramId) ? `/${paramId}`:``}/${group.aliasName}`,
																						hash: `#${table.aliasName}`
																					}}
																					onClick={event => {

																						if (aliasName === group.aliasName)
																							event.preventDefault();

																							this.onClickMenu(table.aliasName);

																					}}>{table.name}</Link>
																			))}
																		</span>
																	</span>
																)}
														</Fragment>
													)}

												</li>
											)
									})}
								</ul>
							)}

							<div className="navigation-panel__period">{currentPreiodName}</div>

							<div className="navigation-panel-dropdown navigation-panel-dropdown--chain">
								<div className="dropdown-label">AMONG:</div>
								<Dropdown 
									className="dropdown-respondent"
									options={respondentTypesList.reduce((respondentGroups, group) => {
										const items = group.items.filter( respondent => excludeListRespondentTypeNames.includes(respondent.name) !== true  )
										return [
											...respondentGroups,{
												...group, 
												items
											}
										]
									}, [])} 
									optionSelectedId={respondentTypeId}
									onChange={(respondent) => this.props.setRespondentType(respondent.id)}
								/>
							</div>

							<div className="navigation-panel__report button-report-holder button-report-holder--small">
			 					{this.getReportButton()}
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}


ChainHeader.propTypes = {
	respondentTypeId: PropTypes.number,
	respondentTypesList: PropTypes.array,

	mainData: PropTypes.object,
	сlusterPresets: PropTypes.object,
	header: PropTypes.object,

	setRespondentType: PropTypes.func,
	getChainDataHeader: PropTypes.func,
};

ChainHeader.contextType = PlatformContext;

const mapStateToProps = (state, props) => ({
	respondentTypeId: state.chain.filterRespondentTypeId,
	respondentTypesList: state.init.respondentTypes,

	dataGroups: state.chain.dataGroups,
	
	mainData: getChainData(state, props),
	clusterPreset: getPresetsByCluster(state, props),

	header: state.chain.header,

	foodTabChains: state.init.foodTabChains,

	currentPeriodName: state.init.currentPeriodName,
	supermarketCStoreCurrentPeriodName: state.init.supermarketCStoreCurrentPeriodName,

	filterCompareTo: state.chain.filterCompareTo,
	reportCompareTo: state.chain.reportCompareTo,

	chainStateDmaRegionViewSettings: state.init.chainStateDmaRegionViewSettings,
	avaliableChainViews: state.init.avaliableChainViews,

});

const mapDispatchToProps = dispatch => ({
	setRespondentType: (id) => dispatch(setRespondentType(id)),
	getChainDataHeader: (data) => dispatch(getChainDataHeader(data)),

	toggleCompetetivePopup: (data) => dispatch(toggleCompetetivePopup(data)),
	changeReportCompareTo: (data) => dispatch(changeReportCompareTo(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChainHeader);