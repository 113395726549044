import { combineReducers } from 'redux';

import init from './init';
import presets from './presets';
import rankings from './rankings';
import chain from './chain';

export default combineReducers({
	init,
	presets,
	rankings,
	chain
});