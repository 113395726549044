import React, { Component } from "react";
import {connect} from 'react-redux';
// import PropTypes from 'prop-types';
// import {withRouter} from 'react-router';

import Preloader from '../../components/Preloader';
import ClipboardButtons from '../../components/ClipboardButtons';
 
import {getDataByTable} from '../../actions/rankings';
import {getDataTable, getIsHistoryTable} from '../../route/Rankings/Rankings.selectors';
import { capitalizeFirstLetter } from '../../utils/helpers';

import RankingCurrent from './Ranking.Current';
import RankingHistory from './Ranking.History';

import {SECTIONS} from '../../utils/constants';

class Ranking extends Component {

	constructor(props){
		super(props);
 		this.onChangeTypeTable = this.onChangeTypeTable.bind(this);
	}

	onChangeTypeTable(event){
		event.preventDefault();

		const {
			group: {
				methodName: groupMethodName
			},
			table: {
				methodName: tableMethodName
			},
			isHistoryTable,
			respondentTypeId,
			match:{
				params:{
					0: clusterName
				}
			},

		} = this.props;

		const {id = null} = SECTIONS.find(cluster => cluster.aliasName === clusterName) || {};
 
		this.props.getDataByTable({
			groupMethodName,
			tableMethodName,
			isHistory: !isHistoryTable,
			respondentTypeId,
			chainType: id,
		});
 
	}
 
	render() {

		const { 
			group,
			table,
			chainList, 
			dataTable,
			isHistoryTable,
			match,
			history,
		} = this.props;


		const historyFieldCompareTo = (dataTable !== null && isHistoryTable === true) ? dataTable.columnsGroups.reduce((nameField, group) => {   
			const column = group.columns.find( column => column.isAllowToggleVisible === true && column.isVisible === true ) || null;
			return (column) ? column.label : nameField
		}, '').trim() : null;

		return (
			<section className="section" id={table.aliasName} snapshot={table.methodName}>
				<div className="section-header">
					<div className="section-header-meta">
						<div className="section-header-meta__caption">
							{table.name}
							{historyFieldCompareTo && (
								<span className="field-name"> - {historyFieldCompareTo}</span>
							)}
						</div>
						{table.description && <div className="section-header-meta__description">{capitalizeFirstLetter(table.description)}</div>}
					</div>

					<div className="section-header-controls snapshot-disable">
						{(dataTable !== null && window.generalConfig.access.trial === false) && (
							<ClipboardButtons 
								fileName={table.name}
								nodeNameAttribute={table.methodName}
							/>
						)}
					</div>
					<div className="section-header-copyright snapshot-only">Datassential</div>	
				</div>
		 
				<div className="section-content">
		 			{dataTable === null && <Preloader type="table"/>}
					{dataTable && isHistoryTable === false && (
						<RankingCurrent 
							group={group}
							table={table}
							chainList={chainList}
							dataTable={dataTable}
							history={history}
							match={match}
						/>
					)}

					{dataTable && isHistoryTable === true && (
						<RankingHistory 
							group={group}
							table={table}
							chainList={chainList.filter(chain => {
								return chain.stateId === null && chain.dmaRegionId === null;
							})}
							dataTable={dataTable}
							history={history}
							match={match}
						/>
					)}
				</div>

				<div className="section-footer">
					<div className="section-footer-enter">
						{table.question && <div className="section-footer-question" title={table.question}>
							<span className="section-footer-question__char">Q:</span> {table.question}
						</div>}

						{dataTable !== null && table.hasHistory === true && <div className="section-footer-button-chart snapshot-disable clipboard-disable">			 
							<button className="button button-period" onClick={event => this.onChangeTypeTable(event)}>
								View {(isHistoryTable === false) ? 'history' : 'current'}
							</button>
						</div>}
					</div>
				</div> 
			</section>
		);
	}
}

const mapStateToProps = (state, props) => ({
	dataTable: getDataTable(state, props),
	isHistoryTable: getIsHistoryTable(state, props),
	respondentTypeId: state.rankings.filterRespondentTypeId,
});

const mapDispatchToProps = dispatch => ({
	getDataByTable: (data) => dispatch(getDataByTable(data)),
});

 
export default connect(mapStateToProps, mapDispatchToProps)(Ranking);