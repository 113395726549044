import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

	import TableView from './TableView';
	import SituationAssessment from './SituationAssessment';
	import ChartScatter from './ChartScatter';
	import {DashboardData, DashboardLocation} from './Dashboard';
	import BrandFunnel from './BrandFunnel';
	import HistogramRanking from './HistogramRanking';
	import ToggleList from './ToggleList';
	import WordCloudBox from './WordCloudBox';
	import CompetitiveChart from './CompetitiveChart';
	import MenuItems from './MenuItems';
	import MenuCategories from './MenuCategories';


import { getDataByBroup } from '../../actions/chain';
import { getChainData } from '../../route/Chain/Chain.selectors.js';

// import {smoothScrollTo} from '../../utils/helpers';

class ChainSections extends Component {

	componentWillMount() {
		this.loadGroupData(this.props);
	}

	componentWillReceiveProps(nextProps){
		this.loadGroupData(nextProps);
	}
	
	// @ToDo: remake
	loadGroupData(props){
 
		const {
			dataInit: {
				chain
			}
			,
			dataGroups,
			match: {
				params: {
					0 : aliasGroupName = null,
					groupName
				}
			}
		} = props;

		if (dataGroups !== null){
			const groupData =  dataGroups.find( (element) => (element.aliasName === groupName) ) || null;

			if (groupData.isLoaded === false && groupData.isLoadProcess === false){
				this.props.getDataByBroup({
					methodName: groupData.methodName,
					chainId: chain.id,
					stateId: chain.stateId,
					dmaRegionId: chain.dmaRegionId,
					aliasGroupName,
				});
			}
		}
	}

	render() {

		const {
			dataInit: {
				tableGroups,
				chain
			},
			match,
			history,
		} = this.props;
 
		const group = tableGroups.find((element) => (element.aliasName === match.params.groupName)) || null;
 
		return (
			<Fragment>
				{group && group.tables.map((table, index) => {

						const tableInfo = {
							...table,
							groupMethodName: group.methodName
						}

						switch(true) {
							
							case (tableInfo.methodName.indexOf('Occasions') === 0): 
							case (tableInfo.methodName.indexOf('SituationAssessment') === 0): 
								const isExtended = tableInfo.methodName.indexOf('SituationAssessment') === 0;
								return (
									<SituationAssessment 
										key={`${tableInfo.methodName}${index}`} 
										table={{...tableInfo, isExtended}} 
										chain={chain} 
										history={history}
										match={match}
									/>
								)
			
							case (tableInfo.methodName.indexOf('BrandFunnel') === 0): 
								return (
									<BrandFunnel 
										key={`${tableInfo.methodName}${index}`} 
										table={tableInfo} 
										chain={chain} 
										history={history}
										match={match}
									/>
								)
					
							case (tableInfo.methodName.indexOf('UnmetNeeds') === 0):
								return (
									<ToggleList 
										key={`${tableInfo.methodName}${index}`} 
										table={tableInfo} 
										chain={chain} 
										history={history}
										match={match}
									/>
								)
							
							case (tableInfo.methodName.indexOf('PerformanceWhereItMatters') === 0):
								return (
									<ChartScatter 
										key={`${tableInfo.methodName}${index}`} 
										table={tableInfo} 
										chain={chain} 
										history={history}
										match={match}
									/>
								)
					
							case (tableInfo.methodName.indexOf('DashboardData') === 0):
								return (
									<DashboardData 
										key={`${tableInfo.methodName}${index}`} 
										table={tableInfo} 
										chain={chain} 
										history={history}
										match={match}
									/>
								)

							case (tableInfo.methodName.indexOf('DashboardLocationData') === 0):
								return (
									<DashboardLocation 
										key={`${tableInfo.methodName}${index}`} 
										table={tableInfo} 
										chain={chain} 
										history={history}
										match={match}
									/>
								)
			
							case (tableInfo.methodName.indexOf('CompetitiveCrossover') === 0):
								return (
									<CompetitiveChart 
										key={`${tableInfo.methodName}${index}`} 
										table={tableInfo}
										chain={chain} 
										history={history}
										match={match}
									/>
								)
											
							case (tableInfo.methodName.indexOf('CompetitiveSubstitution') === 0):
							case (tableInfo.methodName.indexOf('CompetitiveRanking') === 0):
								return (
									<HistogramRanking 
										key={`${tableInfo.methodName}${index}`} 
										table={tableInfo} 
										chain={chain} 
										history={history}
										match={match}
									/>
								)
						
							case (tableInfo.methodName.indexOf('TopOfMindItems') === 0):
								return (
									<WordCloudBox 
										key={`${tableInfo.methodName}${index}`} 
										table={tableInfo} 
										chain={chain} 
										history={history}
										match={match}
									/>
								)
					
							case (tableInfo.methodName.indexOf('StandoutMenuItems') === 0):
								return (
									<MenuItems 
										key={`${tableInfo.methodName}${index}`} 
										table={tableInfo} 
										chain={chain}
										history={history}
										match={match}
									/>
								)
					
							case (tableInfo.methodName.indexOf('MenuCategories') === 0):
								return (
									<MenuCategories 
										key={`${tableInfo.methodName}${index}`} 
										table={tableInfo} 
										chain={chain} 
										history={history}
										match={match}
									/>
								)
						
							default:
								return (
									<TableView 
										key={`${tableInfo.methodName}${index}`} 
										table={tableInfo} 
										chain={chain}
										history={history}
										match={match}
									/>
								);
						}
				})}
			</Fragment>
		);
	}
}


ChainSections.propTypes = {
	dataInit: PropTypes.object
};

const mapStateToProps = (state, props) => ({
	dataGroups: state.chain.dataGroups,
	dataInit: getChainData(state, props)
});

const mapDispatchToProps = dispatch => ({
	getDataByBroup: ( requestQuery ) => dispatch(getDataByBroup( requestQuery )),
});
 
export default connect(mapStateToProps, mapDispatchToProps)(ChainSections);