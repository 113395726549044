import React from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SectionWrapper from '../../../containers/SectionWrapper';
import { getDataTable } from '../../../route/Chain/Chain.selectors.js';


const DashboardData = props => {

	const { 
		table, 
		data, 
		chain,
		match: { 
			params: { 
				0: clusterName,
				// 1: section
			}
		},
	} = props;


	return (
		<SectionWrapper config={{...table, isShowData: (data === null) ? false : true}}>
			{data &&  
				<div className="section-content--container dashboard" clipboard={table.methodName}>
					<span className="hidden-layer">{table.name.toUpperCase()}</span>
					<div className="dashboard-row" >
						<div className="dashboard-column dashboard-column--main">
							<h4 className="dashboard-caption">Demography</h4>
							<div className="dashboard-row">
								<div className="dashboard-column">

									<div className="dashboard-list">
										<div className="dashboard-list-header">
											<div className="header-name">Gender</div>
											<div className="header-caption header-caption--tooltip-holder">
												Indices
												<div className="dashboard-tooltip">Index versus visitors of other chains</div>
											</div>
										</div>
										<table className="table dashboard-table">
											<tbody>
												<tr>
													<td className="cell dashboard-table__name">Male</td>
													<td className="cell dashboard-table__value">{Math.round(+data.gendersIndexes.male)}</td>
												</tr>
												<tr>
													<td className="cell dashboard-table__name">Female</td>
													<td className="cell dashboard-table__value">{Math.round(+data.gendersIndexes.female)}</td>
												</tr>
											</tbody>
										</table>
									</div>

									<div className="dashboard-list">
										<div className="dashboard-list-header">
											<div className="header-name">Generation</div>
										</div>
										<table className="table dashboard-table">
											<tbody>
												{ isNaN( data.generationsIndexes.genZ ) === false &&
													<tr>
														<td className="cell dashboard-table__name">Gen Z</td>
														<td className="cell dashboard-table__value">{Math.round(+data.generationsIndexes.genZ)}</td>
													</tr>
												}
												<tr>
													<td className="cell dashboard-table__name">Millennials</td>
													<td className="cell dashboard-table__value">{Math.round(+data.generationsIndexes.millennials)}</td>
												</tr>
												<tr>
													<td className="cell dashboard-table__name">Gen X</td>
													<td className="cell dashboard-table__value">{Math.round(+data.generationsIndexes.genX)}</td>
												</tr>
												<tr>
													<td className="cell dashboard-table__name">Boomers</td>
													<td className="cell dashboard-table__value">{Math.round(+data.generationsIndexes.boomers)}</td>
												</tr>
												<tr>
													<td className="cell dashboard-table__name">21 year old +</td>
													<td className="cell dashboard-table__value">{Math.round(+data.generationsIndexes.twentyOneYearOldPlus)}</td>
												</tr>
											</tbody>
										</table>
									</div>

									<div className="dashboard-list">
										<div className="dashboard-list-header">
											<div className="header-name">Ethnicity</div>
										</div>
										<table className="table dashboard-table">
											<tbody>
												<tr>
													<td className="cell dashboard-table__name">White</td>
													<td className="cell dashboard-table__value">{Math.round(+data.ethnicitiesIndexes.white)}</td>
												</tr>
												<tr>
													<td className="cell dashboard-table__name">African Amer.</td>
													<td className="cell dashboard-table__value">{Math.round(+data.ethnicitiesIndexes.black)}</td>
												</tr>
												<tr>
													<td className="cell dashboard-table__name">Hispanic</td>
													<td className="cell dashboard-table__value">{Math.round(+data.ethnicitiesIndexes.hispanic)}</td>
												</tr>
												<tr>
													<td className="cell dashboard-table__name">Asian</td>
													<td className="cell dashboard-table__value">{Math.round(+data.ethnicitiesIndexes.asian)}</td>
												</tr>
											</tbody>
										</table>
									</div>

									<div className="dashboard-list">
										<div className="dashboard-list-header">
											<div className="header-name">Income</div>
										</div>
										<table className="table dashboard-table">
											<tbody>
												<tr> 	
													<td className="cell dashboard-table__name">Under $25k</td>
													<td className="cell dashboard-table__value">{Math.round(+data.incomesIndexes.incomeUnder25K)}</td>
												</tr>
												<tr>
													<td className="cell dashboard-table__name">$25k- $49k</td>
													<td className="cell dashboard-table__value">{Math.round(+data.incomesIndexes.income25To50K)}</td>
												</tr>
												<tr>
													<td className="cell dashboard-table__name">$50k - $100k</td>
													<td className="cell dashboard-table__value">{Math.round(+data.incomesIndexes.income50To100K)}</td>
												</tr>
												<tr>
													<td className="cell dashboard-table__name">Over $100k</td>
													<td className="cell dashboard-table__value">{Math.round(+data.incomesIndexes.incomeMoreThan100K)}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>

								<div className="dashboard-column">
									<div className="dashboard-list">
										<div className="dashboard-list-header" label="Percents">
											<div className="header-name">Household</div>
											<div className="header-caption">Percents</div>
										</div>
										<table className="table dashboard-table">
											<tbody>
												<tr>
													<td className="cell dashboard-table__name">Single</td>
													<td className="cell dashboard-table__value">{Math.round(+data.household.single)}%</td>
												</tr>
												<tr>
													<td className="cell dashboard-table__name">Two people</td>
													<td className="cell dashboard-table__value">{Math.round(+data.household.two)}%</td>
												</tr>
												<tr>
													<td className="cell dashboard-table__name">Three people</td>
													<td className="cell dashboard-table__value">{Math.round(+data.household.three)}%</td>
												</tr>
												<tr>
													<td className="cell dashboard-table__name">Four people</td>
													<td className="cell dashboard-table__value">{Math.round(+data.household.four)}%</td>
												</tr>
												<tr>
													<td className="cell dashboard-table__name">Five or more people</td>
													<td className="cell dashboard-table__value">{Math.round(+data.household.fiveOrMore)}%</td>
												</tr>
											</tbody>
										</table>
									</div>
 
									<div className="dashboard-list">
										<div className="dashboard-list-header">
											<div className="header-name">Kids in household</div>
										</div>
										<table className="table dashboard-table">
											<tbody>
												<tr>
													<td className="cell dashboard-table__name">Kids 5 and under</td>
													<td className="cell dashboard-table__value">{Math.round(+data.kidsUnder6)}%</td>
												</tr>
												<tr>
													<td className="cell dashboard-table__name">Kids 6 to 12</td>
													<td className="cell dashboard-table__value">{Math.round(+data.kids6to12)}%</td>
												</tr>
												<tr>
													<td className="cell dashboard-table__name">Kids 13 to 17</td>
													<td className="cell dashboard-table__value">{Math.round(+data.kids13o17)}%</td>
												</tr>
											</tbody>
										</table>
									</div>

									<div className="dashboard-list">
										<div className="dashboard-list-header">
											<div className="header-name">Employment</div>
										</div>
										<table className="table dashboard-table">
											<tbody>
												<tr>
													<td className="cell dashboard-table__name">Work a white collar / office job</td>
													<td className="cell dashboard-table__value">{Math.round(+data.employments.whiteCollar)}%</td>
												</tr>
												<tr>
													<td className="cell dashboard-table__name">Work a blue collar / factory job</td>
													<td className="cell dashboard-table__value">{Math.round(+data.employments.blueCollar)}%</td>
												</tr>
												<tr>
													<td className="cell dashboard-table__name">Do not currently work</td>
													<td className="cell dashboard-table__value">{Math.round(+data.employments.doNotWork)}%</td>
												</tr>
											</tbody>
										</table>	
									</div>
								</div>
							</div>
						</div>
	
						<div className="dashboard-column dashboard-column--secondary">
							<h4 className="dashboard-caption">Food behavior</h4>

							<div className="dashboard-box dashboard-box--first">
								<span className="dashboard-box-value">{Math.round(+data.foodiesPercent)}<span className="dashboard-box-value__percent">% </span></span>
								<span className="dashboard-box-info">
									<span className="dashboard-box__name">Are Foodies </span>
									<span className="dashboard-box__description">(vs. {Math.round(+data.foodiesTotal)}% for the total US population)</span>
								</span>
							</div>

 							<div className="dashboard-box dashboard-box--last">
								<span className="dashboard-box-value">{Math.round(+data.healthWatchers)}<span className="dashboard-box-value__percent">% </span></span>
								<span className="dashboard-box-info">
									<span className="dashboard-box__name">Health-Conscious </span>
									<span className="dashboard-box__description">(vs. {Math.round(+data.healthWatchersTotal)}% for the total US population)</span>
								</span>
							</div>

 							<div className="dashboard-case dashboard-case--first">
								<div className="dashboard-case__caption">{Math.round(+data.mealsEaten.fromHome)}% of their meals are sourced from home</div>
								<div className="dashboard-case__description">(vs. {Math.round(+data.mealsEatenTotal.fromHome)}% for the total US population)</div> 
							</div>	

 							<div className="dashboard-case">
								<div className="dashboard-case__caption">{Math.round(+data.mealsEaten.awayFromHome)}% of their meals are sourced away from home</div>
								<div className="dashboard-case__description">(vs. {Math.round(+data.mealsEatenTotal.awayFromHome)}% for the total US population)</div>
							</div>
						</div>
						
						<div className="dashboard-row__note">NOTE: {(clusterName === 'restaurants') ? 'figures shown are among past month visitors to' : 'figures shown are among regular visitors to'} {chain.name}</div>
					</div>
				</div>}
		</SectionWrapper>
	)
}

DashboardData.propTypes = {
	data: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
	data: getDataTable(state, props),
})

export default connect(mapStateToProps)(DashboardData);