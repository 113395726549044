import React, { Component, Fragment } from "react";
import {connect} from 'react-redux';
// import PropTypes from 'prop-types';
 
import DropdownToggle from "../../components/DropdownToggle";
import Table from "../../components/Table";
 

import {RANKING_TABLE, TABLE_COLUMNS, TOOLTIP_TYPES} from '../../utils/constants';
import {updateHeaderTable} from '../../actions/rankings';
// import {getDataTable} from '../../route/Rankings/Rankings.selectors';

import Notification from '../../utils/notification';
import {dbTableColumnInfo} from '../../utils/presets';

 

class RankingCurrent extends Component {

	constructor(props){
		super(props);

		this.onToggleColumns = this.onToggleColumns.bind(this);
		this.onSortColumn = this.onSortColumn.bind(this);

		this.notification = new Notification();
	}

	onToggleColumns(itemColumn = null){
		const { dataTable: {columnsGroups} } = this.props;
 
		const columnsGroupsMaped = columnsGroups.map( group => {
			const columns = group.columns.map( column => {

				if (column.isAllowToggleVisible === true && itemColumn === null)
					return {
						...column,
						isVisible: false
					}

				if (column.isAllowToggleVisible === true && column.id === itemColumn.id)
					return {
						...column,
						isVisible: itemColumn.isSelected
					}

				return column;
			})
			return {
				...group,
				columns
			};
		});

		const countVisibleColumn = columnsGroupsMaped.reduce((sum, group) => {
			return sum + group.columns.filter( element => (element.isVisible === true && element.isAllowToggleVisible === true) ).length;
		}, 0 );
 

		if (countVisibleColumn > RANKING_TABLE.MAX_COUNT_COULUMNS_VISIBLE){

			this.notification.add(`Table displays ${RANKING_TABLE.MAX_COUNT_COULUMNS_VISIBLE} columns max.`);

		} else {

			const {
				group: {
					methodName: groupMethodName
				},
				table: {
					methodName: tableMethodName
				}
			} = this.props;
			
			this.props.updateHeaderTable({ groupMethodName, tableMethodName, columnsGroups:columnsGroupsMaped });			
		}
	}

	onSortColumn(column){

		const { columnId, sortOrder } = column;
		const { dataTable: {columnsGroups} } = this.props;

		const columnsGroupsMaped = columnsGroups.map( group => {
			const columns = group.columns.map( column => {
				return {
					...column,
					orderBy: (column.id === columnId) ? sortOrder : null
				}
			})
			return {
				...group,
				columns
			};
		});

		const {
		    group: {
		        methodName: groupMethodName
		    },
		    table: {
		        methodName: tableMethodName
		    }
		} = this.props;

		this.props.updateHeaderTable({ groupMethodName, tableMethodName, columnsGroups:columnsGroupsMaped });
	}
 
	render() {

		const { 
			table,
			chainList, 
			dataTable,
			match: { 
				params: { 
					0: clusterName,
					aliasName: groupName
				}
			}
		} = this.props;



		const countColumnTable = dataTable.columnsGroups.reduce((sum, group) => ( sum + group.columns.length ), 0 );		
		const isFloatingSampleSize = dataTable.rows.some( row => Array.isArray(row.bases) );

		return (
			<Fragment>

				{countColumnTable > RANKING_TABLE.MAX_COUNT_COULUMNS_VISIBLE_TOTAL && <DropdownToggle
					className="dropdown-toggle--side dropdown-toggle--multiselect"
					isMultiselect={true}
					options={ dataTable.columnsGroups.map( (columnGroup) => {

						const items = columnGroup.columns
							.filter( column => column.isAllowToggleVisible === true )
							.map( column => {
								return{
									id: column.id,
									name: column.label,
									isSelected: column.isVisible
								}
							});

						return {
							name: columnGroup.label,
							items: items
						}

					}).filter( group => group.items.length > 0 )   }
					onClickItem={(item) => this.onToggleColumns(item)}
					onClear={() => this.onToggleColumns()} /> }

					<div clipboard={table.methodName}>
						<span className="hidden-layer">{table.name.toUpperCase()}</span>
						<Table
							className={`table-ranking ${ (countColumnTable < 6) ? "table-ranking--small" : "" }`}
							columnsGroups={dataTable.columnsGroups.map( (group, indexGroup) => {

									const isPresentGroup =  dataTable.columnsGroups.some( group => group.label.length > 0 );

									const classNameGroup  = (indexGroup === 0) ? "cell--name-chain" :
										(isPresentGroup) ? (indexGroup % 2) ? "cell--theme-b" : "" : "" 

										const columns = group.columns
											.filter( column => column.isVisible === true )
											.map((column) => {

												const columnInfo = dbTableColumnInfo.get(column.name);

												let classNameCell = classNameGroup;
												switch(column.name) {
													case TABLE_COLUMNS.BASE:
														classNameCell = columnInfo.className;
														break;
													case TABLE_COLUMNS.EXCELLENT_VERY_GOOD:
														classNameCell = columnInfo.className;
														break;
													case TABLE_COLUMNS.NPS:
														classNameCell = columnInfo.className;
														break;
													default:
														classNameCell = classNameGroup;
												}
 
 
												return {
													id: column.id,
													label: `${column.label}${(column.name === TABLE_COLUMNS.BASE && isFloatingSampleSize === true) ? `*` :  ``}`,
													type: columnInfo.dataType,
													className: classNameCell,
													isSorting: true,
													//isMixSort: columnInfo.dataType === TABLE_TYPES_COLUMN.CHAIN_LINK,
													orderBy: column.orderBy,
													isColumn: true,
												}
											});

									if (isPresentGroup === true && group.columns.length === 1){
										const [column] = columns;
										return {
											...column,
											isColumn: true,
											columns:[]
										}	
									}

									return {
										className: classNameGroup,
										label: group.label,
										columns: columns,
										isColumn: false
									}

								}).filter( group => group.isColumn === true || group.columns.length > 0 )}

							rowsGroups={[{
								group: null,
								rows: chainList
									.filter(chain => chain.isDisabled === false)
									.map( (chain, chainIndex) => {
						 
										const row = dataTable.rows.find(row => row.chainId === chain.id && row.stateId === chain.stateId && row.dmaRegionId === chain.dmaRegionId ) || null;

										if (row === null)
											return null

											const cellValues = [{
												value:chain.name, 
												chainId:chain.id, 
												stateId: chain.stateId, 
												dmaRegionId: chain.dmaRegionId, 
												location: {clusterName, groupName}  }, 
												...row.values.map( (value, index) => {
													return {
														...{value},
														...((Array.isArray(row.bases) && row.bases[index]) ? {
															tooltip: {
																type: TOOLTIP_TYPES.BASE_SIZE,
																value: row.bases[index]
															}
														} : {}),
													};
											})];


										const { visibleCellValues } = dataTable.columnsGroups.reduce( (reducer, group) => { 

												group.columns.forEach( column => {
													if (column.isVisible === true)
														reducer.visibleCellValues.push(reducer.cellValues[reducer.index]);
													reducer.index++;
												})
												
												return reducer 

											}, {visibleCellValues:[], cellValues: cellValues, index: 0} );

										return visibleCellValues;

							 
									}).filter( row => row ),
							}]}
							onSortColumn={this.onSortColumn}
						/>
					</div>

				{isFloatingSampleSize === true && (
					<div className="section-content__message">
						*Sample sizes vary by metric, the BASE shown represents the largest base size across all metrics, you can also hover over a figure to view its base size.
					</div>
				)}

			</Fragment>
		);
	}
}

const mapStateToProps = (state, props) => ({

});

const mapDispatchToProps = dispatch => ({
	updateHeaderTable: (data) => dispatch(updateHeaderTable(data)),
});

 
export default connect(mapStateToProps, mapDispatchToProps)(RankingCurrent);