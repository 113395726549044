import { FC } from "react";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import { ROOT_URL } from "../../utils/constants";

const NotFound: FC = () => {
    return (
        <div className="container not-found">
            <div className="not-found__box">
                <div className="text-center not-found__caption">page not found</div>
                <div className="text-center not-found__name">404</div>
                <div className="cluster cluster--active">
                    <Link className="cluster-box" to={{ pathname: ROOT_URL }}>
                        <span className="cluster-box__caption">Home</span>
                    </Link>
                    <div className="liquid__holder">
                        <div className="liquid"/>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default NotFound;