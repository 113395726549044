import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';


import SectionWrapper from '../../../containers/SectionWrapper';
import {getDataTable, hasShowSection} from '../../../route/Chain/Chain.selectors';
// import {getChainsByCluster} from '../../../selectors/section.selectors';

import Scrollbar from 'smooth-scrollbar';
import {
	// defaultSortMethod, 
	capitalizeFirstLetter
} from "../../../utils/helpers";

class HistogramRanking extends Component {

	constructor(props){
		super(props);

		this.instanceScrollbar = null;
	}

	componentDidMount() {
		if (this.props.data && this.props.data.rows.length > 15 )
			this.buildScrollBar();
	}
 
	componentDidUpdate(){
		if (this.props.data && this.props.data.rows.length > 15 )
			this.buildScrollBar();
	}	

	componentWillUnmount() {
		this.destroyScrollBar(); 
	}

	buildScrollBar = () => {
		this.destroyScrollBar();
		
		if (!this.instanceScrollbar && this.nodeScrollBar){
			this.nodeScrollBar.classList.add('histogram-ranking--scroll');
			this.instanceScrollbar = Scrollbar.init(this.nodeScrollBar,{
				continuousScrolling: false
			});			
		}
	}

	destroyScrollBar = () => {
		if (this.instanceScrollbar){
			this.nodeScrollBar.classList.remove('histogram-ranking--scroll');
			this.instanceScrollbar.destroy();
			this.instanceScrollbar = null;
		}
	}

	getDataChains(chains, data){

		const dataList = data.rows.map( (item, index) => {

			const chain = chains.find(chain => chain.id === item.chainId) || null,
				[value] = item.values;
 
			return {
				id: item.chainId,
				name: (chain) ? chain.name : 'N/A',
				value: (value) ? +value : 0
			}

		});
		// .sort((a, b) => {

		// 	a = a.value;
		// 	b = b.value;

		// 	return defaultSortMethod(a,b);

		// }).reverse();

		const maxValue = Math.max.apply(null, dataList.map( element => element.value));

		return dataList.map( element => ({
			...element,
			percent: Math.round( (element.value * 100) / maxValue )  
		}));
	}

	render() {
		const { 
			table, 
			data, 
			// clusterChains:{
			// 	chains
			// }, 
			chainsList,
			chain:selectedChain,
			hasDisabledSection
		} = this.props;

		return (
			<Fragment>
				{hasDisabledSection === false ? (
					<SectionWrapper config={{
						...table,
						description: (table.description) ? capitalizeFirstLetter(table.description).replace('[chain]', selectedChain.name) : null,
						question: (table.question) ? capitalizeFirstLetter(table.question).replace('[chain]', selectedChain.name) : null,
						isShowData: (data === null) ? false : true,
		 				copyData: (data === null) ? null : this.getDataChains(chainsList, data).reduce( (list, item) => { 

							list.push([item.name, `${Math.round(item.value)}%`]);
							return list;
						}, [[table.name.toUpperCase()]]),

					}}>
						<div className="section-content--container" clipboard={table.methodName}>
							{data && <div className="histogram-ranking" ref={node => this.nodeScrollBar = node} >
								{this.getDataChains(chainsList, data).map( (chain, index) => {
									return (
										<div className="gauge-linear histogram-ranking__gauge-linear" key={index}>
											<div className="gauge-linear-label histogram-ranking__label"> {(selectedChain.id === chain.id) ? (
													<b>{chain.name}</b>
												) : chain.name } </div>
											<div className="gauge-linear-meter">
												<div className="gauge-linear-progress" style={{width: `${chain.percent}%`}}></div>
											</div>
											<div className="gauge-linear-value">{(chain.value < 1) ? '<1' : Math.round(chain.value)}%</div>
										</div>
									)
								})}

							</div>}
						</div>
					</SectionWrapper> ) : <Fragment></Fragment>}
			</Fragment>
		);
	}
}
 
HistogramRanking.propTypes = {
	chain: PropTypes.object,
	data: PropTypes.object,
	dataInit: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
	data: getDataTable(state, props),
	hasDisabledSection: hasShowSection(state, props),
	///clusterChains: getChainsByCluster(state, props),
	chainsList: [
		...state.init.sections.cstores.chains,
		...state.init.sections.restaurants.chains,
		...state.init.sections.supermarkets.chains,
	]
})
 
export default connect(mapStateToProps)(HistogramRanking);