import React, { Component, Fragment } from "react";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
 
import Table from "../../../components/Table";
import Preloader from '../../../components/Preloader';

import {toggleCompetetivePopup} from '../../../actions/chain';
import {getDataTable, getIsHistoryTable, hasShowSection} from '../../../route/Chain/Chain.selectors.js';

import ClipboardButtons from '../../../components/ClipboardButtons';
 
import PopUpChart from './TableView.PopUpChart';
import {capitalizeFirstLetter, transformChainTableData} from "../../../utils/helpers";

import {
	SECTIONS,
	TABLE_TYPES_COLUMN, 
	// TABLE_COLUMNS,
} from '../../../utils/constants';


class TableView extends Component {

	constructor(props){
		super(props);
		this.state = {
			isOpenChartPopup: false
		}
		this.openChartPopup = this.openChartPopup.bind(this);
	} 

	openChartPopup(){
		this.setState((prevState, props) => {
		  return {isOpenChartPopup: !prevState.isOpenChartPopup};
		});		
	}


	render() {
		const { 
			table, 
			data,
			chain,
			isHistoryTable,
			filterCompareTo,

			hasDisabledSection,

			currentPeriodLabel,
			previousPeriodLabel,
			supermarketCStoreCurrentPeriodName,
			supermarketCStorePreviousPeriodName,

			match:{
				params:{
					0: clusterName,
				}
			},

		} = this.props;


		const dataTable = (data === null) ? null : transformChainTableData(data);
		const isFloatingSampleSize = (data === null) ? false : data.rows.some( row => Array.isArray(row.bases) === true);


		const [firstSection] = SECTIONS;
		const period = {
			current: (firstSection.aliasName === clusterName) ? currentPeriodLabel : supermarketCStoreCurrentPeriodName,
			previous: (firstSection.aliasName === clusterName) ? previousPeriodLabel : supermarketCStorePreviousPeriodName,
		}  

		const fields = {
			compareFrom: (isHistoryTable === true) ? period.current : chain.name,
			compareTo: (isHistoryTable === true) ? period.previous : ((filterCompareTo.payload) ? filterCompareTo.payload.name : 'N/A')
		}

		if (hasDisabledSection === true)
			return (
				<Fragment></Fragment>
			)

		const chartData = (data !== null) ? data.columnsGroups.reduce( (collection, group) => { 

				group.columns.forEach( column => {

					const [chain, compareTo] = data.rows[collection.iteration].values;
			 		collection.iteration = ++collection.iteration;


						if (column.toLocaleLowerCase() !== 'base' && chain && compareTo){
							collection.chain.data.push({
								name: column,
								y: +chain
							})

							collection.compareTo.data.push({
								name: column,
								y: +compareTo
							})
						}
					
				});

				return collection;

			}, {
				chain: {
					name: fields.compareFrom,
					data: []
				},
				compareTo: {
					name: fields.compareTo,
					data: []
				},

				iteration: 0
			}) : null;

		const countChartDataList = (chartData) ? chartData.compareTo.data.filter(item => (item.y > 0)).length : 0;

		return (
			<section className="section" id={table.aliasName} snapshot={table.methodName}>
				
				<div className="section-header">
					<div className="section-header-meta">
						<div className="section-header-meta__caption">{table.name}</div>
						{table.description && <div className="section-header-meta__description">{capitalizeFirstLetter(table.description)}</div>}
					</div>	
					<div className="section-header-controls snapshot-disable">
						{data && 
							<div className="section-header-controls__buttons">
								<div className="call-modal">
									<button className="button call-modal__button" onClick={event => {
											event.preventDefault();
											event.stopPropagation();

											this.props.toggleCompetetivePopup({
												groupMethodName: table.groupMethodName,
												tableMethodName: table.methodName,
												hasHistory: table.hasHistory,
												isHistory: isHistoryTable
											});
										}}> 

										<svg className="icon icon-way" dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-way" />' }} />
									</button>
								</div>
								{(window.generalConfig.access.trial === false) && (
									<ClipboardButtons 
										fileName={table.name}
										nodeNameAttribute={table.methodName}
									/>
								)}
							</div>
						}
						
					</div>
					<div className="section-header-copyright snapshot-only">Datassential</div>	
				</div>

				<div className="section-content">

					<div clipboard={table.methodName}>
						<span className="hidden-layer">{table.name.toUpperCase()}</span>
					 
						{data === null ? <Preloader type="table"/> : <Table
							className="table-chain"
							isScrollBar={false}
							columnsGroups={[{
								label: "", 
								columns: [{
									id: 1,
									label: " ", 
									type: TABLE_TYPES_COLUMN.STRING_INLINE,
									isSorting: (table.withoutSorting === true) ? false : true,
									className: "cell--metric" 
								},{
									id: 2,
									label: fields.compareFrom,
									type: TABLE_TYPES_COLUMN.NUMBER_PERCENT,
									isSorting: (table.withoutSorting === true) ? false : true,
									className: "cell--chain"
								},{
									id: 3,
									label: fields.compareTo,
									type: TABLE_TYPES_COLUMN.NUMBER_PERCENT,
									isSorting: (table.withoutSorting === true) ? false : true,
									className: "cell--compare-to"
								},{
									id: 4,
									label: "DIFFERENCE",
									type: TABLE_TYPES_COLUMN.NUMBER_PERCENT_DIFFERENCE, 
									isSorting: (table.withoutSorting === true) ? false : true,
									className: "cell--difference"
								}]
							}]}
							rowsGroups={dataTable.rowsGroups}
							rowsFooter={dataTable.footerRows.map( row => {

								if (isFloatingSampleSize)
									return row.map( (cell) => {
										if (cell.value === `Base`)
											cell.value = `${cell.value}*`;
										return cell
									})

								return row
							})}
						/>}

					</div>


					{data !== null && isFloatingSampleSize === true && (
						<div className="section-content__message">
							*Sample sizes vary by metric, the BASE shown represents the largest base size across all metrics, you can also hover over a figure to view its base size.
						</div>
					)}
				</div>

				<div className="section-footer">
					<div className="section-footer-enter">
						{table.question && (
							<div className="section-footer-question" title={table.question}>
								<span className="section-footer-question__char">Q:</span> {table.question}
							</div>
						)}

						{(countChartDataList > 1) && (
							<div className="section-footer-button-chart clipboard-disable snapshot-disable">
								<button 
									className="button button-primary" 
									onClick={ (event) => {
											event.preventDefault();
											this.openChartPopup()
									}}>
										Create chart
								</button>
							</div>
						)}
					</div>
				</div>

				{(this.state.isOpenChartPopup === true) && ( 
					<PopUpChart 
						title={table.name} 
						data={ ((data) => {
							return data.columnsGroups.reduce( (collection, group) => { 

								group.columns.forEach( column => {

									const [chain, compareTo] = data.rows[collection.iteration].values;
							 		collection.iteration = ++collection.iteration;


										if (column.toLocaleLowerCase() !== 'base' && chain && compareTo){
											collection.chain.data.push({
												name: column,
												y: +chain
											})

											collection.compareTo.data.push({
												name: column,
												y: +compareTo
											})
										}
									
								});

								return collection;

							}, {
								chain: {
									name: fields.compareFrom,
									data: []
								},
								compareTo: {
									name: fields.compareTo,
									data: []
								},

								iteration: 0
							});

						} )(data) }
						onClose={this.openChartPopup} 
					/>
				)}
			</section>
		);
	}
}

 
TableView.propTypes = {
	data: PropTypes.object
};

const mapStateToProps = (state, props) => ({
	data: getDataTable(state, props),
	isHistoryTable: getIsHistoryTable(state, props),

	currentPeriodLabel: state.init.currentPeriodName,
	previousPeriodLabel: state.init.previousPeriodName,

	supermarketCStoreCurrentPeriodName: state.init.supermarketCStoreCurrentPeriodName,
	supermarketCStorePreviousPeriodName: state.init.supermarketCStorePreviousPeriodName,

	hasDisabledSection: hasShowSection(state, props),

	filterCompareTo: state.chain.filterCompareTo,
})

const mapDispatchToProps = dispatch => ({
	toggleCompetetivePopup: (data) => dispatch(toggleCompetetivePopup(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(TableView);