import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Scrollbar from 'smooth-scrollbar';

class DropdownToggle extends Component{
	constructor (props) {
		super(props);

		this.state = {
			isOpen: false
		}
 
		this.instanceScrollbar = null;

		this.onWindowClick  = this.onWindowClick.bind(this);
		this.buildMenu      = this.buildMenu.bind(this);
		this.onClickItem    = this.onClickItem.bind(this);
		this.onClickToggleDropdown = this.onClickToggleDropdown.bind(this);

	}
 
	componentDidMount() {
		window.addEventListener('click', this.onWindowClick);
		window.addEventListener('touchstart', this.onWindowClick);
		// this.buildScrollBar();
	}

	componentWillUnmount() {
		window.removeEventListener('click', this.onWindowClick);
		window.removeEventListener('touchstart', this.onWindowClick);
		this.destroyScrollBar();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.isOpen === false && this.state.isOpen === true)
			this.buildScrollBar();
	}

	onWindowClick(event){
		if (event.target !== this.nodeDropdown && !this.nodeDropdown.contains(event.target)) {
			this.setState({
				isOpen: false
			})
		}
	}

	buildScrollBar = () => {
		if (this.nodeScrollBar)
			this.instanceScrollbar = Scrollbar.init(this.nodeScrollBar,{
				continuousScrolling: false
			});
	}

	destroyScrollBar = () => {
		if (this.instanceScrollbar)
			this.instanceScrollbar.destroy();
			this.instanceScrollbar = null;
	}

	onClickToggleDropdown(event) {
		event.preventDefault();

		this.setState(prevState => ({
			isOpen: !prevState.isOpen
		}));
	}

	onClickItem(event, option){
		event.preventDefault();

		if (this.props.onClickItem) 
			this.props.onClickItem({
				...option,
				isSelected: !option.isSelected
			})

		if (this.props.isMultiselect === false)
			this.setState({
				isOpen: false
			})

	}
	
	buildOption(option, index){
		return (
			<div key={`${index}${option.id}`} className={"dropdown-toggle-group__item" + (option.isSelected === true ? " is-selected" : "")} onClick={(event) => this.onClickItem(event, option)}>
				<span className="icon">
					<svg className="icon-apply" dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-apply" />' }} />
				</span>
				{option.name}
			</div>
		)
	}

	buildMenu() {
		const {options, isMultiselect} = this.props;

		const { total: totalItems, selected: selectedItems } = options.reduce((calc, option) => { 		  
			return {
				selected: calc.selected +  option.items.filter( element => element.isSelected === true ).length,
				total: calc.total + option.items.length
			}
		}, {selected: 0, total: 0} );

		const label = options.reduce((label, option) => {
			const selectedItem = option.items.find(item => item.isSelected === true) || null;
			return (selectedItem) ? selectedItem.name : label;
		}, 'Options');

		const optionList = options.map((option, index) => {
			if (option.hasOwnProperty('items') === true) {
 
				const _options = option.items.map((item, itemIndex) => this.buildOption(item, itemIndex))

				return (
					<div className="dropdown-toggle-group" key={option.name}>
						{(option.name.length ? <div className="dropdown-toggle-group__caption">{option.name}</div> : "")}
						{_options}
					</div>
				)

			} else {
				return this.buildOption(option, index);
			}
		})

		return (
			<div className="dropdown-toggle-menu">
				<div className="dropdown-toggle-header">
					 
					{isMultiselect === true ? (
						<Fragment>
							<span className="dropdown-toggle-header__display">{selectedItems} of {totalItems} selected</span>
							<button className="button button-link dropdown-toggle-header__button-clear" onClick={(event) => {
								event.preventDefault();
								this.props.onClear();
							}}>clear all</button>
						</Fragment>
						) : (<span className="dropdown-toggle-header__display">{label}</span>)}
				</div>

				{(optionList.length ? 
					(<div className="dropdown-toggle-list" ref={ node => this.nodeScrollBar = node }>{optionList}</div>) : 
					(<div className="dropdown-toggle-menu__message">No options found</div>))}
			</div>
		)
	}

	render() {
		const classList = [
			...['dropdown-toggle', this.props.className],
			...[(this.state.isOpen === true) ? 'is-open' : ''],
		].filter( element => element ).join(' ');

		return(
			<div ref={node => this.nodeDropdown = node}  className={classList}>
				<button className="button dropdown-toggle-button" onClick={ (event) => this.onClickToggleDropdown(event) } clipboard-disable="true">
					<svg className="dropdown-toggle-button__icon" dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-checkboxes" />' }} />
					<span className="dropdown-toggle-button__caption">Select</span>
				</button>
				{this.state.isOpen === true && this.buildMenu()} 
			</div>
		)
	} 
} 

DropdownToggle.propTypes = {
	className: PropTypes.string,
	options: PropTypes.array,
	isMultiselect: PropTypes.bool,

	onClickItem: PropTypes.func,
	onClear: PropTypes.func,
};

DropdownToggle.defaultProps = {
	className: "",
	options: [],
	onClickItem: () => {},
	onClear: () => {},
};


export default DropdownToggle;