import React, { Component, Fragment } from "react";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Scrollbar from 'smooth-scrollbar';

import {defaultSortMethod} from "../../utils/helpers";

import ChainPopupGroupView from './ChainPopup.Group.View';
import ChainPopupGroupEditor from './ChainPopup.Group.Editor';
 
import {
	getPresetsByCluster, 
} from '../../selectors/section.selectors';

import {
	PRESETS_GROUP_ACTIONS, 
	CHAIN_COMPARE_TYPES, 
	// CUSTOM_PRESET_TYPE_ID,
	PRESET_TYPE_LIST,
	// SECTIONS
} from '../../utils/constants';


import {doSavePreset, doRemovePreset} from '../../actions/preset';


	export const isMixLSRFSR = (presetTypeList) => {
		const groups = presetTypeList.map(item => item.segmentGroup);
		return (groups.includes(PRESET_TYPE_LIST.LSR.segmentGroup) && groups.includes(PRESET_TYPE_LIST.FSR.segmentGroup));
	}


	const getCustomPresets = (presetTypeList, presets) => {

		const groups = presetTypeList.map(item => item.segmentGroup);
		const isMixedLSRFSR = (groups.includes(PRESET_TYPE_LIST.LSR.segmentGroup) && groups.includes(PRESET_TYPE_LIST.FSR.segmentGroup));

		return presetTypeList
			.filter(item => item.isCustom === false)
			.reduce((resultList, item) => {
				
				const presetList = (presets[item.section] || [])

					.filter(preset => {

						if (isMixedLSRFSR === true && preset.isCustom === true && preset.segmentGroup === PRESET_TYPE_LIST.MIX_LSR_FSR.segmentGroup)
							return true;

						return (preset.segmentGroup === item.segmentGroup && preset.isCustom === true);
					});

					presetList.forEach(preset => {
						const isPresent = resultList.some(item => item.id === preset.id);
						if (isPresent === false)
							resultList.push(preset);
					})

				return resultList;

			}, []);
	}

	const getTypePresets = (presetType, presets) => {

		return (presets[presetType.section] || [])
			.filter(preset => {

				if (preset.isCustom === false && preset.segmentGroup === PRESET_TYPE_LIST.MIX_LSR_FSR.segmentGroup)
					return true;


				return (preset.segmentGroup === presetType.segmentGroup && preset.isCustom === false);
			});
	}


	const isEnabledType = (presets = []) => {
		
		return presets.map(preset => {
			return preset.chains.some( chain => chain && chain.isDisabledInPreset === false );
		}).some( isEnabled => isEnabled === true );
	}
  

	const mapPresetTypeList = (presetTypeList = [], presets = []) => {

		return presetTypeList.map(presetType => {

			const presetList = (presetType.isCustom === true) ? getCustomPresets(presetTypeList, presets) : getTypePresets(presetType, presets);

			return {
				...presetType,
				presets: presetList.sort((a, b) => {
					return defaultSortMethod(a.name, b.name);
				}),

				isEnabled: isEnabledType(presetList),
			};

		});
	}



class ChainPopupGroup extends Component {

	constructor(props){
		super(props);

		this.state = {
			presetTypeId: null,
			preset: null,
			actionTask: null
		};

		this.tooltipClientRect = null;

		this.actionManager  = this.actionManager.bind(this);
		this.setTypeSegment = this.setTypeSegment.bind(this);
		this.onGroupView = this.onGroupView.bind(this);

		this.instanceScrollbar = null;
	}

	componentDidMount() {
		this.instanceScrollbar = Scrollbar.init(this.nodeScrollBar,{
			continuousScrolling: false
		});
	}


	componentDidUpdate(prevProps, prevState) {
		if (this.state.presetTypeId !== prevState.presetTypeId)
			this.instanceScrollbar.scrollTo(0,0);
	}

	componentWillUnmount() {
		this.instanceScrollbar.destroy();
		this.instanceScrollbar = null;
	}


	componentWillMount(){
		
		const { 
			clusterPresets: {
				cluster:{
					chainComparePresetTypeList,
					chainReportPresetTypeList,
					defaultPresetTypeId,
				},
			},
			presets,
			popup: {
				callingTable,
			},
			filterCompareTo: { payload: filterCompareToPayload },
			selectedId,
		} = this.props;


		const presetTypeListByCall = ((callingTable === null)  ? chainReportPresetTypeList : chainComparePresetTypeList);

		const presetTypeList = mapPresetTypeList(presetTypeListByCall, presets);
		const highlightedSetId = selectedId || filterCompareToPayload.id;
		const defaultPresetPresetType = presetTypeList.find(
			presetType => presetType.presets.find(
				preset => preset.id === highlightedSetId
			)
		);

		this.setTypeSegment(defaultPresetPresetType ? defaultPresetPresetType.id : defaultPresetTypeId);
	}

	setTypeSegment(id){
		this.setState(() => ({
			presetTypeId: id
		}));
	}

	onGroupView(event, preset){
		event.preventDefault();

		this.tooltipClientRect = event.target.getBoundingClientRect();
		this.actionManager({
			type: PRESETS_GROUP_ACTIONS.VIEW,
			payload: preset
		}); 
	}

	actionManager(action){

		// const {clusterPresets} = this.props;

		switch(action.type) {

			case PRESETS_GROUP_ACTIONS.VIEW:
				this.setState((prevState) => ({
					preset: action.payload,
					actionTask: PRESETS_GROUP_ACTIONS.VIEW
				}));
			break;

			case PRESETS_GROUP_ACTIONS.EDIT:
				this.setState((prevState) => ({
					preset: action.payload,
					actionTask: PRESETS_GROUP_ACTIONS.EDIT
				}));
			break;

			case PRESETS_GROUP_ACTIONS.CANCEL:
				this.setState((prevState) => ({
					preset: null,
					actionTask: null
				}));
			break;

			case PRESETS_GROUP_ACTIONS.REMOVE:

				this.setState((prevState) => ({
					preset: null,
					actionTask: null
				}));

				this.props.doRemovePreset({
					section: action.section,
					preset: action.preset
				})

			break;

			case PRESETS_GROUP_ACTIONS.CREATE:
				this.setState((prevState) => ({
					preset: action.payload,
					actionTask: PRESETS_GROUP_ACTIONS.CREATE
				}));
			break;

			case PRESETS_GROUP_ACTIONS.SELECT:
				this.props.onSelect({
					type: CHAIN_COMPARE_TYPES.GROUP,
					payload: action.payload
				});
			break; 

			case PRESETS_GROUP_ACTIONS.SAVE:

				this.setState((prevState) => ({
					presetTypeId: PRESET_TYPE_LIST.CUSTOM.id,
					preset: null,
					actionTask: null
				}));

				this.props.doSavePreset({
					section: action.section,
					preset: action.preset
				})
			 
			break; 
			default:
			break; 
		}
	}


	render() {

		const { 
			presetTypeId, 
			preset: selectedPreset, 
			actionTask,
		} = this.state; 

		const { 
			history,
			match,

			clusterPresets: {
				cluster:{
					chainComparePresetTypeList = [],
					chainReportPresetTypeList = [],
					chainXlsReportPresetTypeList = [],
					hasChainReportTabs,
				},
			},
			filterCompareTo:{
				payload:filterCompareData, 
				type:filterCompareType
			},
			popup: {
				callingTable
			},

			sections,
			presets,

			isLoading,
			selectedId,
			chain,
			isXls = false,
		} = this.props;

		const { defaultPresetId } = chain.header.data;
		const reportPresetTypeList = isXls && chainXlsReportPresetTypeList.length > 0 ? chainXlsReportPresetTypeList : chainReportPresetTypeList;
		const presetTypeListByCall = ((callingTable === null)  ? reportPresetTypeList : chainComparePresetTypeList);

		const presetTypeList = mapPresetTypeList(presetTypeListByCall, presets);

		const presetType = presetTypeList.find(presetType => presetType.id === presetTypeId);

		const highlightedSetId = selectedId || filterCompareData.id;

		const sortedPresets = [...presetType.presets];
		sortedPresets.sort((a, b) => {
			if (!!highlightedSetId) {
				if (b.id === highlightedSetId) return 1;
				if (b.id === defaultPresetId && a.id !== highlightedSetId) return 1;
			}
			else {
				if (b.id === defaultPresetId) return 1;
			}
			return -1;
		})

		return (
			<Fragment>
				{(isLoading === true) && (
					<div className="preset-group-loader">
						<div className="preset-group-loader__circle" />
					</div>
				)}
				<div className="preset-group-container">
					<ul className="swich-group">
						{presetTypeList.map( (presetType, index) => {

							const classList = [
								...['swich-group-item'],
								...[(presetTypeId === presetType.id) ? 'is-active' : ''],
								...[(presetType.isEnabled === false) ? 'is-disabled' : ''],
							].filter( element => element ).join(' ');

							return (
								<li 
									className={classList} 
									onClick={event => this.setTypeSegment(presetType.id)} 
									key={index}>
										{presetType.name}
								</li>
							)

						})}
					</ul>

					<div className="preset-group-holder" ref={node => this.nodeScrollBar = node}
						onMouseLeave={(event) => {
							this.actionManager({
								type: PRESETS_GROUP_ACTIONS.CANCEL
							});						
						}}>

						<ul className="preset-group">
							{sortedPresets
								.map( (preset, index) => {

								const isEnabled = preset.chains.some( chain => chain && chain.isDisabledInPreset === false ) === true;

								const classList = [
									...['preset-group-item'],
									...[(filterCompareType === CHAIN_COMPARE_TYPES.GROUP && filterCompareData.id === preset.id && callingTable !== null) ? 'is-selected' : ''],
									...[(isEnabled === false) ? 'is-disabled' : ''],
									...[(preset.id === highlightedSetId) ? 'is-active' : ''],
								].filter( element => element ).join(' ');

								return (
									<li className="preset-group-cell" key={`${presetTypeId}${index}`} 
										onMouseEnter={event => this.onGroupView(event, preset)}>
										<div 
											className={classList}
											onClick={ (event) => {
												event.preventDefault();

												if (!(filterCompareType === CHAIN_COMPARE_TYPES.GROUP && filterCompareData.id === preset.id && callingTable !== null))
													this.actionManager({
														type: PRESETS_GROUP_ACTIONS.SELECT,
														payload: preset
													})
											}}>
											{preset.name}<br/>{preset.id === defaultPresetId && '(default)'}
										</div>
									</li>
								)
							})}
						</ul>

						{actionTask === PRESETS_GROUP_ACTIONS.VIEW && (
							<ChainPopupGroupView
								history={history}
								match={match}
								preset={selectedPreset}
								presetTypeList={presetTypeList.map(presetType => {

									const  {
										chains = [],
									} = sections[presetType.section] || {};

									return {
										...presetType,
										chains
									}

								})}
								callback={this.actionManager}
								targetClientRect={this.tooltipClientRect} 	
							/>				
						)}
					</div>
					<div className="modal-body-footer">
					 	{/* ${(hasMoreOneEnabledChains === true) ? `` : `is-disabled`} */}
						<button 
							type="button" 
							className={`button modal-footer__btn-create`}

							onClick={ (event) => {
								event.preventDefault();

								this.actionManager({
									type: PRESETS_GROUP_ACTIONS.CREATE,
									payload: null
								})
							}}
						>
							+ Create your own custom group
						</button>
					</div>
				</div>
				 
				{(actionTask === PRESETS_GROUP_ACTIONS.EDIT || actionTask ===  PRESETS_GROUP_ACTIONS.CREATE) && (
					<ChainPopupGroupEditor 
						history={history}
						match={match}

						presetTypeId={(actionTask === PRESETS_GROUP_ACTIONS.CREATE && presetType.isCustom === false) ? presetTypeId : null}
						presetTypeList={presetTypeList.map(item => {

							const  {
								chains = [],
							} = sections[item.section] || {};

							const isSegmentGroups = chains.some( chain => chain.segmentGroupId !== null);

							const chainList = (isSegmentGroups === true && isMixLSRFSR(presetTypeList) === false) ? chains.filter(chain => chain.segmentGroupId === item.segmentGroup) : chains;

							return {
								...item,
								chains: chainList
							}

						})}
						preset={selectedPreset} 
						callback={this.actionManager}
						hasCreate={actionTask ===  PRESETS_GROUP_ACTIONS.CREATE}
						hasReportTabs={(callingTable === null && hasChainReportTabs)}
					/>
				)}

			</Fragment>
		);
	}
}

ChainPopupGroup.propTypes = {
	popup: PropTypes.object,
	chain: PropTypes.object,
	doSavePreset: PropTypes.func,
	doRemovePreset: PropTypes.func,
	filterCompareTo: PropTypes.object,
};

ChainPopupGroup.defaultProps = {
};

const mapStateToProps = (state, props) => ({
	popup: state.chain.competetivePopup,
	clusterPresets: getPresetsByCluster(state, props),
	chain: state.chain,
	filterCompareTo: state.chain.filterCompareTo,
	
	presets: state.presets,
	isLoading: state.presets.isLoading,

	sections: state.init.sections,

});

const mapDispatchToProps = dispatch => ({
	doSavePreset: (data) => dispatch(doSavePreset(data)),
	doRemovePreset: (data) => dispatch(doRemovePreset(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChainPopupGroup);