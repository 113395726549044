import React from "react";
import PropTypes from 'prop-types';
  

const GaugeCircle = (props) => {

	const {
		className,
	    // name,
	    data: {
			value,
			segmentName,
			tooltipMax,
			tooltipMin,
			tooltipAvg
	    }
	} = props;
 
	return (
		<div className={`gauge-circle ${className}`}>
			<div className="gauge-circle-caption">{props.name}</div>
			<div className="gauge-circle-content">
				<div className="gauge-circle-value">
			 		{value ? value : ""}
					<span className="gauge-circle-value__sufix">%</span>
				</div>
			</div>
			<div className="gauge-circle-pointer" style={{
				transform: `rotate(${(+value*360)/100}deg)`
			}}></div>

			{tooltipMax && <div className="gauge-circle-info gauge-circle-info--left">
				{tooltipMax.chain}: <b>{tooltipMax.value}%</b>
				<span className="gauge-circle-info__name">(highest {segmentName})</span>
			</div>}
			
			{tooltipMin && <div className="gauge-circle-info gauge-circle-info--right">
				{tooltipMin.chain}: <b>{tooltipMin.value}%</b>
				<span className="gauge-circle-info__name">(lowest {segmentName})</span>
			</div>}
			
			{tooltipMin && <div className="gauge-circle-info gauge-circle-info--bottom">
				{segmentName} average: <b>{tooltipAvg.value}%</b>
			</div>}
		</div>
	)
}

 


GaugeCircle.propTypes = {
	name: PropTypes.string,
	className: PropTypes.string,
	data: PropTypes.object
};

GaugeCircle.defaultProps = {
	name: "",
	className: "",

	data: {
		value: 0,
		tooltipMax: null,
		tooltipMin: null,
		tooltipAvg: null,
	}
}

export default GaugeCircle;