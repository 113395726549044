import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { 
	DOCUMENT_ROOT_TITLE,
	DOCUMENT_ROOT_TITLE_SEPARATOR,
} from '../../utils/constants';

import MainHeader from '../../containers/MainHeader';
import ChainList from '../../components/ChainList';

import Preloader from '../../components/Preloader';
import { getChainsByCluster } from '../../selectors/section.selectors';

 
class Direction extends Component {

	constructor(props){
		super(props);
		this.documentTitleUpdate = this.documentTitleUpdate.bind(this);

		this.nodeApp = document.getElementById('root');
	}

	componentDidMount(){
		window.scrollTo(0, 0);
		this.documentTitleUpdate();
	}

	componentDidUpdate(){
		this.documentTitleUpdate();
	}

	documentTitleUpdate(){
		const {cluster} = this.props.clusterData;
		document.title = `${DOCUMENT_ROOT_TITLE}${DOCUMENT_ROOT_TITLE_SEPARATOR}${cluster.captionName}`;
	}

	render() {
		const {
			isLoaded,
			clusterData:{
				chains,
				tableGroups,
			},
			match,
			history,
			avaliableChainViews,
		} = this.props;

		const icons = new Map()
			icons.set(0, 'icon-door');
			icons.set(1, 'icon-rings');
			icons.set(2, 'icon-hrt');

		return (
			<Fragment>
				<MainHeader 
					history={history}
					match={match}
					alignTooltips
				/>
				<div className="container container-sidebar container--wide">
					<aside className="container-sidebar-side">
						<div className="container-sidebar-side__separator">
							<div className="main-caption main-caption--primary">View Rankings</div>
						</div>
						<div className="container-sidebar-content">
							<ul className="nav-side">
								{tableGroups
									.filter( group => group.isPersonal !== true )
									.map((group, indexGroup) => (
										<li className="nav-side__item" key={`${indexGroup}${group.aliasName}`}>
											<Link className="nav-side-link" to={{ pathname: `${match.url}/rankings/${group.aliasName}` }}>
												<svg className="nav-side-link__icon" dangerouslySetInnerHTML={{__html: `<use xlink:href="#${icons.get(indexGroup)}" />` }} />
												<span className="nav-side-link__caption">{group.name}</span>
											</Link>
											<ul className="nav-side-deep">
												{group.tables
													.filter( group => group.isPersonal !== true )
													.map((table, indexTable) => ( 
													<li className="nav-side-deep__item" key={`${indexGroup}${indexTable}`}>
														<Link className="nav-side-deep__link" to={{ 
															pathname: `${match.url}/rankings/${group.aliasName}`,
															hash: `#${table.aliasName}`
														}}>{table.name}</Link>
													</li>
												))}
											</ul>
										</li>
								))}
							</ul>
						</div>
					</aside>
					<div className="container-sidebar-main">
						<div className="main-caption">Insights for a Specific Chain</div>
						<div className="container-sidebar-content">
							{isLoaded === false && (
								<Preloader />
							)}
							{isLoaded === true && chains.length > 0 && (
								<ChainList 
									chainList={chains} 
									groupList={tableGroups}
									avaliableChainViews={avaliableChainViews}
								/>
							)}
							{isLoaded === true && chains.length === 0 && (
								<p>List chain is empty...</p>
							)}
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

 
const mapStateToProps = (state, props) => ({
	clusterData: getChainsByCluster(state, props),
	avaliableChainViews: state.init.avaliableChainViews,
	isLoaded: state.init.isLoaded,
});

// const mapDispatchToProps = dispatch => ({});
// export default connect(mapStateToProps, mapDispatchToProps)(Direction);
export default connect(mapStateToProps)(Direction);