import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ROOT_URL, TABLE_TYPES_COLUMN, TOOLTIP_TYPES } from '../../utils/constants';
  
 
const TableCellContent = props => {

	switch(props.content.type) {

		case TABLE_TYPES_COLUMN.STRING_INLINE:
			return (
				<span className="content-text-string" title={props.content.value.trim()}>{props.content.value}</span>
			)

		case TABLE_TYPES_COLUMN.NPS:
			return (
				<span className="content-text-string" title={props.content.value.trim()}>
					<span className="tooltip-question-holder">
						{props.content.value}
						<sup className="tooltip-question" clipboard-disable="true"> ?
							<span className="tooltip-question__content">Net Promoter Score (NPS) is a key measure of customer satisfaction. Scores range from -100 to +100. Scores above +30 are considered very good for chain restaurants</span>
						</sup>
					</span>
				</span>
			)

		case TABLE_TYPES_COLUMN.NPS_VALUE:
			return (
				<Fragment>
					{( props.content.value === null ) ? '-' : `${(props.content.value < 0) ? `${props.content.value}` : `+${props.content.value}` }`}
				</Fragment>
			)

		case TOOLTIP_TYPES.BASE_SIZE:
			return (
				<span className="tooltip-base-size">base size: n={props.content.value.toLocaleString()}</span>
			)


		case TABLE_TYPES_COLUMN.CHAIN_LINK:

			const {
				location:{
					clusterName,
					groupName
				}, 
				chainId,
				stateId,
				dmaRegionId,
			} = props.content;

			const paramId = (stateId || dmaRegionId) || null;

			return (
				<span className="content-text-string"  title={props.content.value.trim()}>
					<Link className="content-text-chain-link"  to={{ pathname: `${ROOT_URL}${clusterName}/chain/${chainId}${(paramId) ? `/${paramId}`:``}/${groupName}` }}>{props.content.value}</Link>
				</span>
			)

		case TABLE_TYPES_COLUMN.NUMBER_PERCENT:
			return (
				<Fragment>
					{( props.content.value === null ) ? '-' : `${(props.content.value === 0) ? '<1' : props.content.value }%`}
				</Fragment>
			)

		case TABLE_TYPES_COLUMN.CURENCY_NUMBER:
			return (
				<Fragment>
					{( props.content.value === null ) ? '-' : `${props.content.value}`}
				</Fragment>
			)

		case TABLE_TYPES_COLUMN.NUMBER_INT:
			return (
				<Fragment>
					{( props.content.value === null ) ? '-' : props.content.value.toLocaleString()}
				</Fragment>
			)


		case TABLE_TYPES_COLUMN.NUMBER_PERCENT_KEYWORD_SIGN:
			return (
				<Fragment>
					{( props.content.value === null ) ? '-' : `${(props.content.value < 1) ? '<1' : props.content.value }%`}
				</Fragment>
			)


		case TABLE_TYPES_COLUMN.NUMBER_PERCENT_DIFFERENCE:
			const iconClass = (+props.content.value < 0) ? 'icon-direction--down' : 'icon-direction--up';

			return (
				<Fragment>
					{ props.content.value === null ? (<span>-</span>) 
					: (<span className="content-text-difference">
						<span className="content-text-difference__value">{(props.content.value === 0) ? '<1' : (props.content.value < 0) ? props.content.value : `+${props.content.value}`  }%</span>
						<span className={`icon-direction ${iconClass}`} clipboard-disable="true">↑</span>
					</span>)}
				</Fragment>
			)

		case TOOLTIP_TYPES.RESPONSE_DISTRIBUTION:

			const importlessNameList = ['average', 'slightly below average', 'significantly below average'];

			return (
				<div className="tooltip tooltip-distribution animation-show-tooltip">
					<div className="tooltip-header">{props.content.caption}</div>
					<div className="tooltip-content">
						{props.content.value.map((item, index) => {
							return (
								<div className="gauge-linear" key={index}>
									<div className="gauge-linear-label">{item.name}</div>
									<div className="gauge-linear-meter"> 
										<div className={`gauge-linear-progress ${(importlessNameList.includes(item.name.toLocaleLowerCase()) === true) ? `is-low-priority` : `` }`} style={{width: `${item.value}%`}} ></div>
									</div>
									<div className="gauge-linear-value">{+item.value}%</div>
								</div>
							);
						})}

					</div>
				</div>
			)

		default:
			return (
				<Fragment>
					{props.content.value}
				</Fragment>
			)
	}

};


TableCellContent.propTypes = {
	content: PropTypes.object
};

export default TableCellContent;