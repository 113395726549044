import React, { Component } from "react";
import {smoothScrollTo} from '../../utils/helpers';

class ButtonScrollUp extends Component {
 
	constructor(props) {
		super(props);
		this.onWindowScroll = this.onWindowScroll.bind(this);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		window.addEventListener('scroll', this.onWindowScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.onWindowScroll);
	}

	onWindowScroll(){
		if(this.nodeButton)
			if (window.pageYOffset > 100) {
				this.nodeButton.classList.add('is-active');
			} else {
				this.nodeButton.classList.remove('is-active');
			}
 	}

	render() {
		return(
			<div className="scroll-up">
				<div className="container scroll-up__container">
					<button className="button scroll-up__button" ref={(node) => this.nodeButton = node}  onClick={(event) => {
						event.preventDefault();
						event.stopPropagation();

						smoothScrollTo(0, 600);
					}}>Up</button>
				</div>
			</div>
		)
	}
}


export default ButtonScrollUp;