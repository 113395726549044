import React, {Component} from "react";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
 
import SectionWrapper from '../../../containers/SectionWrapper';
import {getDataTable} from '../../../route/Chain/Chain.selectors.js';


import Highcharts from 'highcharts';
import {HIGHCHARTS} from '../../../utils/presets';
import {borowserDetect} from '../../../utils/helpers';


class ChartScatter extends Component {

	constructor(props){
		super(props);

		this.chartInstance = null;
		this.buildChart = this.buildChart.bind(this);
	}

	componentDidMount() {
		const { data } = this.props;
		if (data)
			this.buildChart();
	}
 
	componentDidUpdate(){
		const { data } = this.props;
		if (data)
			this.buildChart();
	}

	componentWillUnmount() {
		if (this.chartInstance)
			this.chartInstance.destroy();
	}

	buildChart() {

		const { data, chain } = this.props;

		if (this.chartInstance)
			this.chartInstance.destroy();

		this.chartInstance = new Highcharts.Chart({
			...HIGHCHARTS.CHAIN_AREA_SCATTER,
			chart: {
				renderTo: 'chartBoxScatter',
				type: 'scatter',
				height: 275,
				backgroundColor:'rgba(255, 255, 255, 0.0)',
			},
			exporting: { 
				enabled: false
			},

			tooltip: {
				followPointer: false,
				backgroundColor: '#ffffff',
				borderColor: '#E0E0E0',
				borderRadius: 3,
				shadow: false,
				useHTML: true,
				formatter: function () {
					const indexData = this.series.data.indexOf( this.point );

					return `${ Math.round(this.point.y * 100) }% rate ${chain.name} as excellent or very good in <b>${this.point.name}</b><br />
							<b>${this.point.name}</b> is #${indexData+1} in importance out of ${this.series.data.length} based on consumer perceptions`;
				}
			},

			plotOptions: {
				turboThreshold: 0,
				scatter: {
					turboThreshold: 0,
					marker: {
						radius: 6,
						states: {
							hover: {
								enabled: true,
								lineColor: "#ffffff",
								radius: 6,
							}
						}
					},
				},
				series: {
					stickyTracking: (borowserDetect().isFirefox() === true) ? true : false,
					turboThreshold: 0,
				}
			},

			series: [{
				color: '#22A8F3',
				data: data.filter(item => item.x && item.y )
			}],

		});
	}

 
	render() {
		const { table, data, chain } = this.props;
		
		return (
			<SectionWrapper config={{
				...table, 
				isShowData: (data === null) ? false : true,
				copyData: (data === null) ? null : data.reduce( (list, item) => { 
					if (item.x && item.y)
						list.push([item.name, item.x, item.y]);
					return list
				}, [[table.name.toUpperCase()],['','Important', 'Performance']]),
			}}>
				<div className="scatter-chart" clipboard={table.methodName}>
					<div className="scatter-chart-label scatter-chart-label--top">STRONGER {chain.name} PERFORMANCE</div>
					<div className="scatter-chart-scatter" id="chartBoxScatter"></div>
					<div className="scatter-chart-label scatter-chart-label--bottom">WEAKER {chain.name} PERFORMANCE</div>
					<div className="scatter-chart-label scatter-chart-label--left">LESS<br /> IMPORTANT TO CONSUMERS</div>
					<div className="scatter-chart-label scatter-chart-label--right">MORE<br /> IMPORTANT TO CONSUMERS</div>
				</div>
				<div className="scatter-chart-footer">Each dot above represents a performance attribute; use this to assess where the brand is comparatively strong / weak, and how that stacks up with what consumers care about.</div>
			</SectionWrapper>
		);
	}
}

 
ChartScatter.propTypes = {
	data: PropTypes.array
};

const mapStateToProps = (state, props) => ({
	data: getDataTable(state, props),
})

export default connect(mapStateToProps)(ChartScatter);