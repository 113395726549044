import React, { Component } from "react";
import PropTypes from 'prop-types';

import domtoimage from '../../utils/dom-to-image-fixed';
import Clipboard from '../../utils/clipboard';
import Notification from '../../utils/notification';

import {borowserDetect} from '../../utils/helpers';

class ClipboardButtons extends Component {
 
	constructor(props) {
		super(props);

		this.onCopyText = this.onCopyText.bind(this);
		this.notification = new Notification();
		
		this.nodeGraphic = null;
	}

	componentDidUpdate(){
		this.removeImageFromServer();
	}

	componentWillUnmount(){
		this.removeImageFromServer();
	}

	onCopyText(event){
		event.preventDefault();

		const node = document.querySelector(`[clipboard="${this.props.nodeNameAttribute}"]`);

		new Clipboard({
			data: this.props.copyData,
			node: node,
			onComplete: (response) => {
				this.notification.add((response.success === true) ? `Successfully copied to your clipboard` : `Not successfully copied to your clipboard`);
			}
		})
	}
	
	onCopyDOMImage = () => {

		const node = document.querySelector(`[snapshot="${this.props.nodeNameAttribute}"]`),
			nodeScrollBar = node.querySelector(`.js-smooth-scroll`);


		const cleaner = () => {
			this.removeImageFromServer();
			if (this.nodeGraphic)
			nodeScrollBar.removeEventListener('smoothScrollEvent', cleaner, false);
		}

		if (nodeScrollBar)
			nodeScrollBar.addEventListener('smoothScrollEvent',  cleaner, false);
		

		if (node === null){
			this.notification.add(`Not successfully copied to your clipboard.`);
			return
		}

			setTimeout( () => {
				node.classList.add('snip-process');

				domtoimage.toPng(node).then( (dataUrl) => {
					node.classList.remove('snip-process');

					const image = new Image();
					image.onload = imageEvent => {

						this.nodeGraphic = document.createElement('div');
						this.nodeGraphic.appendChild(imageEvent.target);
						this.nodeButtonScreen.classList.add('button-screenshot--ready');
						new Clipboard({
							node: this.nodeGraphic,
							onComplete: (response) => {
								this.notification.add((response.success === true) ? `Snipped & successfully copied to your clipboard` : `Not successfully copied to your clipboard`);
							}
						});
					}

					image.src = dataUrl;
				})
				.catch( (error) => {
 
					this.notification.add(`Not successfully copied to your clipboard.`);

				});
			}, 1);
 	}

 	removeImageFromServer = () => {

 		if (this.nodeGraphic === null)
 			return

 		const formData = new FormData();
 		formData.append('imageName',this.nodeGraphic.imageName);

 		this.nodeGraphic = null;
 		this.nodeButtonScreen.classList.remove('button-screenshot--ready');

		const xhr = new XMLHttpRequest();
		xhr.withCredentials = true;
		xhr.open('POST', `${process.env.REACT_APP_API}Image/Delete`);
		xhr.send(formData);
 	}


	onClickToClipboard = () => {
 		new Clipboard({
			node: this.nodeGraphic,
			onComplete: (response) => {
				this.notification.add((response.success === true) ? `Successfully copied to your clipboard` : `Not successfully copied to your clipboard`);
			}
		});
	}


	render() {
		return(
			<ul className={`clipboard-buttons ${this.props.className}`}>
				<li className="clipboard-buttons__item">
					<button className="button button-clipboard clipboard-buttons__button button-copy" onClick={this.onCopyText}>Copy data</button>
				</li>
				{(borowserDetect().isIE() === false && borowserDetect().isEdge() === false) ?  (<li className="clipboard-buttons__item">

					<button className="button button-screenshot clipboard-buttons__button" onClick={event => {
						event.preventDefault();
						if (this.nodeGraphic === null){
							this.onCopyDOMImage();
						} else {
							this.onClickToClipboard();
						}

					}} ref={node => this.nodeButtonScreen = node}>
						<svg className="icon icon-scissors" dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-scissors" />' }} />
						<svg className="icon icon-copy" dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-copy" />' }} />
					</button>

				</li>) : null}
			</ul>
		)
	}
}
 

ClipboardButtons.propTypes = {
	className: PropTypes.string,
	fileName: PropTypes.string,
	nodeNameAttribute: PropTypes.string,
 	copyData: PropTypes.array,
};

ClipboardButtons.defaultProps = {
	className: "",
	fileName: "screen",
	nodeNameAttribute: null,
	copyData: null
}

export default ClipboardButtons;