
import { 
	ACTIONS_CHAIN, 
	// CHAIN_COMPARE_TYPES,
	RESPONDENT_TYPES
} from '../utils/constants';

const initialState = {
	filterRespondentTypeId: RESPONDENT_TYPES.TOTAL_US,
	defaultPreset:null,
	filterCompareTo:{
		type: null,
		payload: null
	},
	reportCompareTo:{
		type: null,
		payload: null
	},

	competetivePopup:{
		isOpened: false,
		callingTable: null,
	},
	dataGroups: null,
	header:{
		isLoadProcess: false,
		isLoaded: false,
		data: null
	},
};
 
export default function reducerChain(state = initialState, action) {
	
	switch (action.type) {

		case ACTIONS_CHAIN.CLEAN_STORE: {
			return {
				...state,
				...{...initialState}
			};
		}

		case ACTIONS_CHAIN.FETCH_CHAIN_HEADER_START: {
			return {
				...state,
				header: {
					...state.header,
					isLoadProcess: true,
				}
			};
		} 

		case ACTIONS_CHAIN.FETCH_CHAIN_HEADER_FINISH: {
			return {
				...state,
				header: {
					...state.header,
					isLoadProcess: false,
					isLoaded: true,
					data: action.payload
				}
			};
		}

		case ACTIONS_CHAIN.CREATE_GROUPS_STORE: {
			return {
				...state,
				dataGroups: action.payload.map( element => {
					return {	
						methodName: element.methodName,
						aliasName: element.aliasName,
						isLoadProcess: false,
						isLoaded: false,
						tables: element.tables.map( (table, index) => {
							return {
								data: null,
								methodName: table.methodName,
								isHistory: false,
							}
						})
					}	
				})
			};
		}


		case ACTIONS_CHAIN.FETCH_BY_GROUP_DATA_START: {
			const index = state.dataGroups.findIndex(group => (group.methodName === action.payload.methodName));
			
			return {
				...state,
				dataGroups:[
					...state.dataGroups.slice(0, index),{
						...state.dataGroups[index], 
						isLoadProcess: true
					}, ...state.dataGroups.slice(index + 1),
				]
			};
		}

		case ACTIONS_CHAIN.FETCH_BY_GROUP_DATA_FINISH: {
			const {tables, methodName} = action.payload;
			const index = state.dataGroups.findIndex(group => (group.methodName === methodName));

			return {
				...state,
				dataGroups: [
					...state.dataGroups.slice(0, index),
					...[{...state.dataGroups[index],
							tables: tables,
							isLoadProcess: false, 
							isLoaded: true}],
					...state.dataGroups.slice(index + 1),
				]
			};
		}

		case ACTIONS_CHAIN.UPDATE_GROUP_TABLE: {

			const { 
				payload:{
					groupMethodName,
					tableMethodName,
					options,
				}
			} = action;

			const indexGroup = state.dataGroups.findIndex(group => (group.methodName === groupMethodName));
			const indexTable = state.dataGroups[indexGroup].tables.findIndex(table => (table.methodName === tableMethodName));	
 
			state.dataGroups[indexGroup].tables[indexTable] = {
				...state.dataGroups[indexGroup].tables[indexTable],
				...options
			}

			return {
				...state,
				dataGroups: state.dataGroups
			};
		}

		case ACTIONS_CHAIN.CHANGE_RESPONDENT_TYPE: {

			const dataGroups = state.dataGroups.map(group => {
				return {
					...group,
					isLoaded: false,
					tables: group.tables.map( table => {
						return{
							...table,
							data: null
						}
					})
				}

			});

			return {
				...state,
				dataGroups: dataGroups,
				header:{
					isLoadProcess: false,
					isLoaded: false,
					data: null
				},
				filterRespondentTypeId: action.payload
			};
		}
 
		case ACTIONS_CHAIN.SET_DEFAULT_PRESET: {
			return {
				...state,
				defaultPreset: {
					...action.payload
				}
			};
		} 


		case ACTIONS_CHAIN.CHANGE_FILTER_COMPARE_TO: {
		
			const { isClearDataGroups = false, filterCompareTo } = action.payload;

			let newStatePart = { 
				filterCompareTo: { ...filterCompareTo }
			};

			if (isClearDataGroups)
				newStatePart = {...newStatePart, dataGroups: null};


			return {
				...state,
				...newStatePart
			};
		}


		case ACTIONS_CHAIN.CHANGE_REPORT_COMPARE_TO: {
		
			const {data, type} = action.payload;

			return {
				...state,
				reportCompareTo:{
					type: type,
					payload: data
				}
			};
		}


		case ACTIONS_CHAIN.TOGGLE_OPEN_POPUP: {
			return {
				...state,
				competetivePopup:{
					isOpened: (action.payload === false) ? false : true,
					callingTable: action.payload || null,
				},
			};
		}

		case ACTIONS_CHAIN.DELETE_UNMET_NEEDS: {
			const { 
				payload:{
					methodName,
					id,
				}
			} = action;

			const indexGroup = state.dataGroups.findIndex(group => (group.methodName === 'GetOverview'));
			const indexTable = state.dataGroups[indexGroup].tables.findIndex(table => (table.methodName === methodName));	
			const tableData = state.dataGroups[indexGroup].tables[indexTable].data;
			const updatedTableData = tableData.filter(item => item.id !== id);

			state.dataGroups[indexGroup].tables[indexTable] = {
				...state.dataGroups[indexGroup].tables[indexTable],
				data: updatedTableData,
			}
	 
			return {
				...state,
				dataGroups: state.dataGroups
			}
		}

		default: {
			return state;
		}
	}
}