import React from "react";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

 
import SectionWrapper from '../../../containers/SectionWrapper';
import { getDataTable } from '../../../route/Chain/Chain.selectors.js';


const SituationAssessment = props => {

	const {
	    table,
	    data,
	    chain
	} = props;

	const getList = (list, config) => {

		const serialisedList = list.map( (item, index) => {
			return <li className="sides-list-item" key={index}>
				
				{/*<svg className="icon" viewBox="0 0 16 16" width="16" height="16" dangerouslySetInnerHTML={{__html: `<path stroke="${config.color}" stroke-width="1" d="M4 8 l9 0"></path>` }} />  */}

				{config.isStrengths === true ? (
					<svg className="icon" viewBox="0 0 459.313 459.313" width="12" height="12" dangerouslySetInnerHTML={{__html: `<path fill="#43b975" d="M459.3 229.6c0 22.2-18 40.2-40.2 40.2H40.2c-11.1 0-21.1-4.5-28.4-11.8C4.5 250.8 0 240.8 0 229.7c0-22.2 18-40.2 40.2-40.2h378.9c22.2 0 40.2 18 40.2 40.1z"/><path fill="#43b975" d="M230.6 0c22.2 0 40.2 18 40.2 40.2v378.9c0 11.1-4.5 21.1-11.8 28.4-7.3 7.3-17.3 11.8-28.4 11.8-22.2 0-40.2-18-40.2-40.2V40.2C190.5 18 208.5 0 230.6 0z"/>`}} />
					) : (
					<svg className="icon" viewBox="0 0 459.313 459.313" width="12" height="12" dangerouslySetInnerHTML={{__html: `<path fill="#f53b44" d="M459.313 229.648c0 22.201-17.992 40.199-40.205 40.199H40.181c-11.094 0-21.14-4.498-28.416-11.774C4.495 250.808 0 240.76 0 229.66c-.006-22.204 17.992-40.199 40.202-40.193h378.936c22.195.005 40.17 17.989 40.175 40.181z"/>` }} />  
					)}

				<span>{item.name}<span className="sides-list-item__value">({(item.value > 0) ? `+${item.value}` : ((item.value === 0) ? '<1' : item.value) })</span></span>		
			</li>
		});

		const classList = [
			...['sides-list-items'],
			...[(serialisedList.length > 1) ? 'sides-list-items--columns' : ''],
		].filter( element => element ).join(' ');

		return (serialisedList.length ? <ul className={classList}>{serialisedList}</ul> : <div className="sides-list-empty">{config.notify}</div>)
	} 

	return (
		<SectionWrapper config={{
			...table, 
			isShowData: (data === null) ? false : true,
			copyData: null
		}}>
			{data && <div className="section-content--container sides-list" clipboard={table.methodName}>
				<span className="hidden-layer">{table.name.toUpperCase()}</span>
				<div className="sides-list-colum">
					<div className="sides-list__title">
						STRENGTHS
						<div className="tooltip-hint">
							{table.isExtended === true && (data.strengths.some( item => item.value <= 0 ) ? 
 									`Compared to its closest competitors, these are the top assets for ${chain.name} (even if results are negative) ` : 
 									`Compared to its closest competitors, these are the top assets for ${chain.name}`)}

							{table.isExtended === false && `Compared to its closest competitors, these are the top occasions for ${chain.name}`}
						</div>
					</div>
					{getList(data.strengths, {
						isStrengths: true,
						notify: `Compared to the competitive set, no strengths were found. ${chain.name} is on par with its competition`
					})}						 
				</div>

				<div className="sides-list-colum">
					<div className="sides-list__title">
						WEAKNESSES
						<div className="tooltip-hint">
							{table.isExtended === true && `Compared to its closest competitors, there are the areas of opportunity for ${chain.name}`}
							{table.isExtended === false && `Compared to its closest competitors, these are the top occasions for ${chain.name}`}
						</div>
					</div>


					{getList(data.weakness, {
						isStrengths: false,
						notify: `Compared to the competitive set, no weaknesses were found. ${chain.name} is on par with its competition`
					})}
				</div>
			</div>}
			
		</SectionWrapper>
	);

}
 

SituationAssessment.propTypes = {
	data: PropTypes.object,
	chain: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
	data: getDataTable(state, props),
})


export default connect(mapStateToProps)(SituationAssessment);