import React, { Component } from "react";
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

// import {saveAs} from 'file-saver';
// import domtoimage from 'utils/dom-to-image-fixed';
import Notification from '../../../utils/notification';

import Highcharts from 'highcharts';
// import {borowserDetect} from '../../../utils/helpers';
// import Exporting from 'highcharts/modules/exporting';
// Exporting(Highcharts);

import ClipboardButtons from '../../../components/ClipboardButtons';

import {HIGHCHARTS} from '../../../utils/presets';


class PopUpChart extends Component {

	constructor(props){
		super(props);

		this.notification = new Notification();

		this.onClose = this.onClose.bind(this);
		this.onWindowClick = this.onWindowClick.bind(this);
		this.modalDOMNode = document.getElementById('modal');
	}

	componentDidMount() {
		const {
			title,
			data:{
				chain, 
				compareTo
			}
		} = this.props;

		window.addEventListener('click', this.onWindowClick);
		window.addEventListener('touchstart', this.onWindowClick);
 
		this.modalDOMNode.classList.add("is-active");
 
		this.chartInstance = new Highcharts.Chart({
			...HIGHCHARTS.CHAIN_AREA_COMPARE,
			chart: {
				renderTo: 'chartBoxArea',
				type: 'area',
				//width: 700,
				spacingTop: 50,
			},

			title: {
				text: title,
				align: 'left',
				x: 0,
				y: 0,
				style:{
					color: "#000000", 
					fontSize: "26px",
					fontFamily: 'Comfortaa, cursive',
					textTransform: 'uppercase'
				}
			},

			xAxis: {
				...HIGHCHARTS.CHAIN_AREA_COMPARE.xAxis,
				categories: [...chain.data.map( category => category.name )],
			},
			exporting: { 
				enabled: false
			},
			series: [{
				name: chain.name,
				data: chain.data,
				color: '#22A8F3',
				fillOpacity: 0.2,
				marker:{
					symbol:'circle',
					radius:2,
					enabled:false // point on line
				}
			},{
				name: compareTo.name,
				data: compareTo.data,
				color: '#FF8F80',
				fillOpacity: 0.2,
				marker:{
					symbol:'circle',
					radius:2,
					enabled:false // point on line
				}
			}]
		});


		const nodeCopyright = document.createElement('span');
				nodeCopyright.innerHTML = 'BrandFingerprints<sup>tm<sup>';
				nodeCopyright.setAttribute('class', 'copyright');
		this.chartNode.appendChild(nodeCopyright);
	}

	componentWillUnmount() {
		window.removeEventListener('click', this.onWindowClick);
		window.removeEventListener('touchstart', this.onWindowClick);

		this.modalDOMNode.classList.remove("is-active");
		this.chartInstance.destroy();
	}
 
	onWindowClick(event) {
		if (this.modalDOMNode === event.target)
			this.onClose();
	}

	/*
	onExport = (fileName) => {

		this.notification.add(`Snipped and will download`);

		const nodeCopyright = document.createElement('span');
				nodeCopyright.innerHTML = 'BrandFingerprints<sup>tm<sup>';
				nodeCopyright.setAttribute('class', 'copyright');
 
			this.chartNode.appendChild(nodeCopyright);

		setTimeout( () => {
 
			domtoimage.toBlob(this.chartNode).then( (blob) => {
				
				nodeCopyright.parentNode.removeChild(nodeCopyright);
				saveAs(blob, `${fileName}.png`);

			}).catch( () => {
					html2canvas(this.chartNode,{
						logging: false,
					}).then((canvas) => {
						canvas.toBlob( (blob) => {
						    saveAs(blob, `${fileName}.png`);
						});
						
					});
			});

		}, 1);
 
		// this.chartInstance.exportChart({
		// 	type: 'image/png',
		// 	filename: caption,
		// 	width: this.chartNode.clientWidth,
		// });
	}
	*/

	onClose(){
		this.modalDOMNode.classList.remove("is-active");
		this.props.onClose();
	}

	render() {


		const genId = Math.random().toString(36).substr(2, 9);

		return ReactDOM.createPortal(
			<div className="modal-content animation-show-modal-content">
 				<div className="modal-body modal-body-chart">
					{(window.generalConfig.access.trial === false) && (
						<ClipboardButtons 
							fileName={this.props.name}
							nodeNameAttribute={genId}
						/>
					)}
					
					<button className="button modal-button-close" onClick={(event) => {
						event.preventDefault();
						this.onClose();
					}}>
						<svg className="icon icon-close" dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-close" />' }} />
					</button>
					<div id="chartBoxArea" style={{position: 'relative'}} ref={node => this.chartNode = node} snapshot={genId}></div>
				</div>
			</div>,
			this.modalDOMNode
		);
	}
}

 
PopUpChart.propTypes = {
	name: PropTypes.string,
	data: PropTypes.object,
	onClose: PropTypes.func
};

PopUpChart.defaultProps = {
	name: ``,
};
 

export default PopUpChart;