import {BUTTONS_DOWNLOAD, CHAIN_COMPARE_TYPES} from '../../utils/constants';

// WILD TEXT
export const getPostDataCompare = ({buttonTypeId, chainId, stateId, dmaRegionId, compare, respondentTypeId}) => {

		switch(buttonTypeId) {

			case BUTTONS_DOWNLOAD.REPORT_XLS:

				switch(compare.compareType) {
					case CHAIN_COMPARE_TYPES.GROUP:
                        return {
							handlerMethod: 'GetSummaryReport',
							post: {
								chainId,
								dmaRegionId,
								stateId,
								presetId: compare.compareData.id,
								respondentTypeId,
							}
						}

					default:
						return {
							handlerMethod: 'GetSummaryReportSpecificBrand',
							post: {
								chainId,
								dmaRegionId,
								stateId,
								chainToCompareId: compare.compareData.id,
								stateToCompareId: compare.compareData.stateId,
								dmaRegionToCompareId: compare.compareData.dmaRegionId,
								respondentTypeId,
							}
						}       
				}

			default:

				switch(compare.compareType) {
					case CHAIN_COMPARE_TYPES.GROUP:
                        return {
							handlerMethod: 'BuildPpt',
							post: {
								chainId,
								stateId,
								dmaRegionId,
								presetId: compare.compareData.id,
								format: buttonTypeId,
								respondentTypeId,
							}
						}
					default:
						return {
							handlerMethod: 'BuildPptForSpecificBrand',
							post: {
								chainId,
								stateId,
								dmaRegionId,
								chainToCompareId: compare.compareData.id,
								stateToCompareId: compare.compareData.stateId,
								dmaRegionToCompareId: compare.compareData.dmaRegionId,
								format: buttonTypeId,
								respondentTypeId,
							}
						}       
				}

		}
}