import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
	PRESETS_GROUP_ACTIONS,
	// PRESET_TYPE_LIST,
	// TYPE_CHAIN,
} from '../../utils/constants';
import PerfectScrollbar from "../../components/PerfectScrollbar";

import Notification from '../../utils/notification';


const getPresetTypeByPreset = (presetTypeList, { chains = [] }) => {
	const [ presetType = null ] = presetTypeList.filter(presetType => {
		return chains.every(chain => {
			return presetType.chains.find(({id}) => id === chain.id);
		})
	});

	return presetType;
}



class ChainPopupGroupEditor extends Component {

	constructor(props){
		super(props);

		this.state = {
			searchValue: '',
			presetTypeId: null,
			preset: {
				chains: [],
				id: null,
				isCustom: true,
				name: '',
				segmentGroup: null
			}
		};

		this.onSearch = this.onSearch.bind(this);
		this.onChangeName = this.onChangeName.bind(this);
		this.onToggleSelectChain = this.onToggleSelectChain.bind(this);
		this.onSetSelectChains  = this.onSetSelectChains.bind(this);
		this.setTypeSegment  = this.setTypeSegment.bind(this);

		this.notification = new Notification();		
	}

	componentWillMount(){

		const { 
			preset,
			presetTypeId,
			presetTypeList,
			// sections,
			// hasCreate
		} = this.props;

		const [ firstPresetType ] = presetTypeList; 

 		const { id = null } = (preset) ? getPresetTypeByPreset(presetTypeList, preset) : { id: presetTypeId };

   		const currentPresetTypeId = (id === null) ? firstPresetType.id : id;

		if (preset){
			this.setState({
				presetTypeId: currentPresetTypeId,
				preset: {
					chains: preset.chains.slice(),
					id: preset.id,
					isCustom: preset.isCustom,
					name: preset.name,
					segmentGroup: preset.segmentGroup
				}
			});

		} else {
			this.setState({
				presetTypeId: currentPresetTypeId,
			});
		}
	}

	componentDidMount() {
		this.perfectScrollbar = new PerfectScrollbar(this.nodeScrollBar, {
			wheelPropagation: false
		});
	}

	componentWillUnmount() {
		this.perfectScrollbar.destroy();
		this.perfectScrollbar = null;
	}

	componentDidUpdate(prevProps, prevState) {

		if (prevState.searchValue !== this.state.searchValue){

			this.nodeScrollBar.scrollTop = 0;
			this.perfectScrollbar.update();
		}
	}

	onSearch(event) {

		const valueText = event.target.value.trim().toLowerCase();
		this.setState({searchValue: valueText});
	}

	onChangeName(event) {

		const valueText = event.target.value;

		this.setState(prevState => ({
			...prevState,
			preset: {
				...prevState.preset,
				name: valueText
			}
		}));
	}

	onToggleSelectChain(chain) {
		let chains = this.state.preset.chains;
		const index = chains.findIndex(item => item.id === chain.id && item.stateId === chain.stateId && item.dmaRegionId === chain.dmaRegionId);
		if (index === -1){
			chains.push(chain);
		} else {
			chains.splice(index, 1);
		}
		this.setState({
			...this.state,
			preset: {
				...this.state.preset,
				chains: chains
			}
		});
	}

	onSetSelectChains(allChains = []) {
		this.setState(prevState => {

			let chains = prevState.preset.chains;

			chains.length = 0;

			if (allChains.length)
				chains = [...chains, ...allChains];

			return {
				...prevState,
				preset: {
					...prevState.preset,
					chains: chains
				}
			}
		});
	}

	/*
		onClose = () => {
	 		return new Promise((resolve, reject) => {

				const onAnimationComplete = (resolve) => {
					resolve();
					this.nodeModal.removeEventListener('transitionend', onAnimationComplete);
				}

	    		this.nodeModal.addEventListener('transitionend',  (event) => onAnimationComplete(resolve), false);
	 			this.nodeModal.classList.replace('animation-show-modal-win', 'animation-close-modal-win');
	 		});
	 	}
 	*/

 	handlerSave = () => event => {
 		event.preventDefault();

		const { 
			preset, 
			presetTypeId 
		} = this.state;
		
		const {
			presetTypeList,
			// hasCreate,
		} = this.props;

		const presetName = preset.name.trim();

 		if ((preset.chains.length === 0) || (presetName.length === 0)){
 			this.notification.add(`Please select chains<br />and add a group name`);
 			return;
 		}

		const { presets = [] } = presetTypeList.find(presetType => (presetType.isCustom === true)) || {};
		const similarPresetByName = presets.find(item => item.isCustom === true && item.name === presetName && item.id !== preset.id) || null;

		if (similarPresetByName){
 			this.notification.add(`Group name already exists`);
 			return;
		}

		const presetType = presetTypeList.find(({id}) => id === presetTypeId) || null;		

		this.props.callback({
			type: PRESETS_GROUP_ACTIONS.SAVE,
			section: presetType.section,
			preset: {
				...preset,
				name: presetName,
			}
		});
 	}

	setTypeSegment(item){
		this.nodeScrollBar.scrollTop = 0;

		this.setState(prevState => ({
			searchValue: '',
			presetTypeId: item.id,
			preset: {
				...prevState.preset,
				chains: []
			}
		}));
 
	} 


	render() {

		const {
			// sections,
			presetTypeList,
			hasCreate,
			hasReportTabs = false,
		} = this.props;

		const { 
			searchValue, 
			preset,
			presetTypeId,

		} = this.state,

        classListScroller = [
            ...['modal-body-scrollbar'],
            ...['preset-chains-holder'],
            ...[(hasReportTabs === true) ? 'modal-body-scrollbar--sm' : ''],
        ].filter( element => element ).join(' '),

		regex = new RegExp('^'+ searchValue, 'ig');

		const presetType = presetTypeList.find(({id}) => id === presetTypeId) || null;

		const chainList = presetType.chains
				.filter(chain => {
					if (chain.stateId === null && chain.dmaRegionId === null)
						return true;

					return false;
				})
				.filter(chain => ( chain.name.toLowerCase().search(regex) === 0 ) )
				.map( (chain, index) => {

					const classList = [
						...['preset-chains-caption'],
						...['preset-chains-caption--edit'],
						...[(preset.chains.some( presetChain => presetChain.id === chain.id ) === true) ? 'is-selected' : ''],
						...[(chain.isDisabled === true) ? 'is-disabled' : ''],
						
					].filter( element => element ).join(' ');

					return (
						<div className="preset-chains__item" key={index}>
							<div className={classList} onClick={ (event) => {
								event.preventDefault();
								this.onToggleSelectChain(chain);
							} }>
								{chain.name}
								<button type="button" className="button preset-chains-caption__btn-remove">
									<svg className="icon icon-close" dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-close" />' }} />
								</button>	
							</div>
						</div>
					)
			});

	 	return (
			<div className="modal-win modal-win--primary" ref={node => this.nodeModal = node} >
				<div className="modal-header">
					<div className="modal-title">{(hasCreate === true) ? 'Create' : 'Edit'} custom group</div>
					<div className="modal-header-line"></div>
				</div>
				<div className="modal-body">
					{(hasReportTabs === true) && (
						<ul className="swich-group">
							{presetTypeList
								.filter(presetType => (presetType.isCustom === false))
								.filter(presetType => {

									if (hasCreate)
										return true;

									return (presetTypeId === presetType.id);

								})
								.map( (presetType, index) => {

								const classList = [
									...['swich-group-item'],
									...[(presetTypeId === presetType.id) ? 'is-active' : ''],
									...[(presetType.isEnabled === false) ? 'is-disabled' : ''],
								].filter( element => element ).join(' ');

								return (
									<li 
										className={classList} 
										onClick={event => this.setTypeSegment(presetType)} 
										key={index}>
											{presetType.name}
									</li>
								)

							})}
						</ul>
					)}

					<div className="chain-filter">
						<div className="chain-filter__search">
							<div className="input-text-search-holder">
								<input type="text" className="input-text-search input-text-search--text-indent" onChange={this.onSearch} value={searchValue} placeholder="Search chain here" />
								<svg className="icon icon-search" dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-search" />' }} />
								<div className="input-text-search-line"></div>
							</div>
						</div>

						<div className="chain-filter-buttons">
							<div className="chain-filter-buttons__info">{preset.chains.length} chain{preset.chains.length !== 1 && 's'} selected</div>
							<button type="button" className="button button-link chain-filter-buttons__button" onClick={ (event) => {
								event.preventDefault();

								const chainListId  = presetType.chains
									.filter(chain => chain.isDisabled === false);

								this.onSetSelectChains(chainListId);
							} }>Select all</button>
							<div className="chain-filter-buttons__separator">/</div>
							<button type="button" className="button button-link chain-filter-buttons__button" onClick={ (event) => {
								event.preventDefault();
								this.onSetSelectChains();
							} }>Clear all</button>
						</div>
					</div>

					<div className={classListScroller} ref={node => { this.nodeScrollBar = node }}>
						{(chainList.length > 0) ? (
							<div className="preset-chains">
								{chainList}
							</div>
						) : (
							<div className="text-center preset-chains__not-found">Nothing found...</div>
						)}
					</div>

					<div className="modal-bottom">
						<div className="modal-bottom__field">
							<div className="input-text-search-holder">
								<input type="text" className="input-text-search" value={preset.name} onChange={this.onChangeName} placeholder="Group name type here..." />
								<div className="input-text-search-line"></div>
							</div>
						</div>
						<div className="modal-bottom-buttons">
							<button 
								className={`button button-primary ${(preset.chains.length && preset.name) ? `` : `button-primary--disabled`}`}
								onClick={this.handlerSave()}
							>
								{(this.props.preset) ? "Save" : "Create"} group
							</button>
							<button className="button button-primary" onClick={event => {
								this.props.callback({
									type: PRESETS_GROUP_ACTIONS.CANCEL
								});
							}}>Cancel</button>
						</div>
					</div>

				</div>
			</div>
		);
	}
}


ChainPopupGroupEditor.propTypes = {
	sections: PropTypes.object,
	preset: PropTypes.object,
	callback: PropTypes.func,
	presetTypeId: PropTypes.number,
	presetTypeList: PropTypes.array,
};

ChainPopupGroupEditor.defaultProps = {
	sections: {
		cstores:{
			chains: [],
		},
		restaurants:{
			chains: [],
		},
		supermarkets:{
			chains: [],
		}
	},
	preset: null,
	presetTypeId: null,
	presetTypeList: [],
};

const mapStateToProps = (state, props) => ({
	sections: state.init.sections,
});


export default connect(mapStateToProps)(ChainPopupGroupEditor);