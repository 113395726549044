import React, { useEffect, useMemo } from 'react';

import { createStore, applyMiddleware } from 'redux';
import { Provider, useSelector } from 'react-redux';
import thunk from 'redux-thunk';

import { createBrowserHistory } from 'history';

import { composeWithDevTools } from 'redux-devtools-extension';

import {
    ROOT_URL,
    SECTIONS,
    HOST_ENVIRONMENT,
    EXTEND_VIEW_CHAIN_LIST,
} from './utils/constants';
import {
    getInitialData
} from './actions/init';
import reducer from './reducers';

import Router from "./Router";
import {
    AppId,
    cssX,
    Env,
    PlatformContextProvider,
    usePlatformContext,
    usePlatformFeatureFlag
} from '@datassential/platform-ui-lib';
import '@datassential/platform-ui-lib/dist/assets/base.css';
import cn from "classnames";


const history = createBrowserHistory();
const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

if (HOST_ENVIRONMENT.includes(window.location.hostname) === true)
    window.store = store;

history.listen(location => {

    if (typeof window.ga === 'function') {
        window.ga('set', 'page', window.location.pathname + window.location.search);
        window.ga('send', 'pageview');
    }
});

function AppContent() {
    const availableChainViews = useSelector(state => state.init.avaliableChainViews);

    const handleViewExtendChainClick = setChainId => event => {
        event.preventDefault();

        sessionStorage.setItem('EXTEND_VIEW_CHAIN_ID', setChainId);
        window.location.reload();
    };

    const userActions = useMemo(
        () => {
            const result = [];

            if (window.generalConfig.access.admin === true) {
                result.push({
                    id: 'admin',
                    content: (
                        <a
                            className={cn(cssX("Dropdown__item"), 'is-tall')}
                            href={process.env.REACT_APP_URL_ADMIN}
                            target="_blank"
                        >
                            Tool Admin
                        </a>
                    ),
                });
            }


            if (window.generalConfig.isDatassential && Array.isArray(availableChainViews)) {
                const extendViewChainId = JSON.parse(sessionStorage.getItem('EXTEND_VIEW_CHAIN_ID'));

                availableChainViews.map((chain, index) => {

                    const {
                        caption = null,
                    } = EXTEND_VIEW_CHAIN_LIST.find(item => item.chainId === chain.ffChainId) || {};

                    const setChainId = (extendViewChainId === chain.ffChainId) ? null : chain.ffChainId;

                    result.push({
                        id: 'admin',
                        content: (
                            <div
                                className={cn(cssX("Dropdown__item"), 'is-tall')}
                                onClick={handleViewExtendChainClick(setChainId)}
                            >
                                {`${(chain.ffChainId === extendViewChainId) ? `DS view` : `${caption} view`}`}
                            </div>
                        ),
                    })
                    ;
                });
            }

            return result;
        },
        [availableChainViews]
    );

    const env = useMemo(
        () => {
            switch ((process.env.REACT_APP_PLATFORM_ENV || '').toLowerCase()) {
                case 'qa': return Env.QA;
                case 'staging': return Env.Staging;
                default: return Env.Live;
            }
        },
        []
    );

    return (
        <PlatformContextProvider
            appId={AppId.BrandPerformance}
            env={env}
            additionalUserMenu={userActions}
            enableDevTools={env !== Env.Live}
        >
            <Router/>
        </PlatformContextProvider>
    );
}

function App() {
    useEffect(() => {
        store.dispatch(getInitialData());

        const cluster = SECTIONS.find(cluster => (history.location.pathname.includes(cluster.aliasName) === true)) || null;
        if (cluster && cluster.isEnabled === false)
            history.push(ROOT_URL);
    }, []);

    return (
        <Provider store={store}>
            <AppContent/>
        </Provider>
    );
}


export default App;