import { createSelector } from 'reselect';
import { SECTIONS } from '../../utils/constants';

 
const selectSegmentChainId = (state) => state.rankings.filterSegmentChainId;
const selectSections = (state) => state.init.sections;
// const selectIsInnOutView = (state) => state.init.isInnOutView;

const selectLocationSection = (state, props) => props.match.params[0] || null;
const selectLocationGroup = (state, props) => props.match.params.aliasName;
 
const selectDataGroups = (state) => state.rankings.dataGroups;

export const getChainListBySegmentSelector = createSelector(
	selectSections,
	selectLocationSection,
	selectSegmentChainId,
	// selectIsInnOutView,
	(stateSections, locationSection, segmentId) => {

		const section = SECTIONS.find( (element) => element.aliasName === locationSection ) || null;

		const chainList = (section === null) ? [] : stateSections[section.systemName].chains;

		return  (segmentId) ? chainList.filter((chain) => chain.segmentGroupId === segmentId) : chainList
	}
)

 
export const groupsTableSelector = createSelector(
	selectSections,
	selectLocationSection,
	selectLocationGroup,
	(stateSections, locationSection, locationGroup) => {

		const cluster = SECTIONS.find( (element) => element.aliasName === locationSection ) || null;

		const tableGroups = stateSections[cluster.systemName].tableGroups;
		
		const groupTables = tableGroups.find( (element) => element.aliasName === locationGroup ) || null;
 
		return {
			ready: (tableGroups.length > 0) ? true : false,
			success: (groupTables === null) ? false : true,
			tableGroups : tableGroups,
			groupTables : groupTables,
			clusterName: cluster.captionName,
			aliasName: cluster.aliasName,
		}
	}
)


export const groupsDataSelector = createSelector(
	selectDataGroups,
	selectLocationGroup,
	(dataGroups, aliasGroup) => {

		if (Array.isArray(dataGroups))
			return dataGroups.find( (element) => element.aliasName === aliasGroup ) || null;
		
		return null
	}
)


const selectDataGroupsTable = (state, props) => {

	const { rankings: { dataGroups } } = state;
	const { match: { params:{aliasName} }, table } = props;

	if (dataGroups === null)
		return null

	const selectedGroup = dataGroups.find( (group) => group.aliasName === aliasName ) || null;
	
	return selectedGroup.tables.find( (element) => (element.methodName === table.methodName) ) || null;
};

export const getDataTable = createSelector(
	selectDataGroupsTable,
	tableData => {
		return tableData.dataTable;
	}
)

export const getIsHistoryTable = createSelector(
	selectDataGroupsTable,
	tableData => {
		return tableData.isHistory;
	}
)


export const getDataGroups = createSelector(
	state => state.rankings.dataGroups,
	(dataGroups) => {
		return dataGroups
	}
)




export const getLabelPeriods = createSelector(

	selectLocationSection,

	state => state.init.currentPeriodName,
	state => state.init.previousPeriodName,

	state => state.init.supermarketCStoreCurrentPeriodName,
	state => state.init.supermarketCStorePreviousPeriodName,

	(locationSection, currentPeriodName, previousPeriodName, supermarketCStoreCurrentPeriodName, supermarketCStorePreviousPeriodName) => {
		if (locationSection === 'restaurants')
			return {
				current: currentPeriodName,
				previous: previousPeriodName
			}
			return {
				current: supermarketCStoreCurrentPeriodName,
				previous: supermarketCStorePreviousPeriodName
			}
	}
)

