
import { 
	ACTIONS_RANKINGS, 
	RESPONDENT_TYPES 
} from '../utils/constants';

const initialState = {
	filterRespondentTypeId: RESPONDENT_TYPES.TOTAL_US,
	filterSegmentChainId: 0,
	dataGroups: null,
};
 
export default function reducerRankings(state = initialState, action) {
 
	switch (action.type) {
 
		case ACTIONS_RANKINGS.CREATE_GROUPS_STORE: {

			return {
				...state,
				dataGroups: action.payload.map( (group)=>{
					return {	
						methodName: group.methodName,
						aliasName: group.aliasName,
						isLoadProcess: false,
						isLoaded: false,
						tables: group.tables.filter( table => table.isPersonal !== true ).map( (table, index) => {
							return {
								dataTable: null,
								methodName: table.methodName,
								isHistory: false
							}
						})
					}	
				})
			};
		}

		case ACTIONS_RANKINGS.CLEAN_STORE: {
			return {
				...state,
				...{...initialState}
			};
		}


		case ACTIONS_RANKINGS.FETCH_DATA_BY_GROUP_START: {
			if (Array.isArray(state.dataGroups)) {
				const index = state.dataGroups.findIndex(group => (group.methodName === action.payload.methodName));

				return {
					...state,
					dataGroups: [
						...state.dataGroups.slice(0, index),
						...[{...state.dataGroups[index], isLoadProcess: true}],
						...state.dataGroups.slice(index + 1),
					]
				};
			}

			return state;
		}

		case ACTIONS_RANKINGS.FETCH_DATA_BY_GROUP_FINISH: {
			if (Array.isArray(state.dataGroups)) {
				const { tables, methodName } = action.payload;
				const index = state.dataGroups.findIndex(group => (group.methodName === methodName));

				return {
					...state,
					dataGroups: [
						...state.dataGroups.slice(0, index),
						...[{
							...state.dataGroups[index],
							tables: tables,
							isLoadProcess: false,
							isLoaded: true
						}],
						...state.dataGroups.slice(index + 1),
					]
				};
			}

			return state;
		}


		case ACTIONS_RANKINGS.UPDATE_GROUP_TABLE: {
			if (Array.isArray(state.dataGroups)) {
				const {
					payload: {
						groupMethodName,
						tableMethodName,
						options,
					}
				} = action;

				const indexGroup = state.dataGroups.findIndex(group => (group.methodName === groupMethodName));
				const indexTable = state.dataGroups[indexGroup].tables.findIndex(table => (table.methodName === tableMethodName));

				state.dataGroups[indexGroup].tables[indexTable] = {
					...state.dataGroups[indexGroup].tables[indexTable],
					...options
				}

				return {
					...state,
					dataGroups: state.dataGroups
				};
			}

			return state;
		}

		case ACTIONS_RANKINGS.UPDATE_HEADER_TABLE: {
			if (Array.isArray(state.dataGroups)) {
				const { groupMethodName, tableMethodName, columnsGroups } = action.payload;
				const indexGroup = state.dataGroups.findIndex(group => (group.methodName === groupMethodName));
				const indexTable = state.dataGroups[indexGroup].tables.findIndex(table => (table.methodName === tableMethodName));

				state.dataGroups[indexGroup].tables[indexTable].dataTable = {
					...state.dataGroups[indexGroup].tables[indexTable].dataTable,
					columnsGroups: columnsGroups
				}

				return {
					...state,
					dataGroups: state.dataGroups
				};
			}

			return state;
		}

		case ACTIONS_RANKINGS.CHANGE_RESPONDENT_TYPE: {
			if (Array.isArray(state.dataGroups)) {
				const dataGroups = state.dataGroups.map(group => {
					return {
						...group,
						isLoaded: false,
						tables: group.tables.map(table => {
							return {
								...table,
								dataTable: null
							}
						})
					}

				});

				return {
					...state,
					dataGroups: dataGroups,
					filterRespondentTypeId: action.payload
				};
			}

			return state
		}
		
		case ACTIONS_RANKINGS.CHANGE_SEGMENT_CHAIN: {  
			return {
				...state,
				filterSegmentChainId: action.payload
			};
		}

		default: {
			return state;
		}
	}
}