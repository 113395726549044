import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import MainHeader from '../../containers/MainHeader';
import ChainPopup from '../../containers/ChainPopup';
import ChainHeader from '../../containers/ChainHeader';
import Footer from '../../components/Footer';
import Preloader from '../../components/Preloader';

import ChainSections from '../../containers/ChainSections/ChainSections';

import {createGroupsData, clearChainStore} from '../../actions/chain';
import { getChainData } from './Chain.selectors.js';

import {
	ROOT_URL, 
	DOCUMENT_ROOT_TITLE,
	DOCUMENT_ROOT_TITLE_SEPARATOR,
	CHAIN_FOOD_TAB_ALIAS,
} from '../../utils/constants';


class Chain extends Component {
 
	componentWillMount(){
		this.actionCreateTableGroups(this.props);
	}

	componentWillUnmount() {
		this.props.clearChainStore();
	}
 
	componentWillReceiveProps(nextProps){

		const { 
			dataInit, 
			// tablesGroup, 
			match: {
				params
			},
		} = nextProps;
	

		// const extendViewChainId = JSON.parse(sessionStorage.getItem('EXTEND_VIEW_CHAIN_ID'));
	
		if (dataInit.ready === true && dataInit.success === false){

			if (window.generalConfig.isDatassential === true && params.paramId){
				this.props.history.push(`${ROOT_URL}${params[0]}`);
			} else {
				this.props.history.push(`${ROOT_URL}404`);
			}

			return;
		}


		if (dataInit.ready === true && dataInit.success === true){

			const selectedGroupList = dataInit.tableGroups.filter(group => group.aliasName === params.groupName) || null;
			
			document.title = `${DOCUMENT_ROOT_TITLE}${DOCUMENT_ROOT_TITLE_SEPARATOR}${dataInit.chain.name}`;
			
			if (selectedGroupList.length === 0){
				this.props.history.push(`${ROOT_URL}404`);
				return
			}

			if (params.groupName === CHAIN_FOOD_TAB_ALIAS && window.generalConfig.access.foodTab === false){
				this.props.history.push(`${ROOT_URL}404`);
				return
			}

		}

		this.actionCreateTableGroups(nextProps);
	}

	actionCreateTableGroups(props){

		const {dataInit, dataGroups} = props;

		if (dataInit.ready === true && dataGroups === null) {
			setTimeout( () => { 
				this.props.createGroupsData(dataInit.tableGroups);
			}, 10);
		}
	}

	render() {
		const {
			dataInit,
			match,
			history,
		} = this.props;

		return (
			<Fragment>
				<MainHeader 
					history={history}
					match={match}
				/>
				<div className="container container--content">
					<ChainHeader
						history={history}
						match={match}
					/>
					{dataInit.ready === false && (
						<Preloader />
					)}
					{dataInit.ready === true && dataInit.success === true && (
						<ChainSections 
							history={history}
							match={match}
						/>
					)}
					<Footer />
				</div>
				{dataInit.ready === true && dataInit.success === true && (
					<ChainPopup 
						history={history}
						match={match}
						chain={dataInit.chain} 
					/>
				)}
			</Fragment>
		);
	}


}
 
Chain.propTypes = {
	respondentTypeId: PropTypes.number,
	dataInit: PropTypes.object,
	dataGroups: PropTypes.array
};


const mapStateToProps = (state, props) => ({
	respondentTypeId: state.chain.filterRespondentTypeId,

	dataInit: getChainData(state, props),
	dataGroups: state.chain.dataGroups,
});

const mapDispatchToProps = dispatch => ({
	createGroupsData: (data) => dispatch(createGroupsData(data)),
	clearChainStore: () => dispatch(clearChainStore())
});

export default connect(mapStateToProps, mapDispatchToProps)(Chain);