import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Table from "../../../components/Table";

import {TABLE_COLUMNS} from '../../../utils/constants';
import {dbTableColumnInfo} from '../../../utils/presets';


class WordCloudTable extends Component{
	constructor (props) {
		super(props);

		this.state = {
			searchValue: ''
		}

		this.onSearch = this.onSearch.bind(this);
	}

	componentWillReceiveProps(){
		this.setState({
			searchValue: ''
		});
	}


	onSearch(event) {

 		const { minCountChar } = this.props,
 			valueText = event.target.value.trim().toLowerCase();

 		if (valueText.length >= minCountChar || valueText.length === 0)
			this.setState(prevState => ({
				searchValue: valueText,
			}));
	}


	render() {
		const {
		    keywordList,
		    table,
		} = this.props;

 
		const regex = new RegExp('\\b' + this.state.searchValue, 'gi'),
		    dataList = keywordList.filter(keyword => (keyword.text.toLowerCase().search(regex) !== -1)),
		    columnName = dbTableColumnInfo.get(TABLE_COLUMNS.KEYWORD_NAME),
		    columnWeight = dbTableColumnInfo.get(TABLE_COLUMNS.KEYWORD_PERCENT);

		return(
			<div className="cloud-table">

				<div className="cloud-search input-text-search-holder">
					<input className="cloud-search__input input-text-search" type="text" placeholder="Search keyword"  onChange={this.onSearch} />
					<svg className="icon icon-search" dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-search" />' }} />
					<div className="input-text-search-line"></div>
				</div>

				<div clipboard={table.methodName}>					
					<Table
						columnsGroups={[{
							label: '', 
							columns:[{
								isColumn: true,
								className: columnName.className,
								id: 1,
								isSorting: true,
								label: columnName.label + ` (${dataList.length})`,
								type: columnName.dataType,
								orderBy: null,
							},{
								isColumn: true,
								className: columnWeight.className,
								id: 2,
								isSorting: true,
								label: columnWeight.label,
								type: columnWeight.dataType,
								orderBy: 'desc',
							}]
						}]}
						rowsGroups={[{
							group: null,
							rows: dataList.map(  word => ([{value: word.text }, {value: word.weight }]) )
						}]}
						className="table-cloud"
					/>
				</div>

				{dataList.length === 0 && <div className="cloud-table__message">No match found</div>}
		
			</div>
		)
	} 
} 

WordCloudTable.propTypes = {
	keywordList: PropTypes.array,
	minCountChar: PropTypes.number,
	table: PropTypes.object,
};

WordCloudTable.defaultProps = {
	keywordList: [],
	minCountChar: 2
};


export default WordCloudTable;