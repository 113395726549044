
import { 
	TABLE_TYPES_COLUMN, 
	TABLE_COLUMNS
} from './constants';

export const HIGHCHARTS = {
	CHAIN_AREA_COMPARE: {
		chart: {
			renderTo: 'node',
			type: 'area'
		},
		plotOptions: {
			area: {
				stacking: null,
			},
			series: {
				lineWidth: 1
			}
		},
		title: {
			text: null
		},
		subtitle: {
			text: null
		},
		legend: {
			enabled: true,
 
			align: 'right',
			verticalAlign: 'top',
			floating: true,
			 
			itemStyle: {
				fontSize:'12px',
				fontWeight:'normal',
				textOverflow:'ellipsis',
			}

		},
		xAxis: {
			title: {
				text: null
			},
			categories: ['category','category',' category','category','category'],
			labels:{
				rotation: -60
			},
			endOnTick: true,
			startOnTick: true,
		},
		yAxis: {
			title: {
				text: null
			},
			labels: {
				formatter: function() {
					return `${this.value}%`
				}
			}
		},
		tooltip: {
			followPointer: false,
			backgroundColor: '#ffffff',
			borderColor: '#E0E0E0',
			borderRadius: 3,
			shadow: false,
			useHTML: true,
			formatter: function () {
				return `${this.point.name} ${this.point.y} %`;
			}
		},
		credits: {
			enabled: false
		},
		series: [{
			name: 'name 1',
			data: [{
					name: 'food name',
					y:10,				
				},{
					name: 'food name',
					y:20,			
				},{
					name: 'food name',
					y:30,			
				},{
					name: 'food name',
					y:40,			
				},{
					name: 'food name',
					y:50,			
				}
			],
			color: '#FF8F80',
			fillOpacity: 0.2,
			marker:{
				symbol:'circle',
				radius:2,
				enabled:false
			}
		},{
			name: 'name 2',
			data: [{
					name: 'food name',
					y:50,				
				},{
					name: 'food name',
					y:40,			
				},{
					name: 'food name',
					y:30,			
				},{
					name: 'food name',
					y:20,		
				},{
					name: 'food name',
					y:10,				
				}
			],
			color: '#22A8F3',
			fillOpacity: 0.2,
			marker:{
				symbol:'circle',
				radius:2,
				enabled:false
			}
		}]
	},

	CHAIN_AREA_SCATTER:{
		chart: {
			renderTo: 'chartBoxScatter',
			type: 'scatter',
			// height: 270,
			backgroundColor:'rgba(255, 255, 255, 0.0)',
			spacingLeft: 100
		},
		title: {
			text: null
		},
		subtitle: {
			text: null
		},
		xAxis: {
			title: {
				text: null
			},

			gridLineDashStyle: 'longdash',
			gridLineColor: '#ABA2A2',

			gridLineWidth: 0, // 1
			showLastLabel: true,
			lineWidth: 0,

			labels: {
				enabled: false // true
			},

			tickWidth: 0,

				// startOnTick: true,
				// endOnTick: true,
				// tickLength:0,

			tickPixelInterval: 999,
			max: 0.7,
			min: -0.1,
		},
		yAxis: {
			title: {
				text: null
			},

			gridLineDashStyle: 'longdash',
			gridLineColor: '#ABA2A2',
 
			gridLineWidth: 0, // 1
			showLastLabel: false,
			lineWidth: 0,

			labels: {
				enabled: false // true
			},

			tickWidth: 0,

				// startOnTick: true,
				// endOnTick: true,
				// tickLength:0,
	 
			tickPixelInterval:999,
			max: 1,
			min: 0
		},
		tooltip: {
			followPointer: false,
			backgroundColor: '#ffffff',
			borderColor: '#E0E0E0',
			borderRadius: 3,
			shadow: false,
			useHTML: true,
			formatter: function () {
				return `<div><b>${this.point.name}</b></div><div>Important: ${this.point.x}</div><div>Performance: ${this.point.y}</div>`;
			}
		},
		plotOptions: {
			turboThreshold: 0,
			scatter: {
				turboThreshold: 0,
				marker: {
					radius: 6,
					states: {
						hover: {
							enabled: true,
							lineColor: "#ffffff",
							radius: 6,
						}
					}
				},
			},
			series: {
				stickyTracking: false,
				turboThreshold: 0,
			}
		},
		series: [{
			color: '#22A8F3',
			data: [{
					name: 'food name',
					y:0,
					x:0.08    
				},{
					name: 'food name',
					y:1,
					x:0.43  
				}
			],
		}],
		legend: {
			enabled: false
		},
		credits: {
			enabled: false
		}
	},

	CHAIN_AREA_COMPETITIVE: {
		chart: {
			renderTo: 'chain-area-competitive',
			height: 450
		},
		title: {
			text: null
		},
		subtitle: {
			text: null
		},
		legend: {
			enabled: false,
		},
		tooltip: {
			headerFormat: '<span style="font-size: 13px">{point.key}</span><br />',
			pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: {point.y}<br/>',
			style: {
				color: '#3C4450'
			},
			shared: true,
			followPointer: false,
			backgroundColor: 'rgba(255,255,255,0.8)',
			borderColor: 'rgba(0,0,0,0.1)',
			borderRadius: 3,
			shadow: false,
		},
		xAxis: [{
			categories: ['Chain name', 'Chain name','Chain name'],
			labels:{
				rotation: -90
			},
			crosshair: true
		}],
		yAxis: [{
			gridLineDashStyle: 'dash', //longdash
			gridLineWidth: 1,
			gridLineColor: "#E8E8E8",

			// tickInterval: 20,
			 // tickAmount: 10,
			// min: 0, 
			// max: 100,
			// minRange: 3600*24*30*1000,

			// min: 0, 
			 // max: 100,
			//tickInterval: 10,

			title: {
				text: null,
				style: {
					color: '#3C4450'
				}
			},
			labels: {
				//format: '{value}%',
				formatter: label => {
					return (label.isFirst === true) ? label.value : (label.value + '%')
				},

				style: {
					color: '#3C4450',
				}
			}

		}, {
			min: 0, 
			// max: 30,

			 
			// tickInterval: 2,		
			// tickInterval: 5,
			// tickColor: 'red',
			// tickLength:10,

			gridLineWidth: 0,
			allowDecimals: false,

			title: {
				text: null,
				style: {
					color: '#3C4450'
				}
			},
			labels: {
				//format: '{value}',
				formatter: label => {
					return  label.value;
				},

				style: {
					color: '#3C4450',
				}
			},
			opposite: true
		}],
		series: [{
			name: 'Crossover rate',
			type: 'column',
			color: '#22A8F3',

			shadow: false,
			borderRadius: 8,
			pointWidth: 16,

			label:{
				enabled: false
			},
			yAxis: 0,
			tooltip: {
				valueSuffix: '%' // for tooltip
			},
			data: [12,16,18],

		}, {
			name: 'Common markets',
			type: 'spline',
			yAxis: 1,
			color: '#22C965',
			lineWidth: 2,
			label:{
				enabled: false
			},
			marker: {
				enabled: true
			},
			tooltip: {
				valueSuffix: '' // for tooltip
			},
			data: [18,16,12],
		}],
		responsive: {
			rules: [{
				condition: {
					// maxWidth: 200,
				},
				chartOptions: {
					legend: {
						floating: false,
						layout: 'horizontal',
						align: 'center',
						verticalAlign: 'bottom',
						x: 0,
						y: 0
					}
				}
			}]
		},
		credits: {
			enabled: false
		},
	}

};


export const dbTableColumnInfo = new Map([

	[
		TABLE_COLUMNS.MENU_CATEGORY,
		{
			label: 'Menu Category',
			className: 'cell--menu-category',
			dataType: TABLE_TYPES_COLUMN.STRING_INLINE
		}
	],


	[
		TABLE_COLUMNS.CHAIN_LINK,
		{
			label: '',
			className: 'cell--name-chain',
			dataType: TABLE_TYPES_COLUMN.CHAIN_LINK
		}
	],
	[
		TABLE_COLUMNS.CHAIN,
		{
			label: '',
			className: 'cell--name-chain',
			dataType: ''
		}
	],
	[
		TABLE_COLUMNS.BASE,
		{
			label: 'Base',
			className: 'cell--name-base',
			dataType: TABLE_TYPES_COLUMN.NUMBER_INT
		}
	],
	[
		TABLE_COLUMNS.EXCELLENT_VERY_GOOD,
		{
			label: '',
			className: 'cell--name-exellent',
			dataType: TABLE_TYPES_COLUMN.NUMBER_PERCENT
		}
	],
	[
		TABLE_COLUMNS.HISTORY_BASE,
		{
			label: 'Base current period',
			className: 'cell--name-base',
			dataType: TABLE_TYPES_COLUMN.NUMBER_INT
		}
	],
	[
		TABLE_COLUMNS.HISTORY_FROM,
		{
			label: 'From',
			className: 'cell--name-range-from',
			dataType: TABLE_TYPES_COLUMN.NUMBER_PERCENT
		}
	],
	[
		TABLE_COLUMNS.HISTORY_TO,
		{
			label: 'From',
			className: 'cell--name-range-to',
			dataType: TABLE_TYPES_COLUMN.NUMBER_PERCENT
		}
	],
	[
		TABLE_COLUMNS.HISTORY_DIFFERENCE,
		{
			label: 'Period vs. Year Ago',
			className: 'cell--name-difference',
			dataType: TABLE_TYPES_COLUMN.NUMBER_PERCENT_DIFFERENCE
		}
	],
	[
		TABLE_COLUMNS.DATA_PERCENTAGE,
		{
			label: '',
			className: 'cell--name-percent',
			dataType: TABLE_TYPES_COLUMN.NUMBER_PERCENT
		}
	],
	[
		TABLE_COLUMNS.KEYWORD_NAME,
		{
			label: 'Keyword',
			className: 'cell--word',
			dataType: TABLE_TYPES_COLUMN.STRING_INLINE
		}
	],
	[
		TABLE_COLUMNS.KEYWORD_PERCENT,
		{
			label: 'Percent',
			className: 'cell--weight',
			dataType: TABLE_TYPES_COLUMN.NUMBER_PERCENT_KEYWORD_SIGN
		}
	],
	[
		TABLE_COLUMNS.NPS,
		{
			label: '',
			className: 'cell--nps',
			dataType: TABLE_TYPES_COLUMN.NPS_VALUE
		}
	],

]);
