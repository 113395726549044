import React, { Component, Fragment  } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
	ROOT_URL,
	DOCUMENT_ROOT_TITLE,
	DOCUMENT_ROOT_TITLE_SEPARATOR,
	DOCUMENT_RANKINGS_TITLE,
} from '../../utils/constants';

import MainHeader from '../../containers/MainHeader';
import Ranking from '../../containers/Ranking';
import RankingNavigation from '../../containers/RankingNavigation';
import Footer from '../../components/Footer';
import Preloader from '../../components/Preloader';

import { clearGroupsDataStore, createGroupsDataStore } from '../../actions/rankings';
import { getChainListBySegmentSelector, groupsTableSelector, getDataGroups } from './Rankings.selectors';

const RankingSections = props => {

	const { 
		groupTables, 
		chainList,
		history,
		match,
	} = props;

	return(
		<Fragment>
			{groupTables.tables
				.filter( (table) => table.isPersonal !== true )
					.map((table, index) => {
						const { tables, ...group } = groupTables;
						
						return (
							<Ranking 
								key={table.methodName}
								table={table} 
								group={group} 
								chainList={chainList} 
								history={history}
								match={match}
							/>
						)
					})}
		</Fragment>
	)
}


class Rankings extends Component {

	constructor(props){
		super(props);
		this.main = this.main.bind(this);
	}

	componentWillMount(){
		this.main(this.props);
	}

 	componentWillUnmount() {
		this.props.clearGroupsDataStore();
	}

	componentWillReceiveProps(nextProps){
		this.main(nextProps);
	}

	main(props){
		const {initGroups, dataGroups} = props;
 
		if (initGroups.ready && initGroups.success === false){
			this.props.history.push(`${ROOT_URL}404`);
		} else {
			if (initGroups.ready && dataGroups === null){
				this.props.createGroupsDataStore(initGroups.tableGroups);
			}
		}

		document.title = `${DOCUMENT_ROOT_TITLE}${DOCUMENT_ROOT_TITLE_SEPARATOR}${initGroups.clusterName} - ${DOCUMENT_RANKINGS_TITLE}`;
	}
 
	render() {
		const {
		    initGroups: {
		        groupTables
		    },
		    chainList,
		    dataGroups,
			match,
			history,
		} = this.props;
 
		return (
			<Fragment>
				<MainHeader 
					history={history}
					match={match}
				/>
				<RankingNavigation 
					history={history}
					match={match}
				/>
				<div className="container container--content">
					{(dataGroups === null) ? (
						<Preloader />
					) : (
						<RankingSections 
							groupTables={groupTables} 
							chainList={chainList}
							history={history}
							match={match}
						/>
					)}
					<Footer/>
				</div>
			</Fragment>
		);
	}
}

Rankings.propTypes = {
	chainList: PropTypes.array,
	dataGroups: PropTypes.array,
	initGroups: PropTypes.object,
};

Rankings.defaultProps = {
	chainList: [],
};

const mapStateToProps = (state, props) => ({
	chainList: getChainListBySegmentSelector(state, props),
	dataGroups: getDataGroups(state),
	initGroups: groupsTableSelector(state, props),
});

const mapDispatchToProps = dispatch => ({
	createGroupsDataStore: (data) => dispatch(createGroupsDataStore(data)),
	clearGroupsDataStore: () => dispatch(clearGroupsDataStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rankings);