import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SectionWrapper from '../../../containers/SectionWrapper';

import { WORD_COLORS, WORD_FONT_WEIGHT_SIZE } from './constants';
import WordCloud from './wordcloud';
import WordCloudTable from './WordCloud.Table';
import { getDataTable } from '../../../route/Chain/Chain.selectors';

import {borowserDetect} from '../../../utils/helpers';

class WordCloudBox extends Component {

	constructor(props){
		super(props);
		this.buildWordcloud = this.buildWordcloud.bind(this);
		this.timerMouseOver = null;
	}

	componentDidMount() {
		this.props.data && this.buildWordcloud();
	}
 
	componentDidUpdate(){
		this.props.data && this.buildWordcloud();
	}

	buildWordcloud(){

		this.nodeCloudCanvas.addEventListener('wordcloudstop', (w) => {
			
			if (this.nodeCloudSvg){
				const nodeTextList =  this.nodeCloudSvg.querySelectorAll('text') || [];
				const cloudBBox = Array.from(nodeTextList).reduce( (side, node, index) => {

					const nodeDimension = node.getBBox();
					const rightSide = node.positionX + nodeDimension.width;
					const bottomSide = node.positionY + (nodeDimension.height / 2);
					const topSide = node.positionY - (nodeDimension.height / 2);

					if (index === 0){
						side.top = topSide;
						side.left = node.positionX;
						side.right = rightSide;
						side.bottom = bottomSide;
					} else {
						side = {
							top: (side.top > topSide) ?  topSide : side.top,
							bottom: (side.bottom < bottomSide) ? bottomSide : side.bottom,
							left: (side.left > node.positionX) ? node.positionX : side.left,
							right: (side.right < rightSide) ? rightSide : side.right,
						}
					}

					return side;

				}, {
					top:0,
					left:0,
					right:0,
					bottom:0,
				});

				this.nodeCloudSvg.setAttribute(`viewBox`, `${cloudBBox.left} ${cloudBBox.top} ${cloudBBox.right - cloudBBox.left} ${cloudBBox.bottom - cloudBBox.top} `);

				if (borowserDetect().isIE() === false && borowserDetect().isEdge() === false){
					this.nodeCloud.removeEventListener('mouseover', this.onMouseOver);
					this.nodeCloud.addEventListener('mouseover', this.onMouseOver);
				}				
			}
 
		});

 		const nodeGroupList = this.nodeCloudSvg.querySelectorAll('g') || []; 
		Array.from(nodeGroupList).forEach(node => {
			node.remove();
		});

        WordCloud(
            this.nodeCloudCanvas, { 
                rotateRatio: 0,
                fontFamily: "Nunito, sans-serif",
                gridSize: "8",
                shape: 'cardioid',
                origin:[750,750],
                shuffle: true,
                nodeSvg: this.nodeCloudSvg,

				// gridSize: Math.round(16 * $('#canvas').width() / 1024),
				// weightFactor: function (size) {
				//  return Math.pow(size, 2.3) * $('#canvas').width() / 1024;
				// },
				// color: function (word, weight) {
				//  return (weight === 12) ? '#f02222' : '#c09292';
				// },

                list: ((words) => {

                    const partCount = Math.ceil(words.length / WORD_COLORS.length);
                    let indexColorPointer = 1;

                    return words.map( (word, index) => {

                          if (index >= (indexColorPointer * partCount))
                            indexColorPointer++;

                            return {
                                word: word.text,
                                weight: WORD_FONT_WEIGHT_SIZE[word.weight],
                                attributes: {
                                    color: WORD_COLORS[indexColorPointer-1]
                                }
                            }
                        })

                })( this.props.data )
        });      
 
	}

	focusWordTable = (word) => {
		const nodeScrollbar = this.nodeCloud.querySelector('[data-scrollbar="true"]'),
			nodeListTR = this.nodeCloud.querySelectorAll('tbody tr') || [];
 
 		const nodeTR = Array.from(nodeListTR).find( nodeTR => {
			const [nodeTD] = nodeTR.childNodes;
			return nodeTD.innerText.toLowerCase() === word.toLowerCase()
		}) || null;

		if (nodeTR){
			nodeTR.classList.add('highlight');
			nodeScrollbar.smoothScroll.scrollTo(0,nodeTR.offsetTop,500);
		}
	}

	focusWordCloud = (word) => {
		const nodeListText = this.nodeCloud.querySelectorAll('text'),
			nodeFilter = this.nodeCloud.querySelector('feFlood');

 		const nodeText = Array.from(nodeListText).find( nodeText => {
			return nodeText.innerHTML.toLowerCase() === word.toLowerCase()
		}) || null;
 
		if (nodeText){
			const textColor = nodeText.getAttribute('fill');
			nodeFilter.setAttribute('flood-color', textColor); 
			nodeText.setAttribute('filter', 'url(#wordcloud-filter)'); 
			nodeText.setAttribute('fill', '#ffffff'); 
			nodeText.setAttribute('fill-default', textColor);
		}
	};

	focusWord = (event) => {
 		const nodesText = this.nodeCloud.querySelectorAll('text'),
		nodeListTR = this.nodeCloud.querySelectorAll('tbody tr'),
		nodeTR = event.target.closest('tr');

		Array.from(nodesText).forEach( (node) => {
			const fillDefault = node.getAttribute('fill-default');
			if (fillDefault)
				node.setAttribute('fill', fillDefault);
				node.removeAttribute('filter');
		});

 		Array.from(nodeListTR).forEach((item) => {
 			item.classList.remove('highlight');
 		});

	 		if (event.target.nodeName === 'text')
	 			this.focusWordCloud(event.target.innerHTML);

	 		if (nodeTR){
	 			const [nodeTD] = nodeTR.childNodes;
	  			this.focusWordCloud(nodeTD.innerText);
	 		}

			if (event.target.nodeName === 'text')
				this.focusWordTable(event.target.innerHTML);
	};

	onMouseOver = (event) => {
		clearTimeout(this.timerMouseOver);

		this.timerMouseOver = setTimeout(() => {
			this.focusWord(event);
		}, 100);
	}

	render() {
 		const {table, data} = this.props;
		return (
			<SectionWrapper config={{...table, 
				isShowData: (data === null) ? false : true,
 
 				copyData: (data === null) ? null : data.reduce( (list, item) => { 
					list.push([item.text, `${item.weight}%`]);
					return list;
				}, [[table.name.toUpperCase()]]),

			}}>
				<div className="section-content--container" ref={node => this.nodeCloud = node}>
					<div className="cloud" >
						<div className="cloud-screen"  clipboard={table.methodName}>
							<svg className="icon icon-cloud" viewBox="0 0 54 32" dangerouslySetInnerHTML={{__html: '<path fill="#ebf0f2" d="M24.255 32h-13.733c-5.802 0-10.522-4.324-10.522-9.641 0-3.692 2.624-7.525 6.241-9.114l0.55-0.241v-0.56c0-0.113 0.008-0.227 0.015-0.343l0.009-0.142-0.012-0.187c-0.006-0.072-0.012-0.144-0.012-0.217 0-3.43 3.047-6.222 6.791-6.222 0.3 0 0.596 0.024 0.89 0.060 0.076 0.009 0.151 0.020 0.226 0.032 0.259 0.039 0.514 0.091 0.766 0.157 0.034 0.009 0.069 0.015 0.103 0.024 0.277 0.077 0.546 0.175 0.81 0.285 0.069 0.028 0.136 0.060 0.204 0.090 0.233 0.106 0.461 0.221 0.681 0.352 1.87 1.109 3.112 3.034 3.112 5.222 0 0.492 0.434 0.889 0.97 0.889s0.97-0.397 0.97-0.889c0-2.448-1.209-4.639-3.105-6.108 2.782-3.333 7.362-5.448 11.919-5.448 7.514 0 13.755 5.453 14.406 12.345-0.992-0.064-2.476-0.097-3.961 0.11-0.53 0.074-0.894 0.527-0.813 1.012 0.073 0.44 0.486 0.756 0.958 0.756 0.048 0 0.098-0.004 0.146-0.011 2.161-0.3 4.413-0.019 4.544-0.002 4.517 0.787 7.923 4.599 7.923 8.876 0 4.915-4.365 8.914-9.729 8.914h-20.347z"></path>' }} />
							<div className="cloud-screen-svg">
								<svg width="1000" height="550"  ref={node => this.nodeCloudSvg = node}>
									<defs>
										<filter x="0" y="0" width="1" height="1" id="wordcloud-filter">
											<feFlood floodColor="yellow"/>
											<feComposite in="SourceGraphic"/>
										</filter>
									</defs>
								</svg>
							</div>
							<canvas width="1500" height="1500" className="cloud-screen-canvas" ref={node => this.nodeCloudCanvas = node}></canvas>
						</div>	

 						{ data !== null && <WordCloudTable 
 							keywordList={data} 
 							table={table}
 						/>}
					</div>
				</div>
			</SectionWrapper>
		);
	}
}
 
WordCloudBox.propTypes = {
	data: PropTypes.array,
	dataInit: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
	data: getDataTable(state, props),
});

export default connect(mapStateToProps)(WordCloudBox);