
import httpClient from '../utils/httpClient';

import {
	mapInitialDataChains,
	mapInitialDataPresets,
	mapInitialDataTableGroups, 
	mapInitialDataRespondentTypes,
	filterExtendViewChains,
} from '../utils/helpers';

import {ACTIONS_MAIN, ACTIONS_PRESET} from '../utils/constants';

import Notification from '../utils/notification.js';


export const getInitialData = () => (dispatch, getState) => {

	const httpInitial = new httpClient( `${process.env.REACT_APP_API}InitialData/GetInitialData/`);
		
	httpInitial.request.then(response => {

		if (typeof response === 'object'){

			const avaliableChainViews = (response.avaliableChainViews) ? response.avaliableChainViews : [];

			const sections = {
				restaurants: {
					chains: (window.generalConfig.access.isDatassential === false) ? response.restaurantChains : filterExtendViewChains({
						chains: mapInitialDataChains(response.restaurantChains),
						avaliableChainViews,
					}),
					tableGroups: (response.restaurantTableGroups) ?  mapInitialDataTableGroups(response.restaurantTableGroups) : []
				},
				cstores:{
					chains: (window.generalConfig.access.isDatassential === false) ? response.cStoreChains : filterExtendViewChains({
						chains: mapInitialDataChains(response.cStoreChains),
						avaliableChainViews,
					}),
					tableGroups: (response.cStoreTableGroups) ? mapInitialDataTableGroups(response.cStoreTableGroups) : [],
				},
				supermarkets:{
					chains: (window.generalConfig.access.isDatassential === false) ? response.supermarketChains : filterExtendViewChains({
						chains: mapInitialDataChains(response.supermarketChains),
						avaliableChainViews,
					}),
					tableGroups: (response.supermarketTableGroups) ? mapInitialDataTableGroups(response.supermarketTableGroups) : [],
				}
			};

			dispatch({ 
				type: ACTIONS_PRESET.STORE_DATA, 
				payload: {
					restaurants: mapInitialDataPresets(response.restaurantPresets, sections.restaurants.chains),
					cstores: mapInitialDataPresets(response.cStorePresets, sections.cstores.chains),
					supermarkets: mapInitialDataPresets(response.supermarketPresets, sections.supermarkets.chains),
				} 
			});	

			dispatch({ 
				type: ACTIONS_MAIN.FETCH_INIT_DATA, 
				payload: {
					sections: sections,

					respondentTypes: (response.respondentTypes) ? mapInitialDataRespondentTypes(response.respondentTypes) : [],

					foodTabChains: response.foodTabChains,
					foodTabRespondentTypes: (response.foodTabRespondentTypes) ? mapInitialDataRespondentTypes(response.foodTabRespondentTypes) : [],

					currentPeriodName: (response.currentPeriodName) ? response.currentPeriodName : '',
					previousPeriodName: (response.previousPeriodName) ? response.previousPeriodName : '',

					supermarketCStoreCurrentPeriodName: (response.supermarketCStoreCurrentPeriodName) ? response.supermarketCStoreCurrentPeriodName	 : '',
					supermarketCStorePreviousPeriodName: (response.supermarketCStorePreviousPeriodName) ? response.supermarketCStorePreviousPeriodName : '',
					avaliableChainViews: avaliableChainViews,
					chainStateDmaRegionViewSettings: (response.chainStateDmaRegionViewSettings) ? response.chainStateDmaRegionViewSettings : '',
				}
			});
				
		}


		}).catch(response => {

		const notification = new Notification({
			timeout: 10000,
			itemClassName: `notification-item notification-item--warning`
		});

		if (response.status === 401){
			notification.add(`Unauthorized`);

		} else {
			notification.add(`Network request failed`);
		}
		
	});
}
