import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
 
import DropdownToggle from "../../../components/DropdownToggle";
import Table from "../../../components/Table";
 
import {
	TABLE_COLUMNS, 
	RESPONDENT_TYPES
} from '../../../utils/constants';

import {dbTableColumnInfo} from '../../../utils/presets';
import Notification from '../../../utils/notification';

// import { TOOLTIP_TYPES } from '../../../utils/constants';

const VISIBLE_COLUMN_COUNT = 7;
const FIXED_HEADER_TOP_POSITION  = 46;

const getInitState = (respondentTypesList) => {
	
 	let countVisibleColumn = 0;

	return [{
			label: '',
			columns:[{
				label: '',
				isAllowToggleVisible: false,
				id: TABLE_COLUMNS.MENU_CATEGORY,
				name: TABLE_COLUMNS.MENU_CATEGORY,
				isVisible: true,
				isColumn: false,
				orderBy: 'asc',
			}],
		},
		...respondentTypesList.map(group => {

			return {
				label: group.name,
				columns: group.items
					.filter(respondent => respondent.id !== RESPONDENT_TYPES.TOTAL_US)
					.map(respondent => {

						countVisibleColumn++

						return {
							label: respondent.name,
							isAllowToggleVisible: true,
							id: respondent.id,
							name: TABLE_COLUMNS.DATA_PERCENTAGE,
							isVisible: (countVisibleColumn <= VISIBLE_COLUMN_COUNT) ? true : false,
							isColumn: true,
							orderBy: null,
						}
					})
			}
		}),

	].filter( columnsGroup => columnsGroup.label === null || columnsGroup.columns.length > 0 );
}




class Demos extends Component {

	constructor(props){
		super(props);

		this.handlerToggleColumn = this.handlerToggleColumn.bind(this);
		this.onSortColumn = this.onSortColumn.bind(this);
		this.buildTable = this.buildTable.bind(this);


		const columnsGroups = getInitState(this.props.respondentTypesList);
		this.state = {
			columnsGroups
		} 

		this.notification = new Notification();
	}

	handlerToggleColumn(сurrentСolumn = null){

		this.setState(prevState => {

			const columnsGroups = prevState.columnsGroups.map(group => {
				return {
					...group,
					columns: group.columns.map(column => {

						const isVisible = (сurrentСolumn && column.id === сurrentСolumn.id) ? сurrentСolumn.isSelected : column.isVisible;

						return {
							...column,
							isVisible: (сurrentСolumn == null && column.isAllowToggleVisible === true) ? false : isVisible,
						}
					})
				}
			});

			return {
				...prevState,
				columnsGroups: [
					...columnsGroups
				]
			}
 
		});
	}

	onSortColumn(column){
		// console.log('test', column);
	}

	
	buildTable({columnsGroups, isFixedHeader}) {
 
		const {
			data:{
				categories,
				consumerList,
				// baseList,
			},
		} = this.props;

		const visibleChainColumnList = columnsGroups.reduce( (group,item) => {
			const columns = item.columns.filter(column => column.isVisible === true && column.name === TABLE_COLUMNS.DATA_PERCENTAGE);
			return [
			   ...group,
			   ...columns
			]
		}, []);

		return (
			<Table
				className="table-menu-demo"
				isScrollBar={false}
				fixHeaderPositionTop={(isFixedHeader === true) ? FIXED_HEADER_TOP_POSITION : null}
				columnsGroups={ columnsGroups.map( (group, indexGroup) => {

					const classNameGroup  = (indexGroup % 2) ? "cell--theme-b" : ""

					const columns = group.columns
						.filter( column => column.isVisible === true )
						.map((column) => {

							const columnInfo = dbTableColumnInfo.get(column.name);
					
							return {
								id: column.id,
								label:  (columnInfo.label) ? columnInfo.label : column.label,
								type: columnInfo.dataType,
								className: `${columnInfo.className} ${classNameGroup}`,
								isSorting: true,
								orderBy: column.orderBy,
								isColumn: column.isColumn,
							}
						});


					if (columns.length === 1){
						const [column] = columns;

						if (column.isColumn === false)
							return{
								...column,
								isColumn: true,
								columns:[]
							}	
					}

					return {
						className: classNameGroup,
						label: group.label,
						columns: columns,
						isColumn: false
					}

				}).filter( group => group.isColumn === true || group.columns.length > 0 )}
				rowsGroups={categories.map(category => {
					return {
						group: {
							label: category.name.toUpperCase(),
							colSpan: visibleChainColumnList.length,
						},
						rows: category.items.map((item) => {

							const answer = consumerList.find(answer => answer.answerId === item.answerId) || null;

							const columns = visibleChainColumnList.map(column => {

								const chainAnswer =  (answer) ? 
									(answer.data.find(answer => answer.respondentTypeId === column.id) || null) : null;


								// const baseSize = baseList.find(base => base.respondentTypeId === column.id) || null;
								
								return{
									value: (chainAnswer === null) ? null : chainAnswer.value,

									/*
									...((baseSize && chainAnswer !== null) && {
										tooltip: {
											value: baseSize.value,
											type: TOOLTIP_TYPES.BASE_SIZE
										},
									}),
									*/
								}
							});

							return [{
									value: item.text,
								},
								...columns
							]

						}),
					}
				})}
				onSortColumn={this.onSortColumn}
				onRenderHeaderCell={column => {

					if ( [12, 13, 14].includes(column.id) === true)
						return (
							<Fragment>
								{column.label}
								<div className="tooltip tooltip-frequency" clipboard-disable="true">
									<div className="tooltip-frequency__header">How often do you visit?</div>
									<span className="tooltip-frequency__abbreviation">H</span> - At least every 2 to 3 weeks<br />
									<span className="tooltip-frequency__abbreviation">M</span> - Monthly to once every 2 to 3 months<br />
									<span className="tooltip-frequency__abbreviation">L</span> - Every six months or longer<br />
								</div>
							</Fragment>

						)

					return column.label;
				}}
			/>
		)
	}
 
	render() {

		// const {
		// 	// table,
		// 	data:{
		// 		// categories,
		// 		// consumerList,
		// 		// baseList,
		// 	},
		// } = this.props;

		const {
			columnsGroups
		}  = this.state;

		const visibleChainColumnList = columnsGroups.reduce( (group,item) => {
			const columns = item.columns.filter(column => column.isVisible === true && column.name === TABLE_COLUMNS.DATA_PERCENTAGE);
			return [
			   ...group,
			   ...columns
			]
		}, []);

		return (
			<Fragment>
				<DropdownToggle
					className="dropdown-toggle--side dropdown-toggle--single"
					placeholder=""
					isMultiselect={true}
					options={columnsGroups.map(item => {

						const items = item.columns
							.filter( column => column.isAllowToggleVisible === true )
							.map( column => {
								return{
									id: column.id,
									name: column.label,
									isSelected: column.isVisible
								}
							})

						return {
							name: item.label,
							items: items
						}

					}).filter( group => group.items.length > 0 )}
					onClickItem={(column) => {

						if (column.isSelected === true && visibleChainColumnList.length >= VISIBLE_COLUMN_COUNT){
							this.notification.add(`Table displays ${VISIBLE_COLUMN_COUNT} columns max.`);
						} else {
							this.handlerToggleColumn(column);
						}
					}}
					onClear={() => {
						this.handlerToggleColumn();
					}}

					clipboard-disable="true"
				/>

				<div clipboard-disable="true">
					{this.buildTable({
						columnsGroups,
						isFixedHeader: true,
					})}
				</div>

				<span className="hidden-layer">
					{this.buildTable({
						columnsGroups: columnsGroups.map( group => {
							const columns = group.columns.map(column => ({
								...column,
								isVisible:true,
							}));

							return {
								...group,
								columns
							}

						}), 
						isFixedHeader: false,
					})}
				</span>


			</Fragment>
		);
	}
}

Demos.propTypes = {
	table: PropTypes.object,
	data: PropTypes.object,
	respondentTypesList: PropTypes.array,
};

const mapStateToProps = (state, props) => ({
	respondentTypesList: state.init.foodTabRespondentTypes,
});
 
export default connect(mapStateToProps)(Demos);