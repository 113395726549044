export const FEATURE_FLAG_IS_PLATFORM_ENABLED = 'enable platform';

export const DOCUMENT_ROOT_TITLE = 'Brand Performance by Datassential';
export const DOCUMENT_ROOT_TITLE_SEPARATOR = ' | ';
export const DOCUMENT_RANKINGS_TITLE = 'Rankings';
export const ROOT_URL = '/'; 
export const ACCESS_NO_PERMISSION = 'NO_PERMISSION';

export const EXTEND_VIEW_CHAIN_LIST = [{
	chainId: 10210,
	caption: 'In-n-O',
},{
	chainId: 10182,
	caption: 'LJS',
}];


export const HOST_ENVIRONMENT = [
	'localhost',
	'brand-performance.qa.datassential.com'
];


export const CUSTOM_PRESET_TYPE_ID = 3;
export const SPECIAL_PRESET_TYPE_ID = 4;
 
export const TYPE_CHAIN = {
	RESTAURANT: 1,
	CSTORE: 3,
	SUPERMARKET: 2,
}

export const PRESET_TYPE_LIST = {
	LSR: {
		id:1,
		segmentGroup:1, 
		name: 'LSR',
		isCustom: false,
	},
	FSR: {
		id: 2,
		segmentGroup: 2, 
		name: 'FSR',
		isCustom: false,
	},
	MIX_LSR_FSR:{
		id: 4,
		segmentGroup: 3, 
		name: 'LSR/FSR',
		isCustom: false,
	},
	CSTORES:{
		id: 5,
		segmentGroup: 4, 
		name: 'C-stores',
		isCustom: false,
	},
	GROCERY: {
		id: 6,
		segmentGroup: 4, 
		name: 'Grocery',
		isCustom: false,
	},
	CUSTOM: {
		id: 7,
		segmentGroup:null, 
		name: 'Custom',
		isCustom: true,
	},
}

export const SECTIONS = [{
	aliasName: 'restaurants',
	captionName: 'Restaurants',
	systemName: 'restaurants',
	id:1,
	isChainSegmentation: true,
	excludeListRespondentTypeId:[],
	excludeListRespondentTypeNames:[],

	chainComparePresetTypeList: [
		PRESET_TYPE_LIST.LSR,
		PRESET_TYPE_LIST.FSR,
		PRESET_TYPE_LIST.CUSTOM,
	].map(item => {
		return {
			...item,
			section: 'restaurants'
		}
	}),

	chainReportPresetTypeList: [
		PRESET_TYPE_LIST.LSR,
		PRESET_TYPE_LIST.FSR,
		PRESET_TYPE_LIST.CUSTOM,
	].map(item => {
		return {
			...item,
			section: 'restaurants'
		}
	}),

	defaultPresetTypeId: 1,
	hasChainReportTabs: false,

},{
	aliasName: 'c-stores',
	captionName: 'C-stores',
	systemName: 'cstores',
	id:3,
	isChainSegmentation: false,
	excludeListRespondentTypeId:[3],
	excludeListRespondentTypeNames:['Gen Z'],

	chainComparePresetTypeList: [
		PRESET_TYPE_LIST.CSTORES,
		PRESET_TYPE_LIST.CUSTOM,
	].map(item => {
		return {
			...item,
			section: 'cstores'
		}
	}),

	chainReportPresetTypeList: [{
		...PRESET_TYPE_LIST.LSR,
		section: 'restaurants'
	},{
		...PRESET_TYPE_LIST.CSTORES,
		section: 'cstores'
	},{
		...PRESET_TYPE_LIST.CUSTOM,
		section: 'cstores'
	}],

	chainXlsReportPresetTypeList: [{
		...PRESET_TYPE_LIST.CSTORES,
		section: 'cstores'
	},{
		...PRESET_TYPE_LIST.CUSTOM,
		section: 'cstores'
	}],

	defaultPresetTypeId: 5,
	hasChainReportTabs: true,


},{
	aliasName: 'grocery',
	captionName: 'Grocery',
	systemName: 'supermarkets',
	id:2,
	isChainSegmentation: false,
	excludeListRespondentTypeId:[3],
	excludeListRespondentTypeNames:['Gen Z'],

	chainComparePresetTypeList: [
		PRESET_TYPE_LIST.GROCERY,
		PRESET_TYPE_LIST.CUSTOM,
	].map(item => {
		return {
			...item,
			section: 'supermarkets'
		}
	}),

	chainReportPresetTypeList: [
		PRESET_TYPE_LIST.GROCERY,
		PRESET_TYPE_LIST.CUSTOM,
	].map(item => {
		return {
			...item,
			section: 'supermarkets'
		}
	}),

	defaultPresetTypeId: 6,
	hasChainReportTabs: false,

}];

export const ORDER_FREQUENCY_LIST = [{
	name:'frequently',
	id: 1,
},{
	name:'occasionally',
	id: 2
},{
	name:'frequently and occasionally',
	id: 3
},{
	name:'rarely',
	id: 4,
}];					

export const RESPONDENT_TYPES = {
	TOTAL_US: 1, 
}

export const CHAIN_FOOD_TAB_ALIAS = 'food';


export const TOOLTIP_TYPES = {
	BASE_SIZE: 'BASE_SIZE',
	RESPONSE_DISTRIBUTION: 'RESPONSE_DISTRIBUTION',
};

export const BUTTONS_DOWNLOAD = {
	REPORT_PPT_BIG: 1, 
	REPORT_PPT_SMALL: 0, 
	REPORT_XLS: 3, 
}

export const CHAIN_COMPARE_TYPES = {
	GROUP: 'GROUP',
	BRAND: 'BRAND',
	HISTORY: 'HISTORY'
};

export const PRESETS_GROUP_ACTIONS = {
	VIEW: 'VIEW',
	EDIT: 'EDIT',
	CREATE: 'CREATE',
	REMOVE: 'REMOVE',
	SELECT: 'SELECT',
	CANCEL: 'CANCEL',
	SAVE: 'SAVE',
};

export const RANKING_TABLE = {
	MAX_COUNT_COULUMNS_VISIBLE: 8,
	MAX_COUNT_COULUMNS_VISIBLE_TOTAL: 10,
};

export const TABLE_TYPES_COLUMN = {
	STRING_INLINE: 'stringInline',
	NUMBER_INT: 'numberInt',
	NUMBER_PERCENT: 'numberPercent',
	NUMBER_PERCENT_DIFFERENCE: 'numberPercentDifference',
	NUMBER_PERCENT_KEYWORD_SIGN: 'numberPercentKeywordSign',
	CHAIN_LINK: 'chainLink',

	CURENCY_NUMBER: 'CURENCY_NUMBER',

	NPS: 'NPS',
	NPS_VALUE: 'NPS_VALUE',
};
 
export const TABLE_COLUMNS = {
	CHAIN: 'CHAIN',
	CHAIN_LINK: 'CHAIN_LINK',
	BASE: 'BASE',

	MENU_CATEGORY: 'MENU_CATEGORY',

	NPS: 'NPS',
	EXCELLENT_VERY_GOOD: 'EXCELLENT_VERY_GOOD',


	HISTORY_BASE: 'HISTORY_BASE',
	HISTORY_FROM: 'HISTORY_FROM',
	HISTORY_TO: 'HISTORY_TO',
	HISTORY_DIFFERENCE: 'HISTORY_DIFFERENCE',
	DATA_DIFFERENCE: 'HISTORY_DIFFERENCE',
	DATA_PERCENTAGE: 'DATA_PERCENTAGE',

	// WordCloud
	KEYWORD_NAME: 'KEYWORD_NAME',
	KEYWORD_PERCENT: 'KEYWORD_PERCENT',
};

 
export const ACTIONS_MAIN = {
	FETCH_INIT_DATA: 'FETCH_INIT_DATA',
	FETCH_CONFIG_DATA: 'FETCH_CONFIG_DATA',
};

export const ACTIONS_PRESET = {
	STORE_DATA: 'STORE_DATA',
	LOADING: 'LOADING',
	UPDATE_PRESET: 'UPDATE_PRESET',
	CREATE_PRESET: 'CREATE_PRESET',
	REMOVE: 'REMOVE'
};

export const ACTIONS_RANKINGS = {
	CREATE_GROUPS_STORE: 'RANKINGS_CREATE_GROUPS_STORE',
	CLEAN_STORE: 'RANKINGS_CLEAN_STORE',

	FETCH_DATA_BY_GROUP_START: 'RANKINGS_FETCH_DATA_BY_GROUP_START',
	FETCH_DATA_BY_GROUP_FINISH: 'RANKINGS_FETCH_DATA_BY_GROUP_FINISH',

	UPDATE_GROUP_TABLE: 'RANKINGS_UPDATE_GROUP_TABLE',

	UPDATE_HEADER_TABLE: 'RANKINGS_UPDATE_HEADER_TABLE',

	CHANGE_RESPONDENT_TYPE: 'RANKINGS_CHANGE_RESPONDENT_TYPE',
	CHANGE_SEGMENT_CHAIN: 'CHANGE_SEGMENT_CHAIN'
};

export const ACTIONS_CHAIN = {
	FETCH_CHAIN_HEADER_START: 'CHAIN_FETCH_HEADER_DATA_START',
	FETCH_CHAIN_HEADER_FINISH: 'CHAIN_FETCH_HEADER_DATA_FINISH',

	CREATE_GROUPS_STORE: 'CHAIN_CREATE_GROUPS_STORE',
	
	FETCH_BY_GROUP_DATA_START: 'CHAIN_FETCH_BY_GROUP_DATA_START',
	FETCH_BY_GROUP_DATA_FINISH: 'CHAIN_FETCH_BY_GROUP_DATA_FINISH',

	UPDATE_GROUP_TABLE: 'CHAIN_UPDATE_GROUP_TABLE',
	
	CHANGE_RESPONDENT_TYPE: 'CHAIN_CHANGE_RESPONDENT_TYPE',
	CHANGE_FILTER_COMPARE_TO: 'CHAIN_CHANGE_FILTER_COMPARE_TO',
	CHANGE_REPORT_COMPARE_TO: 'CHAIN_CHANGE_REPORT_COMPARE_TO',
	SET_DEFAULT_PRESET: 'CHAIN_SET_DEFAULT_PRESET',

	TOGGLE_OPEN_POPUP: 'TOGGLE_OPEN_POPUP',

	CLEAN_STORE: 'CHAIN_CLEAN_STORE',
	DELETE_UNMET_NEEDS: 'DELETE_UNMET_NEEDS',
};