import React, { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import ChainPopupGroup from '../../ChainPopup/ChainPopup.Group';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ChainPopupChain from '../../ChainPopup/ChainPopup.Chain';

interface iSelectChain {
    id: number;
    stateId: number;
    dmaRegionId: number;
}

const TrendExport: FC<{
    initialStepIndex?: number;
    chain: {
        name: string;
        stateId: number;
        dmaRegionId: number;
    } | null;
    onSubmit: (data: { selectedChains: any[]; activePresetId: number }) => void;
}> = ({
    initialStepIndex = 0,
    chain,
    onSubmit,
}) => {
    const history = useHistory();
    const match = useRouteMatch();
    const [isPopupOpened, setIsPopupOpened] = useState(false);
    const [currentStepIndex, setCurrentStepIndex] = useState(initialStepIndex);
    const [activePresetId, setActivePresetId] = useState(0);
    const [selectedChains, setSelectedChains] = useState<iSelectChain[]>([]);

    useEffect(() => {
        setActivePresetId(0);
        setSelectedChains([]);
    }, [isPopupOpened]);

    const title = useMemo(() => {
        switch (currentStepIndex) {
            case 0: return <>
                <span style={{ fontSize: 19 }}>First, select a comparison group for{' '}</span>
                <br/>
                <b style={{ whiteSpace: 'nowrap'}}>{chain?.name}</b>
            </>;
            case 1: return <>
                <span style={{ fontSize: 19 }}>Now, select individual competitors for side-by-side comparison with{' '}</span>
                <br/>
                <b style={{ whiteSpace: 'nowrap'}}>{chain?.name}</b>
            </>;
            default: return `Compare ${chain?.name}`;
        }
    }, [currentStepIndex, chain]);

    const content = useMemo(() => {
        switch (currentStepIndex) {
            case 0: return (
                <ChainPopupGroup
                    history={history}
                    match={match}
                    onSelect={(data: {payload: iSelectChain}) => {
                        setActivePresetId(data.payload.id);
                        setCurrentStepIndex(currentStepIndex + 1);
                    }}
                    selectedId={activePresetId}
                    isXls
                />
            );
            case 1: return (
                <ChainPopupChain
                    history={history}
                    match={match}
                    chain={chain}
                    onSelect={({ payload }: {payload: iSelectChain}) => {
                        const { id, stateId, dmaRegionId } = payload;
                        const index = selectedChains.findIndex(item => item.id === id);
                        if (index >= 0) {
                            setSelectedChains([
                                ...selectedChains.slice(0, index),
                                ...selectedChains.slice(index + 1),
                            ]);
                        } else {
                            setSelectedChains([
                                ...selectedChains,
                                { id, stateId, dmaRegionId, },
                            ]);
                        }
                    }}
                    highlightedChainsIds={selectedChains.map(i => i.id)}
                    isMultiple
                    onCancel={() => setCurrentStepIndex(currentStepIndex - 1)}
                    onSubmit={() => {
                        setIsPopupOpened(false);
                        onSubmit({ selectedChains, activePresetId });
                    }}
                    isXls
                />
            );
            default: return <>No content</>
        }
    }, [currentStepIndex, selectedChains, activePresetId]);

    useEffect(() => {
        setCurrentStepIndex(initialStepIndex);
    }, [isPopupOpened]);

    return (
        <>
            <button
                className="button button-report__btn"
                onClick={(event) => setIsPopupOpened(true)}
            >
                XLSX
            </button>
            {isPopupOpened && ReactDOM.createPortal(
                <div className="modal is-active">
                    <div className="modal-content modal-content--competetive animation-show-modal-content">
                        <div className="modal-header">
                            {currentStepIndex > initialStepIndex && (
                                <button
                                    className="button modal-button-back"
                                    onClick={() => setCurrentStepIndex(currentStepIndex - 1)}
                                >
                                    <svg
                                        className="icon icon-arrow-left"
                                        dangerouslySetInnerHTML={{
                                            __html: '<use xlink:href="#icon-arrow-left" />',
                                        }}
                                    />
                                </button>
                            )}
                            <button
                                className="button modal-button-close"
                                onClick={() => setIsPopupOpened(false)}
                            >
                                <svg
                                    className="icon icon-close icon-close--secondary"
                                    dangerouslySetInnerHTML={{
                                        __html: '<use xlink:href="#icon-close" />',
                                    }}
                                />
                            </button>
                            <div className="modal-title" style={{ fontWeight: 100 }}>
                                <div className="text-center">
                                    {title}
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            {content}
                        </div>
                    </div>
                </div>,
                document.getElementById('modal-root')!
            )}
        </>
    );
};

export default TrendExport;