import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {defaultSortMethod} from "../../../utils/helpers";

const Category = props => {
 
	const {
		// table,
		data,
		chain,
		onClickItem,
		respondentTypeId,
	} = props;


	const getCategories = groupId => {
		return data.categories
			.filter( category => category.groupId === groupId)
			.map((category, categoryIndex) => {
			return (
				<div className="card-menu-category" key={`menu-category-${categoryIndex}`}>
					<div className="card-menu-category__caption"><b>{category.name.toUpperCase()}</b></div>
					<div className="card-menu-food__list">


						{category.items.map((item, itemIndex) => {

							const answer = data.consumerList.find(answer => answer.answerId === item.answerId) || null;

							const chainAnswer =  (answer) ? 
								(answer.data.find(answer => answer.respondentTypeId === respondentTypeId && answer.ffChainId === chain.id) || null) : null;

							return {
								...item,
								value:  chainAnswer.value
							}

						})
						.sort((a, b) => defaultSortMethod(a.value, b.value))
						.reverse()
						.map((item, itemIndex) => {

							return (
								<div className="card-menu-food__item"
									key={`menu-food-${itemIndex}`} 
									onClick={() => onClickItem({
										category,
										item
									})}>
									<span className="card-menu-food__caption" clipboard-disable="true">{item.text}</span>
									<span className="card-menu-food__value" clipboard-disable="true">{item.value > 0 ? `${item.value}%` : `-`}</span>

									<table className="hidden-layer">
										<tbody>
											<tr>
												<td>{item.text}</td>
												<td>{item.value > 0 ? `${item.value}%` : `-`}</td>
											</tr>
										</tbody>
									</table>
								</div>
							)

						})}
					</div> 
				</div>
			)
		});
	};

	return (
		<div className="card-menu">
			{[...new Set(data.categories.map(category => category.groupId))].map((groupId, index) => {
				return (
					<div className="card-menu__list" key={`categories-${index}`}>
						{getCategories(groupId)}
					</div>
				)
			})}
		</div>
	);

}

Category.propTypes = {
	table: PropTypes.object,
	data: PropTypes.object,
	chain: PropTypes.object,
	respondentTypeId: PropTypes.number,
};


const mapStateToProps = (state, props) => ({
    respondentTypeId: state.chain.filterRespondentTypeId,
});

export default connect(mapStateToProps)(Category);