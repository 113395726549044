import httpClient from '../utils/httpClient';

import {
	mapInitialDataPresets,
	mapInitialDataChains,
} from '../utils/helpers';


import { 
	ACTIONS_PRESET, 
	CHAIN_COMPARE_TYPES, 
	ACTIONS_CHAIN 
} from '../utils/constants';

import Notification from '../utils/notification';

export const getPresetsData = () => dispatch => {

	dispatch({ 
		type: ACTIONS_PRESET.LOADING
	});
	
	const http = new httpClient( `${process.env.REACT_APP_API}InitialData/GetInitialData/?onlyPresets=true&onlyChains=true` );
		
		http.request.then( (response) => {
 
			if (typeof response === 'object'){

				const sections = {
					restaurants: {
						chains: mapInitialDataChains(response.restaurantChains),
					},
					cstores:{
						chains: mapInitialDataChains(response.cStoreChains),
					},
					supermarkets:{
						chains: mapInitialDataChains(response.supermarketChains),
					}
				};

				dispatch({
					type: ACTIONS_PRESET.STORE_DATA, 
					payload: {
						restaurants: mapInitialDataPresets(response.restaurantPresets, sections.restaurants.chains),
						cstores: mapInitialDataPresets(response.cStorePresets, sections.cstores.chains),
						supermarkets: mapInitialDataPresets(response.supermarketPresets, sections.supermarkets.chains),
					} 
				});	 				

			}
			
 		}).catch( ( response ) => {
			console.error( "Network Error:", response );


			const notification = new Notification({
				timeout: 10000,
				itemClassName: `notification-item notification-item--warning`
			});

			if (response.status === 401){
				notification.add(`Unauthorized`);

			} else {
				notification.add(`Network request failed`);
			}
			
		})
 
}



export const doSavePreset = (request) => (dispatch, getState) => {

	const { preset:{id, name, chains} } = request;

	dispatch({ 
		type: ACTIONS_PRESET.LOADING
	});

	const http = new httpClient(`${process.env.REACT_APP_API}Preset/Save`, {
		method: "POST",
		data: JSON.stringify({
			presetId: id, 
			name: name, 
			chains: chains.map(chain => (chain.id))
		})
	});
		
	http.request.then( response => {

		const { 
			chain:{
				filterCompareTo:{
					payload:{
						id: filterCompareId
					},
					type: filterCompareType
				}
			},
		} = getState();


		if (response){

			dispatch({ 
				type: ACTIONS_PRESET[ (id) ? ACTIONS_PRESET.UPDATE_PRESET : ACTIONS_PRESET.CREATE_PRESET], 
				payload: {
					...request, 
					preset:{ 
						...response.preset,
						chains: request.preset.chains
					},
				} 
			});
	 

			if (filterCompareType === CHAIN_COMPARE_TYPES.GROUP && filterCompareId === id)
				dispatch({ type: ACTIONS_CHAIN.CHANGE_FILTER_COMPARE_TO, payload: {
					isClearDataGroups: true,
			 		filterCompareTo:{
			 			type: CHAIN_COMPARE_TYPES.GROUP,
			 			payload:{ 
							...response.preset,
							chains: request.preset.chains
						}
			 		}  
				}});

		}

	}).catch( ( response ) => {
		console.error( "Network Error:", response );
	})
}

export const doRemovePreset = (request) => dispatch => {

	const { 
		preset: {
			id
		} 
	} = request;

	dispatch({ 
		type: ACTIONS_PRESET.LOADING
	});
	
	const http = new httpClient( `${process.env.REACT_APP_API}Preset/Delete`, {
		method: "POST",
		data: JSON.stringify({
			presetId: id
		})
	});

	http.request.then( (response) => {

		dispatch({ 
			type: ACTIONS_PRESET.REMOVE,
			payload: request 
		});	

	}).catch( ( response ) => {
		console.error( "Network Error:", response );
	})
}