import React, { Component } from "react";
import PropTypes from 'prop-types';

class Tooltip extends Component {
 
	constructor(props) {
		super(props);
		this.state = {
			isShow: false
		}
		this.toggleVisible = this.toggleVisible.bind(this);
	}

	toggleVisible(){
		this.setState((prevState) => ({
			isShow: !prevState.isShow
		}))
	}

	render() {

		return(
			<span className={`tooltip-holder ${this.props.className}`} onMouseEnter={(event) => {
					this.toggleVisible();
				}} onMouseLeave={(event) => {
					this.toggleVisible();
				}}>
				{this.props.children}
				{this.state.isShow === true && this.props.content}
			</span> 
		)
	}
}


Tooltip.propTypes = {
	className: PropTypes.string,
};

Tooltip.defaultProps = {
	className: "",
};


export default Tooltip;