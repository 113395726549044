import React from 'react';
import PropTypes from 'prop-types';

const Preloader = props => {

	switch(props.type) {
		case "table":
			return (
				<div className="table-loader">
					<div className="table-loader-body">
						<div className="table-loader-row"></div>
						<div className="table-loader-row"></div>
						<div className="table-loader-row"></div>
						<div className="table-loader-row"></div>
						<div className="table-loader-row"></div>
						<div className="table-loader-row"></div>
						<div className="table-loader-row"></div>

						<div className="table-loader-col table-loader-col--i1"></div>
						<div className="table-loader-col table-loader-col--i2"></div>
						<div className="table-loader-col table-loader-col--i3"></div>
						<div className="table-loader-col table-loader-col--i4"></div>
						<div className="table-loader-col table-loader-col--i5"></div>
					</div>
				</div>
			);

		default:
				return (
				<div className="content-loader">
					{/*<div className="content-loader-block content-loader-block--one"></div>*/}
					<div className="content-loader-block content-loader-block--two"></div>
					<div className="content-loader-block content-loader-block--three"></div>
					<div className="content-loader-block content-loader-block--four"></div>
					<div className="content-loader-block content-loader-block--five"></div>
				</div>
			);
	}

}


Preloader.propTypes = {
	type: PropTypes.string
};

Preloader.defaultProps = {
	type: ""
}

export default Preloader;