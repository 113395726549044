
import {
	ACTIONS_MAIN,
} from '../utils/constants';

const initialState = {
	isLoaded: false,
	sections: {
		restaurants:{
			chains: [],
			tableGroups: [],
		},
		cstores:{
			chains: [],
			tableGroups: [],
		},
		supermarkets:{
			chains: [],
			tableGroups: []
		}
	},
	respondentTypes: [],
	segmentChainList: [
		{	
			id:0, 
			name:"All segments"
		},
		{
			id:1, 
			name:"Limited Service"
		},
		{
			id:2, 
			name:"Full Service"
		}
	],
	foodTabChains:[],
	foodTabRespondentTypes:[],
	currentPeriodName: '',
	previousPeriodName: '',
	supermarketCStoreCurrentPeriodName: '',
	supermarketCStorePreviousPeriodName: '',
	avaliableChainViews: [],
	chainStateDmaRegionViewSettings: [],
};

export default function mainData(state = initialState, action) {
	switch (action.type) {
		case ACTIONS_MAIN.FETCH_INIT_DATA: {

			return {
				...state,
				...action.payload,
				isLoaded: true
			};
		}	

		default: {
			return state;
		}
	}
}