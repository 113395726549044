import httpClient from '../utils/httpClient';

import { 
	ACTIONS_CHAIN, 
	CHAIN_COMPARE_TYPES,
	HOST_ENVIRONMENT,
	ACCESS_NO_PERMISSION
} from '../utils/constants';

import { 
	getApiControllerByAlias
} from '../utils/helpers';

import { getUrlParamFilterCompareTo } from '../utils/helpers';
import Notification from '../utils/notification';


export const getChainDataHeader = ( requestQuery ) => (dispatch, getState) => {

	dispatch({ 
		type: ACTIONS_CHAIN.FETCH_CHAIN_HEADER_START 
	});

	const { chainId, stateId, dmaRegionId, dataType, presets, cluster } = requestQuery;

	const apiController = getApiControllerByAlias(cluster);

	const http = new httpClient( `${process.env.REACT_APP_API}${apiController}/GetChainHeaderData`, {
		method: 'POST',
		data: JSON.stringify({
			chainId: chainId,
			dataType,
			stateId,
			dmaRegionId
		})

	});
		
	http.request.then( (response) => {

		const { 
			chain:{
				filterCompareTo,
			},
		} = getState();

		dispatch({ type: ACTIONS_CHAIN.FETCH_CHAIN_HEADER_FINISH, payload: response });

		dispatch({ 
			type: ACTIONS_CHAIN.SET_DEFAULT_PRESET, 
			payload: {
				type: CHAIN_COMPARE_TYPES.GROUP,
				id: response.defaultPresetId
			} 
		});

		if (filterCompareTo.payload === null)
			dispatch({ type: ACTIONS_CHAIN.CHANGE_FILTER_COMPARE_TO, payload: {
		 		filterCompareTo:{
		 			type: CHAIN_COMPARE_TYPES.GROUP,
		 			payload: presets.find( preset => (preset.id === response.defaultPresetId) )
		 		}  
			}});
 

	}).catch( (response) => {

		if (HOST_ENVIRONMENT.includes(window.location.hostname) === true)
			console.error( "Network Error:", response );
	});
}

export const getDataByBroup = ( requestQuery ) => (dispatch, getState) => {

	const { methodName, chainId, stateId, dmaRegionId, aliasGroupName } = requestQuery;
	const { 
		chain:{
			filterCompareTo, 
			filterRespondentTypeId,
			dataGroups
		},
	} = getState();
 
	const apiController = getApiControllerByAlias(aliasGroupName);

 	const group =  dataGroups.find( group => group.methodName === methodName);
	const historyTables = group.tables.filter( table => table.isHistory === true).map( table => {
		return table.methodName
	});
 
	dispatch({ 
		type: ACTIONS_CHAIN.FETCH_BY_GROUP_DATA_START, 
		payload: {
			methodName
		} 
	});

		const filtereCompareTo =  getUrlParamFilterCompareTo(filterCompareTo);

		const http = new httpClient( `${process.env.REACT_APP_API}${apiController}/${methodName}`, {
				method: 'POST',
				data: JSON.stringify({
					chainId: chainId,
					stateId: stateId,
					dmaRegionId: dmaRegionId,
					dataType: filterRespondentTypeId,
					historyTables: (historyTables.length > 0) ? historyTables : null,
					...filtereCompareTo
				})

			});
			
			http.request.then( (response) => {

				const tables = group.tables.map( (table, index) => {
					return {
						...table,
						data: response[index]
					}
				});	


				dispatch({ 
					type: ACTIONS_CHAIN.FETCH_BY_GROUP_DATA_FINISH, 
					payload: {tables, methodName} 
				});
				
			}).catch( (response) => {

				if (HOST_ENVIRONMENT.includes(window.location.hostname) === true)
					console.error( "Network Error:", response );

				if (response.responseText === ACCESS_NO_PERMISSION){

					const notification = new Notification({
						timeout: 4000,
						//className: 'notification notification--center',
						itemClassName: `notification-item notification-item--warning`,
						onDestruction: () => {
							window.location.href = `${window.location.protocol}//${window.location.host}${aliasGroupName}`;
						}
					});
					notification.add(`No have access to this chain`);
				}


			})
}

export const getDataByTable = ( queryParam ) => (dispatch, getState)  => {

	const {
		groupMethodName, 
		tableMethodName, 
		isHistory,
		chainId,
		stateId,
		dmaRegionId,
		chainType,
	} = queryParam;

	const{ 
		chain:{
			filterCompareTo,
			filterRespondentTypeId: respondentTypeId
		} 
	} = getState();

		dispatch({ 
			type: ACTIONS_CHAIN.UPDATE_GROUP_TABLE, 
			payload: {
				groupMethodName,
				tableMethodName,
				options:{
					data: null,
					isHistory: queryParam.isHistory,
				}
			}
		});

		const http = new httpClient( `${process.env.REACT_APP_API}Table/GetTableData`, {
			method: 'POST',
			data: JSON.stringify({
				methodName: tableMethodName,
				dataType: respondentTypeId,
				chainId: chainId,
				stateId: stateId,
				dmaRegionId: dmaRegionId,
				chainType: chainType,
				...(isHistory === true ? { 
					needHistoryData: true
				} : getUrlParamFilterCompareTo(filterCompareTo) ),
			})
		});

		http.request.then( (response) => {

			dispatch({ 
				type: ACTIONS_CHAIN.UPDATE_GROUP_TABLE, 
				payload: {
					groupMethodName,
					tableMethodName,
					options:{
						data: response
					}
				}
			});

		}).catch( ( response ) => {
			console.error( "Network Error:", response );
		})
}


export function createGroupsData(payload) {
	return {
		type: ACTIONS_CHAIN.CREATE_GROUPS_STORE,
		payload: payload
	};
}

export function clearChainStore() {
	return {
		type: ACTIONS_CHAIN.CLEAN_STORE,
		payload: true
	};
}

export function setRespondentType(respondent) {
	return {
		type: ACTIONS_CHAIN.CHANGE_RESPONDENT_TYPE,
		payload: respondent
	};
}

export function changeFilterCompare(filter) {
	return {
		type: ACTIONS_CHAIN.CHANGE_FILTER_COMPARE_TO,
		payload: filter
	};
}

export function changeReportCompareTo(filter) {
	return {
		type: ACTIONS_CHAIN.CHANGE_REPORT_COMPARE_TO,
		payload: filter
	};
}

export function toggleCompetetivePopup(payload = null) {
	return {
		type: ACTIONS_CHAIN.TOGGLE_OPEN_POPUP,
		payload: payload
	};
}

export const deleteUnmetNeeds = (data) => dispatch => {
	const {
		methodName,
		id
	} = data;

	const http = new httpClient(`${process.env.REACT_APP_API}UnmetNeeds/HideResponseFromUnmetNeeds`, {
		method: "POST",
		data: JSON.stringify({
			responseId: id
		}),
	})
	
	http.request.then((response) => {
		if(response.success === true) {
			dispatch({
				type: ACTIONS_CHAIN.DELETE_UNMET_NEEDS,
				payload: {
					methodName,
					id
				}
			})
		}
	})
}