import React, { useState } from "react";

import httpClient from '../../utils/httpClient';
import Notification from '../../utils/notification';

import {
	ROOT_URL, 
} from '../../utils/constants';

import './NavFoodTabTooltip.scss';

const notification = new Notification({
	timeout: 10000,
	itemClassName: `notification-item`
});


export default function NavFoodTabTooltip() {
 
	const [isProcessed, setProcessed] = useState(false);

	const handleContactRequest = () => {

		notification.add(`Request has been successfully submitted`);
		setProcessed(true);


		if (isProcessed === false){
			new httpClient( `${ROOT_URL}Api/SendFoodTabAccessEmail`, {
				method: 'GET',
			});	

			// .request.then((response) => {
			// });
		}
	}

	return (
		<div className="food-tab-tooltip">
			<div className="food-tab-tooltip__enter">
				<div className="food-tab-tooltip__caption">QSR FOOD</div>
				<div className="food-tab-tooltip__content">
					Find out about Brand Fingerprints QSR Food. 
					<br />Understand customer eating behavior for the Top QSR Chains and answer questions on how often and what customers eat.
				</div>
				<div className="food-tab-tooltip__content">
					<button
						className="button button-link food-tab-tooltip__button"
						onClick={handleContactRequest}>Click here...</button>
				</div>
			</div>
		</div>
	)
}

 