import React, { Component } from "react";
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import {Link, NavLink} from 'react-router-dom';

import {ROOT_URL, SECTIONS} from '../../utils/constants';
import Dropdown from '../../components/Dropdown';

import { setRespondentType, getDataByBroup, setSegmentChain } from '../../actions/rankings';
import { groupsTableSelector, groupsDataSelector } from '../../route/Rankings/Rankings.selectors';

import {smoothScrollTo} from '../../utils/helpers';
import { PlatformContext } from "@datassential/platform-ui-lib";

class RankingNavigation extends Component {

	constructor(props){
		super(props);
		this.loadGroupData = this.loadGroupData.bind(this);
		this.onWindowScroll = this.onWindowScroll.bind(this);
	}
 
	componentWillMount(){
		this.loadGroupData(this.props);
	}


	componentDidMount() {
		window.scrollTo(0, 0);
		window.addEventListener('scroll', this.onWindowScroll);
	}
 
 	componentWillUnmount() {
		window.removeEventListener('scroll', this.onWindowScroll);
	}

	componentWillReceiveProps(nextProps){
		this.loadGroupData(nextProps);
	}

	componentDidUpdate(){
		this.scrollToSection(this.props.groupsData);
	}

 	loadGroupData(props){
 		const {
 			groupsData,
 			initGroups:{
 				aliasName
 			}
 		} = props;

 
 		if (groupsData && groupsData.isLoadProcess === false && groupsData.isLoaded === false)
	 		this.props.getDataByBroup({
				respondentType: props.respondentTypeId,
				methodName: groupsData.methodName,
				aliasName,
	 		});
 	}

	getScrollOffset () {
		const { userInfo: { featureFlags } } = this.context;
		const isPlatformEnabled = (featureFlags || []).includes('enable platform');

		return isPlatformEnabled  ? 180 : 50 ;
	}

	scrollToSection = (groupsData = null) => {
		const hashUrl = window.location.hash.substr(1);

		if (hashUrl)
			setTimeout(() => {

				const nodeSection = document.getElementById(hashUrl);

				if(nodeSection)
					smoothScrollTo((nodeSection.offsetTop - this.getScrollOffset()), 1000);

				if (groupsData && groupsData.isLoaded === true)
					window.history.pushState('', document.title, window.location.pathname);	

			}, 600);

	}

	onWindowScroll(){
		if (window.pageYOffset >= this.nodeNavigation.offsetTop) {
			this.nodeNavigation.firstChild.classList.add('is-sticky');
		} else {
			this.nodeNavigation.firstChild.classList.remove('is-sticky');
		}
 	}

 	onClickNav = () => {
		if (window.pageYOffset > this.nodeNavigation.offsetTop)
			smoothScrollTo(this.nodeNavigation.offsetTop, 1000);  
	}

	onClickMenu = (tableAliasName) => {
		const nodeSection = document.getElementById(tableAliasName);
		if (nodeSection)
			smoothScrollTo((nodeSection.offsetTop - this.getScrollOffset()), 1000);
	}
 
	render() {
		const {
 			match: { 
 				params: { 
 					0: clusterName,
 					aliasName
 				}
 			},
			initGroups: {
				tableGroups
			}, 
			respondentTypesList, 
			respondentTypeId,

			segmentChainList, 
			segmentChainId,

		} = this.props;


		const {
			excludeListRespondentTypeNames = [],
			isChainSegmentation = false,
		} = SECTIONS.find(cluster => cluster.aliasName === clusterName) || {};

		// const clusterSection = SECTIONS.find(cluster => cluster.systemName === clusterName) || null;

 		return (
			<div className="section-nav">
				<div className="container">
					<div className="section-nav-container" ref={node => this.nodeNavigation = node}>
						<div className="navigation-panel animation-show-navigation-panel">
							<div className="navigation-panel-container">

								<ul className="tab-navigation">
									{tableGroups
										.filter( (group) => group.isPersonal !== true )
										.map((group, indexGroup) => (
										<li key={`${indexGroup}${group.aliasName}`} className="tab-navigation__item">
											<NavLink 
												activeClassName="is-active" 
												className="tab-navigation__link" 
												to={{ pathname: `${ROOT_URL}${clusterName}/rankings/${group.aliasName}` }}
												onClick={event => {
													 
													this.onClickNav();

													if (aliasName === group.aliasName)
														event.preventDefault();

												}}>{group.name}</NavLink>

											<span className="tab-navigation-menu">
												<span className="tab-navigation-menu-list">
													{group.tables
														.filter( table => table.isPersonal !== true )
														.map((table, indexTable) => ( 
														<Link className="tab-navigation-menu__link" 
															key={`${indexGroup}${indexTable}`} 
															to={{ 
																pathname: `${ROOT_URL}${clusterName}/rankings/${group.aliasName}`,
																hash: `#${table.aliasName}`
															}}
															onClick={event => {

															if (aliasName === group.aliasName)
																event.preventDefault();
	
																this.onClickMenu(table.aliasName);

															}}>{table.name}</Link>
													))}
												</span>
											</span>
										</li>
									))}
								</ul>

								<div className="navigation-panel-dropdown">
									<div className="dropdown-label">AMONG:</div>
									<Dropdown 
										className="dropdown--among"
										options={respondentTypesList.reduce((respondentGroups, group) => {
											const items = group.items.filter( respondent => excludeListRespondentTypeNames.includes(respondent.name) !== true  )
											return [
												...respondentGroups,
												{
													...group, 
													items
												}
											]
										}, [])} 
										optionSelectedId={respondentTypeId}
										onChange={(respondent) => this.props.setRespondentType(respondent.id)}
									/>
									{ isChainSegmentation && 
										<Dropdown 
											className="dropdown--segment"
											options={segmentChainList} 
											optionSelectedId={segmentChainId}
											onChange={(segment) => this.props.setSegmentChain(segment.id)}
										/>}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

RankingNavigation.propTypes = {
	initGroups: PropTypes.object,
	respondentTypeId: PropTypes.number,

	setRespondentType: PropTypes.func,
	clearDataStore: PropTypes.func
};

RankingNavigation.contextType = PlatformContext;

const mapStateToProps = (state, props) => ({
	groupsData: groupsDataSelector(state, props),
	initGroups: groupsTableSelector(state, props),
	respondentTypesList: state.init.respondentTypes,
	respondentTypeId: state.rankings.filterRespondentTypeId,

	segmentChainList: state.init.segmentChainList,
	segmentChainId: state.rankings.filterSegmentChainId,
});

const mapDispatchToProps = dispatch => ({
	setRespondentType: (id) => dispatch(setRespondentType(id)),
	getDataByBroup: ( requestQuery ) => dispatch(getDataByBroup( requestQuery )),
    setSegmentChain: (id) => dispatch(setSegmentChain(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RankingNavigation);