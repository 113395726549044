import React, { Component, Fragment } from "react";
import {connect} from 'react-redux';
// import PropTypes from 'prop-types';
 
import DropdownToggle from "../../components/DropdownToggle";
import Table from "../../components/Table";
 

import {TABLE_COLUMNS} from '../../utils/constants';
import {updateHeaderTable} from '../../actions/rankings';
import {getLabelPeriods} from '../../route/Rankings/Rankings.selectors';

import {dbTableColumnInfo} from '../../utils/presets';

 

class RankingHistory extends Component {

	constructor(props){
		super(props);

		this.onToggleColumn = this.onToggleColumn.bind(this);
		this.onSortColumn = this.onSortColumn.bind(this);
	}

	onToggleColumn(columnSelected){

		const { dataTable: {columnsGroups} } = this.props;
 
		const columnsGroupsMaped = columnsGroups.map( group => {
			const columns = group.columns.map( column => {
 
				return {
					...column,
					isVisible: (column.isAllowToggleVisible === true) ? ((column.id === columnSelected.id) ? true : false) : column.isVisible,
				}
			})
			return {
				...group,
				columns
			};
		});

		const {
		  group: { methodName: groupMethodName },
		  table: { methodName: tableMethodName }
		} = this.props;

		this.props.updateHeaderTable({ groupMethodName, tableMethodName, columnsGroups:columnsGroupsMaped });
	}

	onSortColumn(column){

		const { columnId, sortOrder } = column;
		const { dataTable: {columnsGroups} } = this.props;

		const columnsGroupsMaped = columnsGroups.map( group => {
			const columns = group.columns.map( column => {
				return {
					...column,
					orderBy: (column.id === columnId) ? sortOrder : null
				}
			})
			return {
				...group,
				columns
			};
		});

		const {
		  group: { methodName: groupMethodName },
		  table: { methodName: tableMethodName }
		} = this.props;

		this.props.updateHeaderTable({ groupMethodName, tableMethodName, columnsGroups:columnsGroupsMaped });
	}
 
	render() {

		const { 
			table,
			chainList, 
			dataTable,
			labelPeriods,
			match: { 
				params: { 
					0: clusterName,
					aliasName: groupName
				}
			}
		} = this.props;

		const isFloatingSampleSize = dataTable.rows.some( row => Array.isArray(row.bases) );

		return (
			<Fragment>

				<DropdownToggle
					className="dropdown-toggle--side dropdown-toggle--single"
					isMultiselect={false}
					options={ dataTable.columnsGroups.map( (columnGroup) => {

						const items = columnGroup.columns
							.filter( column => column.isAllowToggleVisible === true )
							.map( column => {
								return{
									id: column.id,
									name: column.label,
									isSelected: column.isVisible
								}
							});

						return {
							name: columnGroup.label,
							items: items
						}

					}).filter( group => group.items.length > 0 )}
					onClickItem={(column) => this.onToggleColumn(column)}
				/>

				<div clipboard={table.methodName}>
					<span className="hidden-layer">{table.name.toUpperCase()}</span>
					<Table
						className="table-history-ranking"
						columnsGroups={ dataTable.columnsGroups.map( (group, indexGroup) => {

								const columns = group.columns
									.filter( column => column.isVisible === true )
									.map((column) => {

										const columnInfo = dbTableColumnInfo.get(column.name);

										switch(column.name) {
											case TABLE_COLUMNS.HISTORY_FROM:
												columnInfo.label = labelPeriods.previous;
												break;
											case TABLE_COLUMNS.HISTORY_TO:
												columnInfo.label = labelPeriods.current;
												break;
											default:
												break;
										}

										return {
											id: column.id,
											label: columnInfo.label,
											type: columnInfo.dataType,
											className: columnInfo.className,
											isSorting: true,
											orderBy: column.orderBy,
											isColumn: true,
										}
									});

								if (columns.length === 1){
									const [column] = columns;
									return{
										...column,
										isColumn: true,
										columns:[]
									}	
								}

								return {
									className: '',
									label: group.label,
									columns: columns,
									isColumn: false
								}

						}).filter( group => group.isColumn === true || group.columns.length > 0 )}
						rowsGroups={[{
							group: null,
							rows: ( (dataTable, chainList) => {

								const selectedColumn = dataTable.columnsGroups.reduce( (selectedColumn, group) => {
									return (!selectedColumn) ? group.columns.find( column => column.isVisible === true && column.isAllowToggleVisible === true ) || null : selectedColumn;
								}, null);

								return chainList
									.filter(chain => chain.isDisabled === false)
									.map( (chain, chainIndex) => {
										
										const row = dataTable.rows.find( row => row.chainId === chain.id ) || null;

										if (selectedColumn === null)
											return null;

										if (row === null)
											return null;

											const [[baseNumber]] = row.values;
											const [valueCurrentPeriod, valuePreviousPeriod, valueDifferent] = row.values[selectedColumn.id];

											return [
												{
													value: chain.name, 
													chainId: chain.id, 
													stateId: chain.stateId, 
													dmaRegionId: chain.dmaRegionId, 
													location: {
														clusterName, groupName
													}
												},
												{value:baseNumber},
												{value:valueCurrentPeriod},
												{value:valuePreviousPeriod},
												{value:valueDifferent},
											];

									}).filter( row => row );

							})(dataTable, chainList)
						}]}
						onSortColumn={this.onSortColumn}
					/>
				</div>

				{isFloatingSampleSize === true && <div className="section-content__message">
					*Sample sizes vary by metric, the BASE shown represents the largest base size across all metrics, you can also hover over a figure to view its base size.
				</div>}
			</Fragment>
		);
	}
}

const mapStateToProps = (state, props) => ({
	labelPeriods: getLabelPeriods(state, props),
});

const mapDispatchToProps = dispatch => ({
	updateHeaderTable: (data) => dispatch(updateHeaderTable(data)),
});

 
export default connect(mapStateToProps, mapDispatchToProps)(RankingHistory);