
export const WORD_FONT_WEIGHT_SIZE = {
	0: 8,
	1: 12,
	2: 16,
	3: 20,
	4: 24,
	5: 27,
	6: 30,
	7: 33,
	8: 36,
	9: 39,
	10: 42,
	11: 44,
	12: 46,
	13: 48,
	14: 50,
	15: 52,
	16: 54,
	17: 56,
	18: 58,
	19: 60,
	20: 61,
	21: 62,
	22: 63,
	23: 64,
	24: 65,
	25: 66,
	26: 67,
	27: 68,
	28: 69,
	29: 70,
	30: 70.5,
	31: 71,
	32: 71.5,
	33: 72,
	34: 72.5,
	35: 73,
	36: 73.5,
	37: 74,
	38: 74.5,
	39: 75,
	40: 75.5,
	41: 76,
	42: 76.5,
	43: 77,
	44: 77.5,
	45: 78,
	46: 78.5,
	47: 79,
	48: 79.5,
	49: 80,
	50: 80.5,
	51: 81,
	52: 81.5,
	53: 82,
	54: 82.5,
	55: 83,
	56: 83.5,
	57: 84,
	58: 84.5,
	59: 85,
	60: 85.5,
	61: 86,
	62: 86.5,
	63: 87,
	64: 87.5,
	65: 88,
	66: 88.5,
	67: 89,
	68: 89.5,
	69: 90,
	70: 90.5,
	71: 91,
	72: 91.5,
	73: 92,
	74: 92.5,
	75: 93,
	76: 93.5,
	77: 94,
	78: 94.5,
	79: 95,
	80: 95.5,
	81: 96,
	82: 96.5,
	83: 97,
	84: 97.5,
	85: 98,
	86: 98.5,
	87: 99,
	88: 99.5,
	89: 100,
	90: 100.5,
	91: 101,
	92: 101.5,
	93: 102,
	94: 102.5,
	95: 103,
	96: 103.5,
	97: 104,
	98: 104.5,
	99: 105,
	100: 105.5
};


export const WORD_COLORS = [
	'#00b0f0', 
	'#92d050', 
	'#ff6d4b', 
	'#d77791', 
	'#d09486', 
	'#ffc000', 
	// '#fc64cd',  
	// '#d9d9d9', 
	// '#ccff33', 
	// '#ff3232',
];