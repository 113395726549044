import React, {Component} from "react";
// import {connect} from 'react-redux';
import PropTypes from 'prop-types';
 
import PerfectScrollbar from "../../components/PerfectScrollbar";

// import { isMixLSRFSR } from './ChainPopup.Group';

import { 
	PRESETS_GROUP_ACTIONS, 
	// CHAIN_COMPARE_TYPES
} from '../../utils/constants';



const getPresetTypeByPreset = (presetTypeList, { chains = [] }) => {
	const [ presetType = null ] = presetTypeList.filter(presetType => {
		return chains.every(chain => {
			return presetType.chains.find(({id}) => chain && id === chain.id);
		})
	});

	return presetType;
}
 
class ChainPopupGroupView extends Component {

	constructor(props){
		super(props);

		this.positionUpdate  = this.positionUpdate.bind(this);
		this.scrollBarUpdate = this.scrollBarUpdate.bind(this);
	}

	componentDidMount() {
		this.positionUpdate();
		this.scrollBarUpdate();
	}

	componentDidUpdate() {
 		this.positionUpdate();
 		this.scrollBarUpdate();
	}

	componentWillUnmount() {
		if (this.perfectScrollbar)
			this.perfectScrollbar.destroy();
		this.perfectScrollbar = null;
	}

	positionUpdate(){
		const {targetClientRect} = this.props,
			// screenHalfWidth = document.body.clientWidth / 2,
			screenHalfHeight = document.body.clientHeight / 2;
		const tooltipClientRect = this.nodeTooltip.getBoundingClientRect();
		let positionTop;

		if (targetClientRect.top > screenHalfHeight){
			positionTop = targetClientRect.top - tooltipClientRect.height;
			this.nodeTooltip.classList.remove('tooltip-preset--bottom');
			this.nodeTooltip.classList.add('tooltip-preset--top');
		} else {
			positionTop = targetClientRect.top; 
			this.nodeTooltip.classList.remove('tooltip-preset--top');
			this.nodeTooltip.classList.add('tooltip-preset--bottom');
		}

		this.nodeTooltip.style.transform = `translate3d(${Math.round(targetClientRect.left)}px, ${Math.round(positionTop)}px, 0)`;
		this.nodeTooltip.classList.add('is-active');
	}

	scrollBarUpdate(){	
		this.nodeScrollBar.scrollTop = 0;
		if (this.perfectScrollbar)
			this.perfectScrollbar.destroy();

		this.perfectScrollbar = new PerfectScrollbar(this.nodeScrollBar, {
			wheelPropagation: false
		});
	}

	render() {

		const { 
			preset, 
			presetTypeList,
		} = this.props;

 		const { 
 			// chains: clusterChains  = [],
 			// type = null,
 			section,
 		} = (preset) ? getPresetTypeByPreset(presetTypeList, preset) : {};


 	// 	const chainList = (isMixLSRFSR(presetTypeList) === false) ? clusterChains.filter(chain => chain.segmentGroupId === type) : clusterChains;
		// const countEnabledChains = chainList.filter(chain => chain.isDisabled === false).length;


		const isEditingEnable = preset.chains.every(chain => (chain.isDisabled === false)); 

		return (
			<div className="tooltip-preset" ref={(node) => this.nodeTooltip = node}>
				<div className="tooltip-preset-chains" ref={(node) => this.nodeScrollBar = node}>
					{preset.chains
						.map( (chain, index) => {
						return (
							<div 
								className={`tooltip-preset-chains__item ${(chain && chain.isDisabled === true) ? `is-disabled` : ``}`} 
								key={index}>
								{chain.name}
							</div>
						)
					})}
				</div>
				<div className="tooltip-preset-footer">
					<div className="tooltip-preset-footer__count">{preset.chains.length} chains</div>
					{preset.isCustom === true && (
						<div className="tooltip-preset-footer__buttons">
							<button 
								className="button button-link" 
								disabled={(isEditingEnable === false) ? true : false} 
								onClick={(event) => {
									event.preventDefault();
									event.stopPropagation();

									this.props.callback({
										type: PRESETS_GROUP_ACTIONS.EDIT,
										payload: preset
									});
		 
								}}>
								edit
							</button>
							<button 
								className="button button-link button-remove"
								disabled={(isEditingEnable === false) ? true : false} 
								onClick={(event) => {
									event.preventDefault();
									event.stopPropagation();

									this.props.callback({
										type: PRESETS_GROUP_ACTIONS.REMOVE,
										section: section,
										preset: preset
									});
	 
							}}>
								delete
							</button>
						</div>
					)}
				</div>
			</div>
		);
	}
}

ChainPopupGroupView.propTypes = {
	preset: PropTypes.object,
	chains: PropTypes.array,
	callback: PropTypes.func,
	presetTypeList: PropTypes.array,
};

ChainPopupGroupView.defaultProps = {
	preset: null,
};

// const mapStateToProps = (state, props) => ({
// 	sections: state.init.sections,
// });

// export default withRouter(connect(mapStateToProps)(ChainPopupGroupView));
export default ChainPopupGroupView;
