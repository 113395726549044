import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';


import SectionWrapper from '../../../containers/SectionWrapper';
import {getDataTable, hasShowSection}  from '../../../route/Chain/Chain.selectors';
import {getChainsByCluster} from '../../../selectors/section.selectors';

import Highcharts from 'highcharts';
import {HIGHCHARTS} from '../../../utils/presets';
 

import {defaultSortMethod, capitalizeFirstLetter} from "../../../utils/helpers";
 

const COLOR_SET = {
	MAIN_BLUE: '#22A8F3',
	MAIN_GREEN: '#92d050'
}

 

const CHART_TYPE_LIST = [{
	name: 'Crossover rate',
	description: [{
		aliasName: 'restaurants',
		text: 'in markets where both chains exist, % of [chain] visitors also visited the other chain within the past 3 months'
	},{
		aliasName: 'c-stores',
		text: 'in markets where both chains exist, % of [chain] visitors who also visited the other chain at least occasionally'
	},{
		aliasName: 'grocery',
		text: 'in markets where both chains exist, % of [chain] visitors who also visited the other chain at least occasionally'
	}],
	color: '#22A8F3',
	key: 'crossoverValue'
},{
	name: 'Common markets',
	description: [{
		aliasName: 'restaurants',
		text: '# of metro areas where both [chain] and the other chain each have 10 or more locations'
	}, {
		aliasName: 'c-stores',
		text: '# of metro areas where both [chain] and the other chain have locations'
	}, {
		aliasName: 'grocery',
		text: '# of metro areas where both [chain] and the other chain have locations'
	}],
	color: '#92d050',
	key: 'commonValue'
},];

const getMappedData = (chains, data, sortBy) => {

	const dataList = data.rows.map( (item, index) => {
		const chain = chains.find(chain => chain.id === item.chainId) || null,
			[crossoverValue] = item.values,
			[additionalValue] = item.additionalValues;

		return {
			id: item.chainId,
			name: (chain) ? chain.name : 'N/A',
			crossoverValue: (crossoverValue) ? +crossoverValue : 0,
			commonValue: (additionalValue.value) ? +additionalValue.value : 0,
		}
	});
	
	//const maxValue = Math.max.apply(null, dataList.map( element => element.crossoverValue));

	return dataList.map( item => ({
		...item,
		//crossoverValue: Math.round( (item.crossoverValue * 100) / maxValue )  
		crossoverValue: item.crossoverValue,
	})).sort((a, b) => {

		a = a[sortBy];
		b = b[sortBy];

		return defaultSortMethod(a,b);

	}).reverse();
}


class CompetitiveChart extends Component {

	constructor(props){
		super(props);

		this.nodeChart = React.createRef();
		this.instanceHighchart = null;

		this.handlerSwichType = this.handlerSwichType.bind(this);

		const [fistType] = CHART_TYPE_LIST;

		this.state = {
			sortBy: fistType.key
		}
	}

	componentDidMount() {
		if (this.props.data)
			this.buildChart();
	}
 
	componentDidUpdate(){
		if (this.props.data)
			this.buildChart();
	}	

	componentWillUnmount() {
		if (this.instanceHighchart)
			this.instanceHighchart.destroy();
	}

	handlerSwichType(key) {
		this.setState({
			sortBy: key
		});
	}


	buildChart() {

		const {
			data, 
			clusterChains:{
				chains
			}, 

		} = this.props;

		if (this.instanceHighchart)
			this.instanceHighchart.destroy();

		const {
			sortBy
		} = this.state;

		const chartData = getMappedData(chains, data, sortBy);

		this.instanceHighchart = new Highcharts.Chart({
			...HIGHCHARTS.CHAIN_AREA_COMPETITIVE,
			chart: {
				// marginLeft: 43,
				// marginRight: 43,
				renderTo: this.nodeChart,
				height: 450,
			},
			xAxis: [{
				categories: chartData.map(chain => (chain.name)),
				labels:{
					style: {
						// fontSize:'12px'
						color: '#000000',
						fontFamily:'"Comfortaa", cursive'
					},
					rotation: -90
				},
				crosshair: true
			}],
			series: [{
				name: CHART_TYPE_LIST[0].name,
				type: 'column',
				color: COLOR_SET.MAIN_BLUE,

				shadow: false,
				borderRadius: 8,
				pointWidth: 16,

				label:{
					enabled: false
				},
				yAxis: 0,
				tooltip: {
					valueSuffix: '%' // for tooltip
				},
				data: chartData.map(chain => {
					return chain.crossoverValue ? Math.round(chain.crossoverValue) : 0
				}),

			}, {
				name: CHART_TYPE_LIST[1].name,
				type: 'spline',
				yAxis: 1,
				color: COLOR_SET.MAIN_GREEN,
				lineWidth: 3,
				label:{
					enabled: false,
				},
				marker: {
					enabled: false,
				},
				tooltip: {
					valueSuffix: '' // for tooltip
				},
				data: chartData.map(chain => (chain.commonValue)),
			}],
		});

	}


	render() {
		const { 
			table, 
			data, 
			clusterChains:{
				chains
			},
			isVisibleSection,
			chain:selectedChain,

			match: {
				params: {
					0 : aliasName = null,
				}
			}

		} = this.props;
 
		const {
			sortBy
		} = this.state;
 
		return (
			<Fragment>
				{isVisibleSection === false ? (
					<SectionWrapper config={{
						...table,
						description: (table.description) ? capitalizeFirstLetter(table.description).replace('[chain]', selectedChain.name) : null,
						question: (table.question) ? capitalizeFirstLetter(table.question).replace('[chain]', selectedChain.name) : null,
						isShowData: (data === null) ? false : true,
						copyData: (data === null) ? null : getMappedData(chains, data, sortBy).reduce( (list, chain) => { 

							const crossoverValue = chain.crossoverValue ? Math.round(chain.crossoverValue) : 0;
							list.push([chain.name, `${crossoverValue}%`, chain.commonValue]);
							return list
						}, [
							[table.name.toUpperCase()],
							['', ...CHART_TYPE_LIST.map(type=>type.name)]
						]),

					}}>
						<div className="section-content--container" clipboard={table.methodName}>
							<div className="card-competitive">
								<div className="card-competitive-header">
									{CHART_TYPE_LIST.map((item, index) => {
										const classList = [
											...['button-legend'],
											...[`button-legend--${item.key.toLocaleLowerCase()}`],
											...[(sortBy === item.key) ? `is-selected`: ``],
										].filter( element => element ).join(' ');

					 

										const description = item.description.find(item => item.aliasName === aliasName).text || '';
							 
										return (
											<div
												key={`swich-${index}`}
												onClick={ event => {
													event.preventDefault();
													this.handlerSwichType(item.key);
												}}
												className={classList}
												style={{backgroundColor: item.color}}>
												<div className="button-legend__caption">{item.name}</div>
												<div className="button-legend__description">{description.replace('[chain]', selectedChain.name)}</div>
												<div className="button-legend__line" style={{backgroundColor: item.color}}></div>
											</div>
										)
									})}
								</div>
								<div className="card-competitive-chart" ref={node => { this.nodeChart = node }}></div>
							</div>
						</div>
					</SectionWrapper> ) : <Fragment></Fragment>}
			</Fragment>
		);
	}
}
 
CompetitiveChart.propTypes = {
	chain: PropTypes.object,
	data: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
	data: getDataTable(state, props),
	isVisibleSection: hasShowSection(state, props),
	clusterChains: getChainsByCluster(state, props),
})
 
export default connect(mapStateToProps)(CompetitiveChart);