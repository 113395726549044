import React, {Component} from "react";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import Preloader from '../../../components/Preloader';
import ClipboardButtons from '../../../components/ClipboardButtons';
import Dropdown from '../../../components/Dropdown';

import {
	ORDER_FREQUENCY_LIST,
} from '../../../utils/constants';

import { getDataTable } from '../../../route/Chain/Chain.selectors.js';



const getValuesByFilter = ({data, filterConsumerTypeId}) => {
	const consumer = data.find(item => item.consumerTypeId === filterConsumerTypeId) || [];
	if (consumer.length === 0)
		return []

	return consumer.data
}


const getBaseValue = ({data}) => {
	const consumerData = data.find(item => item.consumerTypeId === null) || null;
		if (consumerData === null)
			return null;
 
	const [consumerItem] = consumerData.data;
	const [base] = consumerItem.data;
	
	return base.value;
}


class MenuItems extends Component {

	constructor(props){
		super(props);

		this.handlerSwichFilter = this.handlerSwichFilter.bind(this);

		this.state = {
			filterConsumerTypeId: ORDER_FREQUENCY_LIST[0].id,
		};
	}

	handlerSwichFilter(filter){
		this.setState(prevState => {
			return {
				...prevState,
				...filter
			}
		});	
	}

	render() {
		const {
			table,
			data,
			chain,
		} = this.props;

		const {
			filterConsumerTypeId
		} = this.state;

		const answerValues = data
			? getValuesByFilter({
				...data,
				filterConsumerTypeId
			})
		: [];


		const dataList = (data || { items: [] }).items.map((item, index) => {
			const answer = answerValues.find(answer => answer.answerId === item.answerId) || null;
			const [row] = (answer) ? answer.data : [{
				value: 0
			}];
			return {
				...item,
				value: row.value > 0 ? `${row.value}%` : `-`,
			}
		});


		const filterConsumer = ORDER_FREQUENCY_LIST.find(item => item.id === filterConsumerTypeId)
		const formattedDate = new Date().toLocaleString('default', { 
			month: 'short',
			day: 'numeric', 
			year: 'numeric'
		});
 

		return (
			<section className="section" id={table.aliasName} snapshot={table.methodName}>
				<div className="section-header">
					<div className="section-header-meta">
						<div className="section-header-meta__caption">
							{table.name}
						</div>
						<div className="section-header-meta__description">
							% of customers who
							{data && <Dropdown 
								className="dropdown-frequency section-header-meta__dropdown"
								placeholder="frequently"
								options={ORDER_FREQUENCY_LIST.slice()}
								optionSelectedId={filterConsumerTypeId}
								onChange={item => this.handlerSwichFilter({
									filterConsumerTypeId: item.id
								})}
							/>} order
						</div>
					</div>
					<div className="section-header-controls snapshot-disable">
						{(data && window.generalConfig.access.trial === false) && (
							<ClipboardButtons 
								fileName={table.name}
								nodeNameAttribute={table.methodName}
							/>
						)}

						{data && (
							<div className="base-size">base size: n={getBaseValue({...data})}</div>
						)}	
 
					</div>
					<div className="section-header-copyright snapshot-only">Datassential</div>	
				</div>
		 
				<div className="section-content" clipboard={table.methodName}>

					<span className="hidden-layer">
						<div><b>Datassential Brand Fingerprints</b></div>
						<div>{chain.name}: {table.name.toUpperCase()}</div>
						<div>% of consumers who {filterConsumer.name} order</div>
						<div>Extracted {formattedDate}</div>
						<div>Questions? Call us at 888-556-3687 or email help@datassential.com</div>
						<br />
						<br />
					</span>

					{data === null ? (
						<Preloader type="table" />
						) : (
						<div className="menu-items-container">
							<div className="menu-items-list">
								{dataList.map((item, index) => {
									return (
										<div className="menu-items-item" key={`menu-item-${index}`}>
											<figure className="menu-items-item__image" clipboard-disable="true">
												<img 
													src={`${item.image}`} 
													alt=""
												/>
											</figure>
											<div className="menu-items-item__footer" clipboard-disable="true">
												<span className="menu-items-item__name"> {item.text} </span>
												<span className="menu-items-item__value">
													{item.value}
												</span>
											</div>
											<table className="hidden-layer">
												<tbody>
													<tr>
														<td>{item.text}</td>
														<td>{item.value}</td>
													</tr>
												</tbody>
											</table>
										</div>
									)
								})}
							</div>
						</div>		
					)}
				</div>

				<div className="section-footer">
					<div className="section-footer-enter">
						{table.question &&  <div className="section-footer-question" title={table.question}>
							<span className="section-footer-question__char">Q:</span> {table.question}
						</div>}
					</div>
				</div> 
			</section>
		)

	}


}
 

MenuItems.propTypes = {
	data: PropTypes.object,
	chain: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
	data: getDataTable(state, props),
})


export default connect(mapStateToProps)(MenuItems);