export default class Notification {
	constructor(config = {}) {
		this.config = Object.assign( Notification.config(), config);
		this.nodeWrapper = null;
	}

	createWrapper(){
		this.nodeWrapper = document.createElement('div');
		this.nodeWrapper.classList.add(...this.config.className.split(' '));
		document.body.appendChild(this.nodeWrapper);
	}

	removeWrapper(){
 		this.nodeWrapper.parentNode.removeChild(this.nodeWrapper);
 		this.nodeWrapper = null;
	}

	add(messageHTML){
 		if (!this.nodeWrapper)
			this.createWrapper();

		const nodeNotify = document.createElement('div');
		nodeNotify.classList.add(...this.config.itemClassName.split(' '));
		nodeNotify.innerHTML = messageHTML;
		this.nodeWrapper.appendChild(nodeNotify);

		const time = setTimeout(() => {
			this.remove(nodeNotify);
			clearTimeout(time);
		},	this.config.timeout);

	}

	remove(nodeNotify){
		nodeNotify.addEventListener('animationend', e => {

			nodeNotify.parentNode.removeChild(nodeNotify);

			if (typeof this.config.onDestruction === 'function')
				this.config.onDestruction();


			if (this.nodeWrapper && this.nodeWrapper.children.length === 0)
				this.removeWrapper();
		});

		nodeNotify.classList.add(`${Notification.config().itemClassName}--remove`);
	}

	static config(){
		return {
			timeout: 3000,
			className: 'notification',
			itemClassName: 'notification-item',
			onDestruction: null
		}
	}
}