import React, { Component, Fragment } from "react";
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
 

import {getHasHistoryChain} from '../../route/Chain/Chain.selectors.js';

import {
	getChainsByCluster
} from '../../selectors/section.selectors';

import {CHAIN_COMPARE_TYPES} from '../../utils/constants';

import ChainPopupGroup from './ChainPopup.Group';
import ChainPopupChain from './ChainPopup.Chain';

import {
	toggleCompetetivePopup, 
	getDataByTable, 
	changeReportCompareTo,
	changeFilterCompare
} from '../../actions/chain';
import {getPresetsData} from '../../actions/preset';

class ChainPopup extends Component {

	constructor(props){
		super(props);

		this.state = {
			compareType: null,
		}

		this.close = this.close.bind(this);
		this.onSelected = this.onSelected.bind(this);
		this.onSelectCompareType = this.onSelectCompareType.bind(this);

		this.renderTask = this.renderTask.bind(this);
		this.onWindowClick = this.onWindowClick.bind(this); 

		this.modalDOMNode = document.getElementById('modal');
	}

	componentDidMount() {
		window.addEventListener('click', this.onWindowClick);
		window.addEventListener('touchstart', this.onWindowClick);
	}

	componentWillUnmount() {
		window.removeEventListener('click', this.onWindowClick);
		window.removeEventListener('touchstart', this.onWindowClick);
		this.modalDOMNode.classList.remove('is-active');
	}

	componentDidUpdate(){
		if (this.props.popup.isOpened){
			
			if (Array.from(this.modalDOMNode.classList).includes('is-active') === false)
				this.props.getPresetsData();

			this.modalDOMNode.classList.add('is-active');
		} else {
			this.modalDOMNode.classList.remove('is-active');
		}


	}
 
	onWindowClick(event) {
		if (this.props.popup.isOpened && this.modalDOMNode === event.target)
			this.close();
	}

	close(){
		this.modalDOMNode.classList.remove('is-active');
		this.props.toggleCompetetivePopup(false); 
		this.setState({
			compareType: null
		});
	}

	onSelected(select) {
		this.close();
		
		const { 
			popup:{
				callingTable
			} 
		} = this.props;

		if (callingTable === null){

			this.props.changeReportCompareTo({
				data: select.payload,
				type: select.type
			});

			return;
		}

		if (select.type === CHAIN_COMPARE_TYPES.HISTORY){
			const {
				chain,
				popup:{
					callingTable:{
						groupMethodName,
						tableMethodName,
						isHistory,
					}
				},
			} = this.props;

			this.props.getDataByTable({
				chainId: chain.id,
				stateId: chain.stateId,
				dmaRegionId: chain.dmaRegionId,
				chainType: chain.chainType,
				groupMethodName, 
				tableMethodName, 
				isHistory: !isHistory
			});

		} else {
			this.props.changeFilterCompare({
				isClearDataGroups:true,
				filterCompareTo: select,
			});
		}
	}

	onSelectCompareType(compareType = null) {
		this.setState({
			compareType: compareType
		})
	}

	renderTask(){
		const {
			history,
			match,

			chain,
			popup: {
				callingTable
			},
			currentPeriodLabel,
			isHistoryChain,
            clusterChains: {
                chains
            },
		} = this.props;


		const {compareType} = this.state;

        const countEnabledChains = chains.filter(chain => chain.isDisabled === false).length;

		switch(compareType) {
			case CHAIN_COMPARE_TYPES.GROUP:
				return (
					<ChainPopupGroup 
						history={history}
						match={match}
						chain={chain} 
						onSelect={this.onSelected} 
					/>
				);

			case CHAIN_COMPARE_TYPES.BRAND:
				return (
					<ChainPopupChain 
						history={history}
						match={match}
						chain={chain} 
						onSelect={this.onSelected} 
						onCancel={this.onSelectCompareType}
						callingTable={callingTable} 
					/>
				);
			
			default:
				return (
					<div className="comparison-section">
						<div className="comparison-section__item">
							<button className="button comparison-section__button" onClick={(event) => {
								event.preventDefault();
								this.onSelectCompareType(CHAIN_COMPARE_TYPES.GROUP);
							}}>
								<svg className="icon icon-competitive" dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-competitive" />' }} />
								<span className="comparison-section__caption">competitive<br />set</span>
							</button>
						</div>
						<div className="comparison-section__item">
							<button 
								className="button comparison-section__button"
								disabled={countEnabledChains === 1}
								onClick={(event) => {
									event.preventDefault();
									this.onSelectCompareType(CHAIN_COMPARE_TYPES.BRAND);
								}}
							>
								<svg className="icon icon-circle" dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-brand" />' }} />
								<span className="comparison-section__caption">specific<br />brands</span>
							</button>
						</div>

						{isHistoryChain === true && 
							callingTable &&
							callingTable.hasHistory === true && (
								<div className="comparison-section__item">
									<button className="button comparison-section__button" onClick={ (event) => {
										event.preventDefault();
										this.onSelected({
											type: CHAIN_COMPARE_TYPES.HISTORY
										});
									}}>
										<svg className="icon icon-circle" dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-history" />' }} />
										<span className="comparison-section__caption">
											{callingTable.isHistory === false ? (
												<span>historic<br />trend</span>
											  ) : (
												<span>back to<br />{currentPeriodLabel}</span>
											)}
										</span>
									</button>
								</div>
							)}
					</div>
				)
		}
	}


	render() {
		const { 
			chain, 
			popup, 
		} = this.props;

		const {compareType} = this.state;

		return (
			 <Fragment>
				 {popup.isOpened === true && ReactDOM.createPortal(
					<div className="modal-content modal-content--competetive animation-show-modal-content">
						<div className="modal-header">
							{compareType &&
								<button className="button modal-button-back" onClick={(event) => {
									event.preventDefault();
									this.onSelectCompareType();
								}}>
									<svg className="icon icon-arrow-left" dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-arrow-left" />' }} />
								</button>
							}
							<button type="button" className="button modal-button-close" onClick={this.close}>
								<svg className="icon icon-close icon-close--secondary" dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-close" />' }} />
							</button>
							<div className="modal-title">
								{compareType === null && <div>Compare {chain.name} to...</div>}
								{compareType === CHAIN_COMPARE_TYPES.GROUP && <div className="text-center">Select a comparison group</div>}
								{compareType === CHAIN_COMPARE_TYPES.BRAND && <div className="text-center">Select an individual brand</div>}
							</div>
						</div>
						<div className="modal-body">
							{this.renderTask()}
						</div>
					</div>, this.modalDOMNode )}
			 </Fragment>
		);
	}
}

ChainPopup.propTypes = {
	chain: PropTypes.object,
	popup: PropTypes.object
};

const mapStateToProps = (state, props) => ({
	popup: state.chain.competetivePopup,

	currentPeriodLabel: state.init.currentPeriodName,

	isHistoryChain: getHasHistoryChain(state, props),

	filterCompareTo: state.chain.filterCompareTo,
	dataChainHeader: state.chain.dataChainHeader,

	clusterChains: getChainsByCluster(state, props),


	// isLoadingPresets: state.presets.isLoading,
});

const mapDispatchToProps = dispatch => ({
	changeFilterCompare: (data) => dispatch(changeFilterCompare(data)),
	toggleCompetetivePopup: (data) => dispatch(toggleCompetetivePopup(data)),
	getDataByTable: (data) => dispatch(getDataByTable(data)),

	changeReportCompareTo: (data) => dispatch(changeReportCompareTo(data)),
	getPresetsData: (data) => dispatch(getPresetsData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChainPopup);