
export default class Clipboard {
	constructor(options) {
		this.data = options.data;
		this.node = options.node;
		this.onComplete = options.onComplete;
		this.doCopy();
	}

	doCopy() {
 		if (Array.isArray(this.data)){
			this.copyData();
		} else if (this.node !== null)  {
			this.doCleanNode();
			this.copyNode();
		}
	}
  
	copySelectedText(callback) {
		let success;

		try {
			success = document.execCommand('copy');
		}
		catch (e) {
			success = false;
		}

		callback(success);

		// @TODO: remoke to promise
		if (typeof this.onComplete === 'function')
			setTimeout(() => {
				this.onComplete({
					success: success
				});
			}, 10);
	}

	selectDOMText(element) {
		var selectedText;

		if (element.nodeName === 'SELECT') {
			element.focus();

			selectedText = element.value;
		}
		else if (element.nodeName === 'INPUT' || element.nodeName === 'TEXTAREA') {
			var isReadOnly = element.hasAttribute('readonly');

			if (!isReadOnly) {
				element.setAttribute('readonly', '');
			}

			element.select();
			element.setSelectionRange(0, element.value.length);

			if (!isReadOnly) {
				element.removeAttribute('readonly');
			}

			selectedText = element.value;
		}
		else {
			if (element.hasAttribute('contenteditable')) {
				element.focus();
			}

			var selection = window.getSelection();
			var range = document.createRange();

			range.selectNodeContents(element);
			selection.removeAllRanges();
			selection.addRange(range);

			selectedText = selection.toString();
		}

		return selectedText;
	}
	
	doCleanNode(){

    	this.node = this.node.cloneNode(true);
    	const childrenNodeList = this.node.querySelectorAll('*');

		Array.from(childrenNodeList).forEach( element => {
			const hasToRemove = element.hasAttribute('clipboard-disable');
			element.setAttribute('class','');
			element.setAttribute('style','');
			if (element.tagName === 'A'){
				const spanNode = document.createElement('span'); 
				spanNode.innerHTML = element.innerHTML;
				element.parentNode.replaceChild(spanNode, element);
			}
			if (hasToRemove)
				element.parentNode.removeChild(element);
		});
	}

    copyNode() {
    	const copyNode  = document.createElement('div');

			copyNode.style.position = 'absolute';
			copyNode.style.left = '0px';
			copyNode.style.top = '0px';
			copyNode.style.opacity = '0.1';
			copyNode.style.fontFamily = 'Segoe UI Light, Segoe UI, Segoe, Optima,Arial,sans-serif';

	        copyNode.appendChild(this.node);
    	 	document.body.appendChild(copyNode);

    	 	this.selectDOMText(copyNode);
        	this.copySelectedText( result => {
        		copyNode.parentNode.removeChild(copyNode);
        	});
    }

    copyData() {

		const lineBreak = navigator.userAgent.match(/Windows/) ? '\r\n' : '\n';
        const nodeTextArea = document.createElement('textarea');

        // Prevent zooming on iOS
        nodeTextArea.style.fontSize = '12pt';
        // Reset box model
        nodeTextArea.style.border = '0';
        nodeTextArea.style.padding = '0';
        nodeTextArea.style.margin = '0';
        // Move element out of screen horizontally
        nodeTextArea.style.position = 'absolute';
        nodeTextArea.style.left = '-9999px';
        // Move element to the same position vertically
        let yPosition = window.pageYOffset || document.documentElement.scrollTop;
        nodeTextArea.style.top = `${yPosition}px`;

        nodeTextArea.setAttribute('readonly', '');
        
        nodeTextArea.value = this.data.map( (element) => {
    		return element.join('\t');
    	}).join(lineBreak);

        document.body.appendChild(nodeTextArea);

        if ( this.selectDOMText(nodeTextArea) )
        	this.copySelectedText( () => {
        		document.body.removeChild(nodeTextArea);
        	});
    }
}