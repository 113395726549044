import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';


class Autocomplete extends Component{
	constructor (props) {
		super(props);

		this.state = {
			searchValue: '',

			indexPointer: null,
			isOpen: false
		}

		this.refDropdownNode = createRef();

		this.optionList = [];

		this.onSearch = this.onSearch.bind(this);
		this._onWindowClick = this._onWindowClick.bind(this);
		this._onWindowClick = this._onWindowClick.bind(this);
	}

	componentDidMount() {
		window.addEventListener('click', this._onWindowClick);
		window.addEventListener('touchstart', this._onWindowClick);
	}


	componentWillUnmount() {
		window.removeEventListener('click', this._onWindowClick);
		window.removeEventListener('touchstart', this._onWindowClick);
	}

	componentWillReceiveProps(){
		this.setState({
			searchValue: '',

			indexPointer: null,
			isOpen: false
		});
	}

	_onWindowClick(event) {

		const dropdownNode = this.refDropdownNode.current;

		if (dropdownNode && event.target !== dropdownNode && !dropdownNode.contains(event.target))
			this.setState({
				searchValue: '',
				indexPointer: null,
				isOpen: false
			})
		
		this.optionList = [];
	}

	onSearch(event) {
		const valueText = event.target.value.toLowerCase();
 
		this.setState(prevState => ({
			indexPointer: null,
			searchValue: valueText,
			isOpen: (valueText.length >= this.props.minCountChar) ? true : false
		}));

	}

	onSelect(item){
		this.setState({
			indexPointer: null,
			isOpen: false,
			searchValue: '',
		})

		this.optionList = [];

		if (this.props.onSelect) 
			this.props.onSelect(item)
	}

	// TODO: remake
	onKeyPressed(e) {
		const {indexPointer} = this.state,
			maxIndex = this.optionList.length -1;

 		let index = null;
 
 		if (indexPointer !== null && e.key === 'Enter'){
			const item = this.optionList[indexPointer] || null;
			this.onSelect(item);
			return;
 		}

		if (e.key === 'ArrowDown')
			index = (indexPointer === null) ? 0 : (( indexPointer === maxIndex) ? indexPointer : indexPointer + 1  );

 		if (e.key === 'ArrowUp')
			index = (indexPointer < 1) ? 0 : indexPointer - 1;

 		if (e.key === 'ArrowUp')
			index = (indexPointer < 1) ? 0 : indexPointer - 1;

 
		this.setState(prevState => ({
			indexPointer: index
		})); 
	}

	buildMenu() {
		const { options } = this.props;
		const { searchValue, indexPointer } = this.state,
			regex = new RegExp('\\b'+ searchValue, 'ig');

		this.optionList = [];
  
		const optionList = options.filter( (option) => ( option.name.toLowerCase().search(regex) !== -1 ) ).map((option, index) => {

			this.optionList.push(option);
			const caption =  option.name.replace(regex, $1 => (`<mark>${$1}</mark>`) );
			
			return (
				<div 
					key={`${option.id}-${index}`} 
					className={`autocomplete-item ${(indexPointer === index) ? `is-selected` : ``}`} 
					onClick={this.onSelect.bind(this, option)} 
					dangerouslySetInnerHTML={{__html: caption }} 
				/>
			)

		});

		return <div className="autocomplete-menu">{(optionList.length ? optionList : <div className="autocomplete-menu-noresults">Nothing found...</div>)}</div>
	}

	render() {

		const { 
			// options, 
			placeholder 
		} = this.props;
		const { searchValue } = this.state;

		return(
			<div 
				className={`autocomplete ${this.props.className}` + (this.state.isOpen ? " is-open" : "")}
				ref={this.refDropdownNode}
				onKeyDown={(e) => {
					if (this.optionList.length > 0)
						this.onKeyPressed(e)
				}}>
				<div className="autocomplete-display">
 					<input className="autocomplete-input" type="text" placeholder={placeholder} value={searchValue} onChange={this.onSearch} />
					<svg className="icon icon-search" dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-search" />' }} />
				</div>
				{this.state.isOpen ? this.buildMenu() : ''} 
			</div>
		)
	} 
} 

Autocomplete.propTypes = {
	className: PropTypes.string,
	placeholder: PropTypes.string,
	options: PropTypes.array,
	minCountChar: PropTypes.number,
	onSelect: PropTypes.func,
};

Autocomplete.defaultProps = {
	className: "",
	placeholder: "Search...",
	options: [],
	minCountChar: 2
};


export default Autocomplete;