import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';



class Dropdown extends Component{
	constructor (props) {
		super(props);

		this.state = {
			selected: this.props.optionSelectedId,
			isOpen: false
		}

		this.refDropdownNode = createRef();

		this._onWindowClick = this._onWindowClick.bind(this);
		this._onWindowClick = this._onWindowClick.bind(this);
	}

	componentDidMount() {
		window.addEventListener('click', this._onWindowClick);
		window.addEventListener('touchstart', this._onWindowClick);
	}

	componentWillUnmount() {
		window.removeEventListener('click', this._onWindowClick);
		window.removeEventListener('touchstart', this._onWindowClick);
	}

	componentWillReceiveProps(nextProps){
		const { optionSelectedId } = nextProps;
		this.setState({
			selected: optionSelectedId,
			isOpen: false
		})
	}
	
	_onWindowClick(event){
		const dropdownNode = this.refDropdownNode.current;

		if (dropdownNode && event.target !== dropdownNode && !dropdownNode.contains(event.target))
			this.setState({
				isOpen: false
			})
	}

	onToggleClick() {
		this.setState(prevState => ({
			isOpen: !prevState.isOpen
		}));
	}

	select(item){
	
		this.setState({
			selected: item.id,
			isOpen: false
		})

		if (this.props.onChange) 
			this.props.onChange(item)
	}
 	
 	buildOption(option, index){
		return <div key={index + option.id} className={"dropdown-item" + (this.state.selected === option.id ? " is-selected" : "")} onClick={this.select.bind(this, option)}>{option.name}</div>
 	}

	buildMenu() {
		const { options } = this.props;
  
		const optionList = options.map((option, index) => {
			if (option.hasOwnProperty("items") === true) {
 
				const _options = option.items.map((item, itemIndex) => this.buildOption(item, itemIndex))

				return (
					<div className="dropdown-group" key={option.name}>
						{(option.name.length ? <div className="dropdown-group-caption"><span className="dropdown-group-caption__name">{option.name}</span></div> : "")}
						{_options}
					</div>
				)

			} else {
				return this.buildOption(option, index)
			}
		})

		return <div className="dropdown-menu">{(optionList.length ? <div className="dropdown-list">{optionList}</div> : <div className="dropdown-menu-noresults">No options found</div>)}</div>
	}

	render() {

		const { options, placeholder } = this.props;

		let label = placeholder;
		options.forEach( (option) => {
			if (option.hasOwnProperty("items") === true) {

				option.items.forEach( (item) => {
					if (item.id === this.state.selected)
						label = item.name
				})

			} else {
				if (option.id === this.state.selected)
					label = option.name
			}
		});


		return(
			<div 
				ref={this.refDropdownNode}
				className={`dropdown ${this.props.className}` + (this.state.isOpen ? " is-open" : "")}
			>
				<div className="dropdown-display" onClick={this.onToggleClick.bind(this)}>
					<span className="dropdown-display__caption">{label}</span>
				</div>
				{this.state.isOpen ? this.buildMenu() : ''} 
			</div>
		)
	} 
} 

Dropdown.propTypes = {
	className: PropTypes.string,
	placeholder: PropTypes.string,
	options: PropTypes.array,
	onChange: PropTypes.func,
	optionSelectedId: PropTypes.number
};

Dropdown.defaultProps = {
	className: "",
	placeholder: "All items...",
	options: [],
	optionSelectedId: null
};


export default Dropdown;