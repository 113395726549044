import React from "react";
import PropTypes from 'prop-types';
import Preloader from '../../components/Preloader';

import ClipboardButtons from '../../components/ClipboardButtons';
import {capitalizeFirstLetter} from "../../utils/helpers";


const SectionWrapper = (props) => {
	const {
		config,
		// initConfig,
	} = props;

	return (
		<section className="section" id={config.aliasName} snapshot={config.methodName}>
			<div className="section-header">
				<div className="section-header-meta">
					<div className="section-header-meta__caption">{config.name}</div>
					{config.description && <div className="section-header-meta__description">{capitalizeFirstLetter(config.description)}</div>}
				</div>	
				<div className="section-header-controls snapshot-disable">
					{config.isShowData && (
						<div className="section-header-controls__buttons">
							{config.buttons && config.buttons}

							{(window.generalConfig.access.trial === false) && (
								<ClipboardButtons 
									fileName={config.name}
									nodeNameAttribute={config.methodName}
									copyData={config.copyData}
								/>
							)}

						</div>
					)}
				</div>
				<div className="section-header-copyright snapshot-only">Datassential</div>	
			</div>

			<div className="section-content">
				{config.isShowData === true ? (
					props.children						
				) : (
					<Preloader type="table"/>
				)}
			</div>

			<div className="section-footer">
				{config.question && <div className="section-footer-enter">
						<div className="section-footer-question" title={config.question}>
							<span className="section-footer-question__char">Q:</span> {config.question}
						</div>
					</div>
				}
			</div>
		</section>
	)

}
 
SectionWrapper.propTypes = {
	config: PropTypes.object
};

export default SectionWrapper;