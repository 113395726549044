import { useMemo } from "react";

export default function useSessionAccess(): {
    cstores: boolean;
    restaurants: boolean;
    supermarkets: boolean;
    isLoaded: boolean;
} {
    return useMemo(
        () => ({
            cstores: window.generalConfig.access.cStores,
            restaurants: window.generalConfig.access.restaurants,
            supermarkets: window.generalConfig.access.supermarkets,
            isLoaded: window.generalConfig.isLoaded,
        }),
        []
    );
}