import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import Clipboard from '../../../utils/clipboard';
import Notification from '../../../utils/notification';
 
import {
	ORDER_FREQUENCY_LIST,
} from '../../../utils/constants';

const ENABLED_RESPONDENT_LIST = [0,1,2,3,4,5,6,8,9,12,13,14];

const getFilteredRespondentList = (list = []) => {
	return list.map(group => {
		const items = group.items.filter(item => {
			if (ENABLED_RESPONDENT_LIST.includes(item.id) === true)
				return item;
			return null;
		});

		if (items.length > 0)
			return {
				...group,
				items
			}
		return null;
	}).filter(group => group);
}


class ModalDemo extends Component {

	constructor(props){
		super(props);

		this.onWindowClick = this.onWindowClick.bind(this); 
		this.onCopyText = this.onCopyText.bind(this); 
		this.close = this.close.bind(this);

		this.nodeModalPoint = document.getElementById('modal');
		this.notification = new Notification();
	}

	componentDidMount() {
		window.addEventListener('click', this.onWindowClick);
		window.addEventListener('touchstart', this.onWindowClick);
		this.nodeModalPoint.classList.add('is-active');
	}

	componentWillUnmount() {
		window.removeEventListener('click', this.onWindowClick);
		window.removeEventListener('touchstart', this.onWindowClick);

	}

	onWindowClick(event) {
		if (this.nodeModalPoint === event.target){
			this.close();
		}
	}


	onCopyText(respondentDataList){

		const {table} = this.props; 
 
		new Clipboard({
				data: respondentDataList.reduce( (collector, group) => {

				const items = group.items.map(item => {
					return [item.name, `${item.value}%`]
				});

				return [
					...collector,
					...items
				];

			}, [[table.name.toUpperCase()]]),

			node: null,
			onComplete: (response) => {
				this.notification.add((response.success === true) ? `Successfully copied to your clipboard` : `Not successfully copied to your clipboard`);
			}
		})
	}


 	close(){
		this.nodeModalPoint.classList.remove('is-active');
		this.props.onClose(); 
	}

	render() {

 		const {
 			consumerList,
 			itemDetail:{
 				// category,
 				item
 			},
 			chain,
 			filterConsumerTypeId,
 			respondentTypesList,
 			// respondentTypeId
 		} = this.props;



		const answer = consumerList.find(answer => answer.answerId === item.answerId);

		let maxValue = -Infinity;
		const respondentDataList = getFilteredRespondentList(respondentTypesList).map(group => {

			const items = group.items.map(respondent => {

				const dataAnswer = answer.data.find(answer => answer.respondentTypeId === respondent.id) || null;

				return {
					...respondent,
					value : (dataAnswer) ? +dataAnswer.value : 0
				}
			});


			maxValue = Math.max.apply(null, [
				...items.map(item => item.value),
				maxValue
			])

			return {
				...group,
				items
			}
		});

		
		const consumer = ORDER_FREQUENCY_LIST.find( consumer => consumer.id === filterConsumerTypeId);

		return (
			ReactDOM.createPortal(
				<div className="modal-content modal-menu-category animation-show-modal-content" style={{flex: 0, maxWidth: 'none'}}>
					<div className="modal-menu-category-header">
						<div className="modal-menu-category-header__consumer">% who {consumer.name} order</div>
						<div className="modal-menu-category-header__item">{item.text}</div>
						<div className="modal-menu-category-header__chain">at {chain.name}</div>
 
						<button className="button modal-menu-category-header__copy-button" onClick={event => {
							event.preventDefault();
							this.onCopyText(respondentDataList);
						}}>Copy data</button>

						<button type="button" className="button modal-button-close" onClick={this.close}>
							<svg className="icon icon-close icon-close--secondary" dangerouslySetInnerHTML={{__html: '<use xlink:href="#icon-close" />' }} />
						</button>
					</div>
					<div className="modal-body">
 
						<div className="gauge-columns-container">
							<div className="gauge-columns-grid" style={{height: `250px`}}>

								{respondentDataList.map( (group, indexGroup) => {

									return (
										<div className="gauge-columns-group" key={indexGroup}>
											{group.items.map((respondent, indexRespondent) => {

												const height = Math.round((respondent.value * 100) / maxValue);

												return (
													<div className="gauge-columns-bar" key={indexRespondent} style={{height:`${height}%`}}>
														<div className="gauge-columns-bar__value">{`${+respondent.value}%`}</div>
														<div className="gauge-columns-bar__label">{respondent.name}</div>
													</div>
												)
											})}
										</div>
									);

								})}

							</div>
						</div>
						 
					</div>
				</div>, this.nodeModalPoint)
		);
	}
}

ModalDemo.propTypes = {
	respondentTypesList: PropTypes.array,
};

const mapStateToProps = (state, props) => ({
	respondentTypesList: state.init.foodTabRespondentTypes,
});
 
export default withRouter(connect(mapStateToProps)(ModalDemo));